import React, { useEffect, useRef, useState } from "react";
import { RetellWebClient } from "retell-client-js-sdk";
import { START_CONVERSATION } from '../services/agents';
import { notifications } from "@mantine/notifications";

interface RegisterCallResponse {
  callId?: string;
  sampleRate: number;
}

const useCallAgent = (
  { own = false, onFinish = null }
) : [boolean, ({ agentId, own, onFinish }: { agentId: string; own?: boolean; onFinish?: ({callId}) => void }) => void] => {
  const webClient = useRef<RetellWebClient>();
  const [isCalling, setIsCalling] = useState(false);

  const callIdRef = useRef<string>();

  // Initialize the SDK
  useEffect(() => {
    webClient.current = own
      ? new RetellWebClient(`${process.env.REACT_APP_AUDIO_SOCKET_SERVER}`)
      : new RetellWebClient();
    // Setup event listeners
    webClient.current.on("conversationStarted", () => {
      console.log("conversationStarted");
    });

    webClient.current.on("audio", (audio: Uint8Array) => {
      console.log("There is audio");
    });

    webClient.current.on("conversationEnded", ({ code, reason }) => {
      console.log("Closed with code:", code, ", reason:", reason);
      onFinish && onFinish({ callId: callIdRef.current });
      setIsCalling(false); // Update button to "Start" when conversation ends
    });

    webClient.current.on("error", (error) => {
      console.error("An error occurred:", error);
      setIsCalling(false); // Update button to "Start" in case of error
    });

    webClient.current.on("update", (update) => {
      // Print live transcript as needed
      console.log("update", update);
    });
  }, [own]);

  const toggleConversation = async ({ agentId }) => {
    if (isCalling) {
      webClient.current.stopConversation();
    } else {
      const registerCallResponse = await registerCall(agentId);
      if (registerCallResponse.callId) {
        callIdRef.current = registerCallResponse.callId;
        webClient.current
            .startConversation({
                callId: registerCallResponse.callId,
                sampleRate: registerCallResponse.sampleRate,
                enableUpdate: true,
            })
            .catch(console.error);
        setIsCalling(true);
      }
    }
  };

  async function registerCall(agentId: string): Promise<RegisterCallResponse> {
    try {
      const response = await START_CONVERSATION(agentId);
      return response;
    } catch (err) {
      console.log(err);
      notifications.show({ message: err.message, color: "red" })
    }
  }

  return [
    isCalling,
    toggleConversation,
  ];
};

export default useCallAgent;