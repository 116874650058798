import {
  ActionIcon,
  Box,
  Button,
  Grid,
  Group, Paper,
  Progress,
  Space, Text,
  Title
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { default as React, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import InputField, { InputFieldTypes } from "../components/input_field";
import { useAuth } from "../contexts/auth.context";
import { api } from "../services/api";
import { phoneCountryCodes } from "../utility/util";

export default function Invite(){
  const { signIn, companyHost } = useAuth();
  const {key} = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const [inviteData, setInviteData] = useState<any>(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  React.useEffect(() => {
    if(key){
      api.get(`/auth/invite/${key}`)
      .then(({data}) => {
        setInviteData(data);
      })
      .catch((data) => {
        notifications.show({title: "Ops", message: data.message, color: 'red'});
        navigate("/")
      })
    }
  }, [key, navigate])

  return (
    <div style={{
        backgroundImage: `url(/soundwave.png)`,
        backgroundColor: '#12141d',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        position: 'relative',
        minHeight: '100vh',
        zIndex: 0,
        ...(
            isMobile
            ? {
                padding: 20,
            }
            : {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '10vh 30vw',
            }
        )
    }}>
        <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: '#00000055', zIndex: -1}}></div>
          <Paper p="md" style={{borderColor: '#DFDFDF'}}>
            {inviteData?.invite?.company?.image && <div style={{display: 'flex', margin: '20px 0', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <img alt="awaz" src={inviteData?.invite?.company?.image} style={{maxHeight: 80, maxWidth: '180px'}}/>
            </div>}
            <Group style={{padding: isMobile ? '0 4px' : '0 60px'}}>
              <Box style={{flex: 1}}>
                <Text ta="center" size="sm">Sign Up</Text>
                <Title ta="center" order={2}>Get access to {inviteData?.invite?.company?.name}</Title>
              </Box>
            </Group>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                api.post("/auth/invite/confirm", {
                  uuid: key,
                  password,
                  name,
                  phone,
                  phone_code: phoneCode,
                })
                .then(({data: {user}}) => {
                  signIn(user, () => navigate("/", { replace: true }));
                })
                .catch(err => {
                  notifications.show({title: 'Ops', message: err.message, color: 'red'})
                })
              }}
            >
              {<>
                <Title order={5} ta="center" c="gray" mb="xl">{inviteData?.invite?.email}</Title>
                <InputField
                  title="Name"
                  mt="md"
                  name='name'
                  value={name}
                  onChange={({name}) => setName(name)}
                  fieldType={InputFieldTypes.STRING}
                />
                <Group mt="md">
                  <InputField
                    style={{flex: 0.3}}
                    title="Phone"
                    name="phone_code"
                    fieldType={InputFieldTypes.SELECT}
                    value={phoneCode}
                    onChange={({phone_code}) => setPhoneCode(phone_code)}
                    options={phoneCountryCodes}
                  />
                  <InputField
                    style={{flex: 0.7}}
                    title="Phone"
                    name="phone"
                    value={phone}
                    onChange={({phone}) => setPhone(phone)}
                  />
                </Group>
                <InputField
                  mt="md"
                  name="p"
                  fieldType={InputFieldTypes.PASSWORD}
                  title="Choose your password"
                  value={password}
                  onChange={({p}) => setPassword(p)}
                />
                <InputField
                  mt="md"
                  name="p"
                  fieldType={InputFieldTypes.PASSWORD}
                  title="Confirm your choosen password"
                  value={passwordConfirmation}
                  onChange={({p}) => setPasswordConfirmation(p)}
                />
              </>}
              <Space h="xl" />
              <Button
                type="submit"
                mt="xl"
                size="md"
                fullWidth
                color="violet"
              >Sign Up</Button>
            </form>
        </Paper>
    </div>
  );

//   return <div style={{height: '100vh', display: 'flex', flexDirection: 'row'}}>
//   <div style={{ flex: isMobile ? 1 : 0.3, display: 'flex', flexDirection: 'column' }}>
//     <div style={{padding: 40}}>
//     <img alt="awaz" src={companyHost?.image ? companyHost.image : `/logo.png`} style={{maxHeight: 80, maxWidth: '70%'}}/>
//     </div>
//     <div style={{flex: 1, padding: 40}}>
    
//     </div>
//     <div style={{padding: 40}}>
      
//     </div>
//   </div>
//   {!isMobile && <div style={{flex: 0.7, background: `url(/assets/login.png)`, height: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>}
// </div>
};
