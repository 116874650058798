import * as React from "react";
import {
  FaBolt,
  FaCalendar,
  FaChartLine,
  FaDashcube,
  FaLock,
  FaPhone,
  FaRegListAlt,
  FaSign,
  FaUser,
  FaWrench
} from "react-icons/fa";
import AdminPage from '../pages/admin';
import ContactsPage from '../pages/contacts';
import ContactPage from '../pages/contact';
import CalendarsPage from '../pages/calendars';
import { default as HomePage } from "../pages/home";
import InvitePage from "../pages/invite";
import LoginPage from "../pages/login";
import PublicPage from "../pages/public";
import RecoverPage from "../pages/recover";
import SignupPage from "../pages/signup";
import ValidatePage from "../pages/validate";
import KnowledgesPage from "../pages/knowledges";
import SettingsPage from "../pages/settings";
import AgentPage from "../pages/agent";
import CallsPage from "../pages/calls";
import CampaignsPage from "../pages/campaigns";
import OAuthPage from "../pages/oauth";
import ContactListsPage from "../pages/contact_lists";
import ContactListPage from "../pages/contact_list";
import OnboardingPage from "../pages/first_agent";
import { FaBook } from "react-icons/fa6";

export interface RouteProps {
  title: string;
  key: string;
  icon?: React.ReactNode;
  url: string;
  component: React.ReactNode;
  private?: boolean;
  menu?: boolean;
  condition?: (data: { user: any; role: any; }) => boolean;
}
const routes: RouteProps[] = [
  {
    title: "Login",
    key: "login",
    icon: <FaLock />,
    url: "/login",
    component: <LoginPage />,
  },
  {
    title: "Signup",
    key: "signup",
    icon: <FaSign />,
    url: "/signup",
    component: <SignupPage />,
  },
  // {
  //   title: "Client Signup",
  //   key: "signup-customer",
  //   icon: <FaSign />,
  //   url: "/signup/customer",
  //   component: <SignupPage customer />,
  // },
  {
    title: "Invite",
    key: "invite",
    url: "/invite/:key",
    component: <InvitePage />,
  },
  {
    title: "Validate",
    key: "validate",
    url: "/validate/:key",
    component: <ValidatePage />,
  },
  {
    title: "Recover Password",
    key: "recover",
    url: "/recover/:key",
    component: <RecoverPage />,
  },
  // {
  //   title: "Home",
  //   key: "home",
  //   icon: <FaChartLine />,
  //   url: "/",
  //   menu: true,
  //   private: true,
  //   component: <HomePage />,
  // },
  {
    title: "Agents",
    key: "agents",
    icon: <FaUser />,
    url: "/",
    menu: true,
    private: true,
    component: <HomePage />,
  },
  {
    title: "Thanks",
    key: "thanks",
    icon: <FaUser />,
    url: "/thank-you",
    menu: false,
    private: false,
    component: <OnboardingPage />,
    // component: <HomePage />,
  },
  {
    title: "Agents",
    key: "agents",
    icon: <FaChartLine />,
    url: "/agents",
    menu: false,
    private: true,
    component: <HomePage />,
  },
  {
    title: "Agent",
    key: "agent",
    icon: <FaChartLine />,
    url: "/agents/:id",
    menu: false,
    private: true,
    component: <AgentPage />,
  },
  {
    title: "Knowledge",
    key: "knowledge",
    icon: <FaBook />,
    url: "/knowledge",
    menu: true,
    private: true,
    condition: ({user}) => user?.isAdmin,
    component: <KnowledgesPage />,
  },
  {
    title: "Lists",
    key: "contacts",
    icon: <FaRegListAlt />,
    url: "/lists",
    menu: true,
    private: true,
    component: <ContactListsPage />,
  },
  {
    title: "Contacts",
    key: "contacts",
    icon: <FaRegListAlt />,
    url: "/lists/:id",
    menu: false,
    private: true,
    component: <ContactListPage />,
  },

  {
    title: "Campaigns",
    key: "campaigns",
    icon: <FaBolt />,
    url: "/campaigns",
    menu: true,
    private: true,
    component: <CampaignsPage />,
  },
  {
    title: "Contact",
    key: "contact",
    icon: <FaChartLine />,
    url: "/contacts/:id",
    menu: false,
    private: true,
    component: <ContactPage />,
  },
  {
    title: "Calls History",
    key: "calls",
    icon: <FaPhone />,
    url: "/calls",
    menu: true,
    private: true,
    component: <CallsPage />,
  },
  {
    title: "Calendars",
    key: "calendars",
    icon: <FaCalendar />,
    url: "/calendars",
    menu: true,
    private: true,
    component: <CalendarsPage />,
  },
  {
    title: "Settings",
    key: "settings",
    icon: <FaWrench />,
    url: "/settings",
    menu: true,
    private: true,
    component: <SettingsPage />,
  },
  {
    title: "Admin",
    key: "admin",
    icon: <FaChartLine />,
    url: "/admin-awaz",
    condition: ({user}) => user?.isAdmin,
    menu: true,
    private: true,
    component: <AdminPage />,
  },
  {
    title: "Share",
    key: "public",
    url: "/:key",
    menu: false,
    private: false,
    component: <PublicPage />,
  },
  // {
  //   title: "Privacy",
  //   key: "signup-company",
  //   icon: <FaRegFileWord />,
  //   url: "/privacy",
  //   component: <PolicyPage />,
  // },
  {
    title: "OAuth",
    key: "oauth",
    url: "/oauth/:origin",
    component: <OAuthPage />,
  },
];

export default routes;
