import {
  ActionIcon,
    Box,
    Button,
    Divider,
    Group,
    Menu,
    Paper,
    Text
} from "@mantine/core";
import React from "react";
import { useLanguage } from "../../contexts/language.context";
import InputField, { InputFieldTypes } from "../input_field";
import { FaEdit, FaEllipsisV, FaMagnet, FaPlus, FaTrash } from "react-icons/fa";
import { timezones } from "../../utility/util";
import { useApp } from "../../contexts/app.context";

export default function Actions({ data, setData, actionOptions = [], onUpdateAction }: any){
  const { str } = useLanguage();
  const { confirmDialog } = useApp();

  return <>
    <Group>
      <FaMagnet />
      <Text size="lg" fw="bold" style={{flex: 1}}>{str("ACTIONS")}</Text>
    </Group>
    {(data?.data?.actions ?? []).length === 0 && <Box mt="80px" mb="80px">
      <Text mb="xs" fw="bold" c="white" size="lg" ta="center">{str("AGENTS.NO_ACTION_TITLE")}</Text>
      <Text c="white" size="sm" ta="center">{str("AGENTS.NO_ACTION_SUBTITLE")}</Text>
    </Box>}
    {(data?.data?.actions ?? []).map((action, ai) => <Paper p="md" mt="md" style={{backgroundColor: '#242442'}}>
      <Group mb="xs">
        <Text style={{flex: 1}} fw="bold" size="lg">{actionOptions.find(ao => ao.type === action?.type)?.title ?? str("ACTION")}</Text>
        <Menu>
          <Menu.Target>
            <ActionIcon color="white" variant="transparent"><FaEllipsisV /></ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => onUpdateAction && onUpdateAction({...action, index: ai})}
              leftSection={<FaEdit />}
            >{str("UPDATE")}</Menu.Item>
            <Menu.Item
              leftSection={<FaTrash />}
              onClick={() => {
                confirmDialog({ text: str("AGENTS.CONFIRM_REMOVE_ACTION") }, ({ confirmed }) => {
                  if(confirmed){
                    setData(na => ({ ...na, data: { ...na?.data, actions: na.data?.actions.filter((v2, aj) => ai !== aj) } }))
                  }
                })
              }}
            >{str("REMOVE")}</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <Text c="gray" size="sm">{action?.description}</Text>
    </Paper>)}
  </>
}