import {
    Box,
    Button,
    Group,
    Menu,
    Text
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useLanguage } from "../../contexts/language.context";
import InputField, { InputFieldTypes } from "../input_field";
import { FaPlus } from "react-icons/fa";
import { useAgentVariables } from "../../contexts/app.context";
import { useAuth } from "../../contexts/auth.context";
import { groupBy } from "../../utility/util";
import moment from "moment";

export default function Variables({ data, setData }: any){
    const { str } = useLanguage();
    const companyVariables = useAgentVariables();
    const { userData } = useAuth();

    const [variables, setVariables] = useState([]);

    useEffect(() => {
      const vs = [];
      [
        ...(data?.data?.instructions ?? "").match(/(\{\{)(.*?)(\}\})/g) ?? [],
        ...(data?.data?.initial_message ?? "").match(/(\{\{)(.*?)(\}\})/g) ?? [],
      ].forEach((ks) => {
        const k = ks.replace(/\{\{|\}\}/g, "");
        if(!vs.includes(k)) vs.push(k);
      });
      setVariables(groupBy([
        ...vs,
        ...companyVariables.map(v => v.value),
      ], item => item).map(g => g[1][0]))
    }, [data?.data?.instructions, data?.data?.initial_message, companyVariables]);
  

    return <Box p="md">
      <Text mb="md" size="md" c="gray">Default variables for testing purpose</Text>
    {
      (variables ?? []).map(v => (
        <InputField
          title={v}
          value={
            [
              ...companyVariables.map(cv => cv.value),
              "current_time",
              "contact_name",
              "contact_phone",
              "contact_email",
            ].includes(v)
            ? {
              "Contact.Name": userData?.user?.name,
              "Contact.Email": userData?.user?.email,
              "Contact.Phone": `+${userData?.user?.phone_code}${userData?.user?.phone}`,
              "Contact.FirstName": (userData?.user?.name ?? "").split(" ")[0],
              "CurrentTime": moment().format(),
            }[v] || "Dynamic Data"
            : {...data?.data?.default_variables}[v]
          }
          name="vl"
          placeholder="Insert a default value"
          disabled={[
            ...companyVariables.map(cv => cv.value),
            "current_time",
            "contact_name",
            "contact_phone",
            "contact_email",
          ].includes(v)}
          mb="md"
          onChange={({vl}) => setData(na => ({...na, data: {
            ...na?.data,
            default_variables: { ...na?.data?.default_variables, [v]: vl}}
          }))}
        />
      ))
    }
  </Box>
}