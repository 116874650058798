import { Group, Loader, Paper, Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { IMAGE_SOURCE } from '../services/auth';
import { PUBLISH_WEBFLOW } from '../services/companies';
import { parseKeyword } from '../utility/util';
import html2canvas from 'html2canvas';
import { UPLOAD_FILES } from '../services/files';

export default function BlogItemGeneration({ title, isDraft }){
    const [published, setPublished] = useState(false);
    const [txt, setTxt] = useState("");
    const [bd, setBd] = useState("");
    const [image, setImage] = useState("");
    const [formData, setFormData] = useState<any>({});

    const imageRef = useRef<any>();

    const generateImage = async () => {
        html2canvas(imageRef.current).then(canvas => {
            canvas.toBlob((blob) => {
                UPLOAD_FILES([
                    blob,
                ])
                .then(res => {
                    setImage(res[0].url);
                    setFormData(fd => ({
                        ...fd, 
                        'main-image': res[0].url,
                        'thumbnail-image': res[0].url,
                    }))
                })
                .catch(err => {
                    notifications.show({title: "Failed to same image", message: err.message, color: 'red'})
                })
            });
        });
    }

    const generateDescription = async () => {
        setTxt("loading");
        const { data } = await axios.post("https://api.openai.com/v1/chat/completions", {
            "model": "gpt-4",
            "messages": [
                {
                    "role": "user",
                    "content": `Give me without quotes, description to insert in meta SEO description for a blog page about this theme: ${title}.`
                }
            ]
        }, { headers: { 'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_KEY}` } })
        let description = data.choices[0].message?.content;
        setTxt(description);
        setFormData(fd => ({
            ...fd, 
            'description': description,
            'post-summary': description,
        }))
    }

    const generateBody = async () => {
        setBd("loading");
        const opts = { headers: { 'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_KEY}` } };
        const { data: intro } = await axios.post("https://api.openai.com/v1/chat/completions", {
            "model": "gpt-4",
            "messages": [{ "role": "user", "content": `Generate 10 of the most frequently asked questions about ${title}` }]}, opts);
        let items = intro.choices[0].message?.content.split("\n");
            
        let detailsList = await Promise.all(items.map(async item => {
            const { data } = await axios.post("https://api.openai.com/v1/chat/completions", {
                "model": "gpt-4",
                "messages": [{ "role": "user", "content": `Writing about '${title}', talking about this topic: '${item}'. Use html format writing 3 paragraphs using lists, and tables where applicable. Without DOCTYPE, head and body tags, only the content. Without the topic title also, use only h3 headings` }]}, opts);
            return `<h2>${item}</h2>` + data.choices[0].message?.content.replace(/\n/g, "");
        }));

        let { data: conclusion } = await axios.post("https://api.openai.com/v1/chat/completions", {
            "model": "gpt-4",
            "messages": [{ "role": "user", "content": `Writing about '${title}', Summarise the blog and use this opportunity to sell the software awaz adding a hyperlink to the website www.awaz.ai. If using company name: Awaz.io: It's a whitelabel software to Sell, Manage Clients, Orders & Payments with Your Own Branded App. Website: www.awaz.ai. Use html format. Without DOCTYPE, head and body tags, only the content. Without the topic title also, use only h3 headings if needs` }]}, opts);
        let conclusionText = conclusion.choices[0].message?.content.replace(/\n/g, "");
        
        let body = detailsList.join("<br/><br/>") + `<br/><br/><h2>Conclusion</h2>${conclusionText}`;
            
        setBd(body);
        setFormData(fd => ({...fd,  'post-body': body }))
    }

    const publish = (fieldData) => {
        PUBLISH_WEBFLOW({
            isArchived: false,
            isDraft,
            fieldData,
        })
        .then(() => {
            setPublished(true);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: 'red' })
        })
    }

    useEffect(() => {
        if(title){
            setFormData(fd => ({
                ...fd,
                title: title,
                name: title,
                slug: parseKeyword(title).split(" ").join("-"),
                "main-image-alt-text": title,
                "thumbnail-alt-text": title,
            }));
            generateImage();
            generateDescription()
            generateBody()
        }
    }, [title]);
    
    useEffect(() => {
        if(formData['post-summary'] && formData['post-body'] && formData['main-image']){
            publish(formData);
        }
    }, [formData]);

    return <Paper mt="md" p="md" style={{borderColor: '#DFDFDF'}}>
        <Group>
            <Title style={{flex: 1}} order={5}>{title}</Title>
            {published && <FaCheckCircle color="green" />}
        </Group>
        <div ref={imageRef} style={{position: 'relative', width: 900, height: 450, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `url(/assets/thumbnails_blog.png)`}}>
            <Title order={1} style={{position: 'absolute', zIndex: 10, top: 90, left: 45, right: 500}}>{title}</Title>
        </div>
        {/* <Text size="md" mt="md">Keywords:</Text>
        {kw === "loading" ? <Loader size="sm" /> : <div style={{padding: 20, background: '#EFEFEF'}}>{kw}</div>} */}
        <Text size="md" mt="md">Description:</Text>
        {txt === "loading" ? <Loader size="sm" /> : <div style={{maxHeight: 200, padding: 20, background: '#EFEFEF', overflowY: 'auto'}}>{txt}</div>}
        <Text size="md" mt="md">Body:</Text>
        {bd === "loading" ? <Loader size="sm" /> : <div style={{maxHeight: 200, padding: 20, background: '#EFEFEF', overflowY: 'auto'}}>{bd}</div>}
    </Paper>
}