import { ActionIcon, Box, Button, Divider, Grid, Group, Image, Modal, Paper, Text, Title, UnstyledButton } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { HtmlEditor } from "../components/html_editor";
import { useApp } from "../contexts/app.context";
import { DELETE_KNOWLEDGE, DELETE_KNOWLEDGE_CATEGORY, GET_ALL_KNOWLEDGES, SAVE_KNOWLEDGE, SAVE_KNOWLEDGE_CATEGORY } from "../services/knowledges";
import { FaArrowLeft, FaEdit, FaPaperclip, FaPlus } from "react-icons/fa";
import SunEditor from 'suneditor-react';
import { useNavigate } from "react-router-dom";
import SimpleHeader from "../components/simple_header";
import { useMediaQuery } from "@mantine/hooks";

export default function KnowledgesPage(){
    const [knowledges, setKnowledges] = useState<any[]>([]);
    const [selectedKnowledgeCategory, setSelectedKnowledgeCategory] = useState<any>(null);
    const [selectedKnowledge, setSelectedKnowledge] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 900px)');

    const { confirmDialog } = useApp();

    const loadKnowledges = () => {
        GET_ALL_KNOWLEDGES()
        .then(res => {
            setKnowledges(res);
            if(selectedKnowledgeCategory){
                setSelectedKnowledgeCategory(res.find(r => r._id === selectedKnowledgeCategory?._id) ?? selectedKnowledge)
            }
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
        })
    }
    
    const handleSaveCategory = () => {
        let params = { ...selectedKnowledgeCategory };

        setLoadingSave(true)
        SAVE_KNOWLEDGE_CATEGORY(params)
        .then((res) => {
            notifications.show({ message: "Knowledge base saved!", color: "green" })
            loadKnowledges();
            setSelectedKnowledgeCategory(res)
            setLoadingSave(false);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
            setLoadingSave(false);
        })
    }
    
    const handleSave = () => {
        let params = { ...selectedKnowledge };

        setLoadingSave(true)
        SAVE_KNOWLEDGE({...params, category: selectedKnowledgeCategory._id})
        .then((res) => {
            notifications.show({ message: "Item saved!", color: "green" })
            loadKnowledges();
            setSelectedKnowledge(null)
            setLoadingSave(false);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
            setLoadingSave(false);
        })
    }

    useEffect(loadKnowledges, []);
    
    return <div style={{position: 'relative'}}>
        <SimpleHeader 
            variant="light"
            title={'Knowledge Base'}
            button={{title: "New Knowledge Base", leftSection: <FaPlus />, onClick: () => {
                setSelectedKnowledgeCategory({})
            }}}  />

        <Paper shadow='xs' style={{padding: 0}}>
            <Grid gutter={0}>
                {(!isMobile || !selectedKnowledgeCategory) && <Grid.Col span={{ base: 12, md: 4 }} style={{minHeight: '70vh'}}>
                    <Box style={{height: '100%'}}>
                        {knowledges.map((knowledge) => (
                            <UnstyledButton style={{width: '100%'}} onClick={() => {
                                // if(!allowed) notifications.show({
                                //     title: "Payment required",
                                //     message: "Check for your billing settings, there is one payment subscription that failed, change your payment method and try again.",
                                //     color: "yellow"
                                // })
                                setSelectedKnowledgeCategory(knowledge)
                            }}>
                                <Group
                                    style={{borderRight: `7px solid ${selectedKnowledgeCategory?._id === knowledge._id ? "black" : "transparent"}`}}
                                    p="xl"
                                >
                                    <Box style={{flex: 1}}>
                                        <Text size="md" fw="bold">{knowledge?.title}</Text>
                                    </Box>
                                </Group>
                                <Divider />
                            </UnstyledButton>
                        ))}
                    </Box>
                </Grid.Col>}
                {(!isMobile || selectedKnowledgeCategory) && <Grid.Col span={{ base: 12, md: 8 }} style={{minHeight: '70vh', backgroundColor: '#242442'}}>
                    {isMobile && <Group p="md">
                        <ActionIcon size="xl" onClick={() => setSelectedKnowledgeCategory(null)}><FaArrowLeft /></ActionIcon>
                    </Group>}
                    <Box p="md">
                        {selectedKnowledgeCategory?._id && <>
                            <Group mb="md">
                                <ActionIcon onClick={() => setSelectedKnowledgeCategory(null)}><FaArrowLeft/></ActionIcon>
                                <Text
                                    contentEditable
                                    onBlur={(e: any) => setSelectedKnowledgeCategory(kc => ({...kc, title: e.target.textContent}))}
                                    fw="bold" size="xl" style={{flex: 1}}>{selectedKnowledgeCategory.title}</Text>
                                <Button
                                    variant="filled"
                                    color="violet"
                                    onClick={handleSaveCategory}
                                    loading={loadingSave}
                                >Save</Button>
                            </Group>

                            <Grid>
                                <Grid.Col span={{base: 12, md: 6}}>
                                    <UnstyledButton style={{width: '100%'}} onClick={() => {setSelectedKnowledge({  })}}>
                                        <Paper p="md" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 200}}>
                                            <FaPlus size={40} color="#8a8a8a" />
                                            <Text c="#8a8a8a" mt="md" size="xl" fw="bold">New Item</Text>
                                        </Paper>
                                    </UnstyledButton>
                                </Grid.Col>
                                {
                                (selectedKnowledgeCategory?.items ?? [])
                                .map((kc : any) => (
                                    <Grid.Col span={{base: 12, md: 6}}>
                                        <UnstyledButton style={{width: '100%'}}
                                            onClick={() => {
                                                setSelectedKnowledge(kc)
                                            }}
                                        >
                                            <Paper p="md" style={{minHeight: 200, display: 'flex', flexDirection: 'column'}}>
                                                {
                                                    kc.type === "file"
                                                    ? <>
                                                        <Text fw="bold" size="md">{kc.title}</Text>
                                                        <Box p="sm" mt="md" style={{background: '#EFEFEF', borderRadius: 10, flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                            <FaPaperclip color="#8a8a8a" size={40} />
                                                            <Text ta="center" c="#8a8a8a" mt="xs" size="md">File</Text>
                                                        </Box>
                                                    </>
                                                    : <>
                                                        <Text fw="bold" size="md">{kc.title}</Text>
                                                        <Text size="sm" mt="xs" lineClamp={6}>{kc.body}</Text>
                                                    </>
                                                }
                                            </Paper>
                                        </UnstyledButton>
                                    </Grid.Col>
                                ))}
                            </Grid>
                        </>}
                    </Box>
                </Grid.Col>}
            </Grid>
        </Paper>

        {/* <Grid>
            {
                selectedKnowledge
                ? <Grid.Col span={{ base: 12, md: 12 }}>
                    <Group mb="md">
                        <ActionIcon onClick={() => setSelectedKnowledge(null)}>
                            <FaArrowLeft />
                        </ActionIcon>
                        <div style={{flex: 1}}></div>
                        <Button disabled={!selectedKnowledge._id} color="gray" variant="outline"
                            onClick={() => {
                                confirmDialog(
                                    { text: "Do you realy wants to delete this knowledge?" },
                                    ({ confirmed }) => {
                                        if(confirmed) DELETE_KNOWLEDGE(selectedKnowledge?._id)
                                        .then(() => {
                                            loadKnowledges();
                                            setSelectedKnowledge(null);
                                        })
                                        .catch(err => {
                                            notifications.show({ message: err.message })
                                        })
                                    }
                                )
                            }}
                        >Delete Item</Button>
                    </Group>

                    <Paper p="md" mb="md">
                        
                    </Paper>

                    <Group justify="flex-end" mt="md">
                        <Button size="md" onClick={handleSave} loading={loadingSave} color="green" className="draw-button">Save Item</Button>
                    </Group>
                </Grid.Col>
                : <Grid.Col span={{ base: 12, md: 12 }}>
                    <Group mb="md">
                        <InputField
                            name="pl"
                            style={{flex: 1}}
                            onChange={({pl}) => {
                                pl === "new"
                                ? setSelectedKnowledgeCategory({  })
                                : setSelectedKnowledgeCategory(knowledges.find(p => p._id === pl))
                            }}
                            value={selectedKnowledgeCategory ? selectedKnowledgeCategory?._id || "new" : null}
                            fieldType={InputFieldTypes.SELECT}
                            clearable={false}
                            options={[
                                ...knowledges.map(p => ({label: p.title, value: p._id}))
                                .sort((a,b) => a.label > b.label ? 1 : -1),
                                { value: "new", label: "Add new knowledge" }
                            ]}
                        />
                        {selectedKnowledgeCategory?._id && <Button color="gray" variant="outline"
                            onClick={() => {
                                confirmDialog(
                                    { text: "Do you realy wants to delete this category?" },
                                    ({ confirmed }) => {
                                        if(confirmed) DELETE_KNOWLEDGE_CATEGORY(selectedKnowledgeCategory?._id)
                                        .then(() => {
                                            loadKnowledges();
                                            setSelectedKnowledgeCategory(null);
                                        })
                                        .catch(err => {
                                            notifications.show({ message: err.message })
                                        })
                                    }
                                )
                            }}
                        >Delete Category</Button>}
                    </Group>

                </Grid.Col>
            }
        </Grid> */}

        <Modal
            opened={selectedKnowledgeCategory && !selectedKnowledgeCategory?._id}
            onClose={() => setSelectedKnowledgeCategory(null)}
        >
            <InputField
                name="title"
                mb="md"
                onChange={({title}) => setSelectedKnowledgeCategory(sp => ({...sp, title}))}
                value={selectedKnowledgeCategory?.title}
                title="Title"
            />
            <Group justify="flex-end">
                <Button size="md" onClick={handleSaveCategory} loading={loadingSave} color="green">Create Base</Button>
            </Group>
        </Modal>
        
        <Modal
            opened={!!selectedKnowledge}
            onClose={() => setSelectedKnowledge(null)}
        >
            <InputField
                name="type"
                onChange={({type}) => setSelectedKnowledge(sp => ({...sp, type}))}
                value={selectedKnowledge?.type ?? "simple"}
                title="Type"
                options={[
                    {label: "Texts", value: 'simple'},
                    {label: "File", value: 'file'},
                ]}
                fieldType={InputFieldTypes.BOX}
            />
            {
                selectedKnowledge?.type === "file"
                ? <>
                    <InputField
                        name="title"
                        mt="md"
                        onChange={({title}) => setSelectedKnowledge(sp => ({...sp, title}))}
                        value={selectedKnowledge?.title}
                        title="Title"
                    />
                    <InputField
                        name="url"
                        onChange={({url}) => setSelectedKnowledge(sp => ({...sp, url}))}
                        value={selectedKnowledge?.url}
                        title="File"
                        mt="md"
                        fieldType={InputFieldTypes.FILE}
                    />
                </>
                : <>
                    <InputField
                        name="title"
                        mt="md"
                        onChange={({title}) => setSelectedKnowledge(sp => ({...sp, title}))}
                        value={selectedKnowledge?.title}
                        title="Question"
                    />
                    <InputField
                        name="body"
                        mt="md"
                        onChange={({body}) => setSelectedKnowledge(sp => ({...sp, body}))}
                        value={selectedKnowledge?.body}
                        title="Answer"
                        fieldType={InputFieldTypes.TEXTAREA}
                    />
                </>
            }
            <Group justify="flex-end" mt="md">
                <Button size="md" onClick={handleSave} loading={loadingSave} color="green">Save Item</Button>
            </Group>
        </Modal>
    </div>
}
