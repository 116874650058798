import { Box, Button, Group, Loader, Table as MantineTable, Text } from '@mantine/core';
import React from 'react';
import { ActionProps, ActionsRender } from '../components/actions_render';
import { FaSortDown, FaSortUp } from 'react-icons/fa';

interface TableProps<T>{
    loading? : boolean;
    error? : string;
    sort? : { key?: string; direction? : "asc" | "desc" };
    data: T[];
    leftIcon?: any;
    columns: {key:string; title:any; render?: (item:T, index: number) => any; style?: any; itemStyle?: any}[];
    actions?: ActionProps[];
    styles?: any;
    [key: string]: any;
}
export default function Table(props : TableProps<any>){
    const {sort, actions = [], columns = [], loading = false, error, ...others} = props; 
    if(error) return <Group style={{color: "#9a9a9a", padding: '40px 0'}}>Falha ao carregar: {error}</Group>;
    
    return <>
        {/* <div style={{padding: '10px 0'}}>
            {loading && <Loader />}
        </div> */}
        <MantineTable.ScrollContainer minWidth={100} h="70vh">
            <MantineTable
                stickyHeader={true}
                verticalSpacing="xs"
                striped
                highlightOnHover
                style={{width: '100%'}}
                styles={props.styles}
            >
                <MantineTable.Thead style={{zIndex: 10}}>
                    <MantineTable.Tr>
                        {columns.map(column=> <MantineTable.Th key={column.key} style={{whiteSpace: 'nowrap', minWidth: 50, ...column.style}}>
                            <Group align='flex-start'>
                                <Text fw="bold" size="sm">{column.title}</Text>
                                {sort?.key === column.key ? (sort?.direction ?? "asc") === "asc" ? <FaSortDown /> : <FaSortUp /> : null}
                            </Group>
                        </MantineTable.Th>)}
                        {actions.length > 0 && <MantineTable.Th style={{width: 50}}></MantineTable.Th>}
                    </MantineTable.Tr>
                </MantineTable.Thead>
                <MantineTable.Tbody>
                    {props.data.map((item, index) => <MantineTable.Tr onClick={() => props.onClick && props.onClick(item, index)} key={`${index}`} style={{ cursor: props.onClick ? "pointer" : "initial"}}>
                        {columns.map(column => <MantineTable.Td style={{...column.itemStyle}}>{column.render ? column.render(item, index) : item[column.key]}</MantineTable.Td>)}
                        {actions.length > 0 && <MantineTable.Td><ActionsRender
                            actions={actions}
                            item={item}
                            index={index}
                        /></MantineTable.Td>}
                    </MantineTable.Tr>
                    )}
                </MantineTable.Tbody>
                {props.totals && <MantineTable.Tfoot>
                    {columns.map(column => <MantineTable.Th key={column.key}>
                        {{...props.totals}[column.key]}
                    </MantineTable.Th>)}
                </MantineTable.Tfoot>}
            </MantineTable>
        </MantineTable.ScrollContainer>
    </>
}