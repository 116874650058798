import { ActionIcon, Box, Button, Group, Paper, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React from 'react'
import { useApp } from '../../contexts/app.context';
import { EXPORT_COMPANIES, GET_ALL_COMPANIES } from '../../services/companies';
import Table from '../table';
import { FaCheck, FaSign, FaSignInAlt } from 'react-icons/fa';
import { Buffer } from 'buffer';
import { extenseRecurrenceType, formatMilliseconds, formatMoney, getExtenseDatetime } from '../../utility/util';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';
import moment from 'moment-timezone';

interface ListProps{}
interface ListHandle{
    refresh: () => void;
}

const List : React.ForwardRefRenderFunction<ListHandle, ListProps> = ({}, ref) => {
    const [loading, setLoading] = React.useState(false);
    const [loadingExport, setLoadingExport] = React.useState(false);
    const [error, setError] = React.useState<any>(null);
    const [data, setData] = React.useState([]);
    const [months, setMonths] = React.useState([]);
    const [typeView, setTypeView] = React.useState<string>("general");

    const { onChangeRole } = useAuth();
    const navigate = useNavigate();

    const handleExport = () => {
        setLoadingExport(true);

        let months = [];
        let m = moment("2024-01-01T00:00:00");
        while(m < moment().endOf("month")){
            months.push({ base: m.format() })
            m.add(1, "months");
        }

        let csvText = [
            [
                "status",
                "name",
                "email",
                "phone",
                "users",
                "total_dials",
                "used_credits",
                "total_credits",
                "plan",
                "next_payment",
                "domain",
                "signup_date",
                "total_payments",
                ...months.map(m => moment(m.base).format("MMMM/YYYY")),
            ],
            ...data.map(item => [
                item.billing?.activePaymentMethod
                    ? moment().diff(moment(item?.billing?.nextPayment), "days") > 0
                        ? "Not Payed"
                        : "Active"
                    : "Canceled"
                ,
                item.name,
                item.email,
                `+${item.phone_code ?? "1"}${item.phone}`.replace(/ |\(|\)|\-|\+|\_/g, ""),
                (item.users || []).length,
                `${formatMilliseconds(item.billing?.consumption * 1000)}`,
                `${formatMoney(item.billing?.creditsUsage)}`,
                `${formatMoney(item.billing?.totalCredits ?? 0)}`,
                item.billing?.plan?.title,
                item.billing?.nextPayment ? moment(item.billing?.nextPayment).format("YYYY-MM-DD") : null,
                (item.domains ?? [])[0] ? `https://${item.domains[0].url}` : "",
                item.createdAt,
                item.plan_payments.reduce((pv, p) => pv + ((p.payed_at && !p.refund_at) ? p.value : 0),0),
                ...months.map(month => ((item.plan_payments ?? [])
                    .filter(p => (
                        p.payed_at && !p.refund_at && 
                            moment(p.payed_at).isBetween(
                            moment(month.base).startOf("month"),
                            moment(month.base).endOf("month")
                        )
                    ))
                    .reduce((pv, p) => pv + p.value,0)
                ).toFixed(2))
            ])
        ].map(items => items.map(it => `"${(it ?? "").toString().trim()}"`).join(",")).join("\n")
    
        const byteCharacters = Buffer.from(csvText, "utf-8");
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) { byteNumbers[i] = byteCharacters[i]; }
        const byteArray = new Uint8Array(byteNumbers);
    
        const blob = new Blob([byteArray], { type: "application/csv" });
    
        const url = URL.createObjectURL(blob);;
        const a = document.createElement('a');
        a.href = url;
        a.download = `Awaz_Companies.csv`;
        a.click();
        URL.revokeObjectURL(url);

        setLoadingExport(false);
    }

    const COLUMNS = typeView === "payments"
    ? [
        {title: "Company", key: 'name', render: (item) => <Box>
            <Text size="sm">{item.name}</Text>
        </Box>},
        {title: "Total Payed", key: "total_payments", render: (item) => (
            item.plan_payments.reduce((pv, p) => pv + ((p.payed_at && !p.refund_at) ? p.value : 0),0).toFixed(2)
        )},
        ...months.map(month => ({
            title: moment(month.base).format("MMMM/YYYY"),
            key: `${month.base}`,
            render: (item) => ((item.plan_payments ?? [])
                .filter(p => (
                    p.payed_at && 
                    moment(p.payed_at).isBetween(
                        moment(month.base).startOf("month"),
                        moment(month.base).endOf("month")
                    )
                ))
                .reduce((pv, p) => pv + p.value,0)
            ).toFixed(2),
        }))
    ]
    : [
        { title: "Company", key: 'name', render: (item) => item.name},
        { title: "E-mail", key: 'email', render: (item) => item.email},
        { title: "Phone", key: 'phone', render: (item) => `+${item.phone_code ?? ""}${item.phone}`},
        { title: "Users", itemStyle: { whiteSpace: 'nowrap' }, key: "Users", render: (item) => (
            <Text size="sm">{(item.users || []).filter(u => u.roles && u.roles.length > 0 && !(u.roles || []).some(r => ["Client"].includes(r.profile?.title))).length}</Text>
        )},
        { title: "Usage", itemStyle: { whiteSpace: 'nowrap' }, key: "Usage", render: (item) => (
            <Text size="sm">{formatMilliseconds(item.billing?.consumption * 1000)} - {`${formatMoney(item.billing?.creditsUsage)} / ${formatMoney(item.billing?.totalCredits ?? 0)}`}</Text>
        )},
        { title: "Plan", itemStyle: { whiteSpace: 'nowrap' }, key: "Plan", render: (item) => (
            <Text size="sm">{item.billing?.plan?.title ?? "Free"}</Text>
        )},
        { title: "Status", itemStyle: { whiteSpace: 'nowrap' }, key: "Status", render: (item) => (
            <Text size="sm">{item.billing?.activePaymentMethod
                ? moment().diff(moment(item?.billing?.nextPayment), "days") > 0
                    ? "Not Payed"
                    : "Active"
                : "Canceled"}</Text>
        )},
        { title: "Next payment", itemStyle: { whiteSpace: 'nowrap' }, key: 'next-payment', render: (item) => <Box>
            <Text size="sm">{item.active_plan?.plan_type !== "one-time" && item.billing?.activePaymentMethod && `${getExtenseDatetime(item?.billing?.nextPayment, false)}`}</Text>
        </Box> },
        // { title: "Actions", key: 'actions', render: (item) => {
        //     let roles = (item.users || []).reduce((pv, u) => pv.concat(u.roles), []);
        //     let role = roles.find(r => ["Owner", "Admin"].includes(r.profile?.title))
        //     return role ? <Box>
        //         <ActionIcon
        //             onClick={() => {
        //                 navigate("/")
        //                 onChangeRole(role._id)
        //             }}
        //             variant='outline'
        //         >
        //             <FaSignInAlt />
        //         </ActionIcon>
        //     </Box> : null
        // }},
    ]

    const loadCompanies = () => {
        setError(null);
        setLoading(true);

        GET_ALL_COMPANIES()
        .then(companies => {
            setLoading(false);
            setData(companies);
        })
        .catch(err => {
            setLoading(false);
            setError(err.message);
            notifications.show({message: err.message, color: 'red'});
        })
    }

    const refresh = () => { 
        loadCompanies();
    }

    React.useEffect(refresh, []);
    
    React.useEffect(() => {
        let m = moment("2024-01-01T00:00:00");
        let ms = [];
        while(m < moment().endOf("month")){
            ms.push({ base: m.format() })
            m.add(1, "months");
        }
        setMonths(ms);
    }, []);

    React.useImperativeHandle(ref, () => ({
        refresh: () => { refresh(); }
    }));

    return <>
        <Paper p="sm" mt="md">
            <Group mb="md">
                <Button loading={loadingExport} variant='outline' onClick={() => setTypeView(p => p === "payments" ? "general" : "payments")}>
                    {typeView === "payments" ? "General View" : "Billing View"}
                </Button>
                <Button loading={loadingExport} onClick={handleExport}>Export</Button>
            </Group>
            <Table 
                mb="md"
                data={data}
                columns={COLUMNS}
                error={error}
                loading={loading}
                actions={[
                    // {
                    //     title: "Deletar", 
                    //     color: "red", 
                    //     loading: item => Boolean(loadingDelete[item.id]), 
                    //     onClick: ({item}) => {
                    //         app.confirmDialog(
                    //             {text: "Realmente deseja deletar esse usuário?"}, 
                    //             (({confirmed}) => {
                    //                 if(confirmed) deleteUser(item.id);
                    //             })
                    //         )
                    //     }
                    // }
                ]}
            />
            <Text c="gray" size="lg">Totals</Text>
            <Text>Consumption: {formatMilliseconds(data.reduce((pv, item) => (
                pv + (item.billing?.consumption ?? 0)
            ), 0) * 1000)}</Text>
            <Text>Used Credits: {formatMoney(data.reduce((pv, item) => (
                pv + (item.billing?.creditsUsage ?? 0)
            ), 0))}</Text>
            <Text>Total Credits: {formatMoney(data.reduce((pv, item) => (
                pv + (item.billing?.totalCredits ?? 0)
            ), 0) - 2000000)}</Text>
            <Text>Total Payments: {formatMoney(data.reduce((pv, item) => (
                pv + item.plan_payments.reduce((pv, p) => pv + ((p.payed_at && !p.refund_at) ? p.value : 0),0)
            ), 0))}</Text>
            <Text>Calls: {data.reduce((pv, item) => (
                pv + (item.billing?.quantityCalls ?? 0)
            ), 0)}</Text>
            <Text>Actives: {data.reduce((pv, item) => (
                pv + ((
                    item.billing?.activePaymentMethod &&
                    moment().diff(moment(item?.billing?.nextPayment), "days") <= 0
                ) ? 1 : 0)
            ), 0)}</Text>
            <Text>Unsubs: {data.reduce((pv, item) => (
                pv + (!item.billing?.activePaymentMethod ? 1 : 0)
            ), 0)}</Text>
        </Paper>
    </>
}

export default React.forwardRef(List);