import {
    ActionIcon,
    Box,
    Button,
    Grid, Group,
    Loader,
    Paper,
    Progress,
    Text,
    Title, Tooltip, UnstyledButton
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaCheckCircle, FaChevronLeft, FaFacebook, FaLinkedin, FaMicrochip, FaMicrophone, FaPinterest, FaReddit, FaTimesCircle, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import InputField, { InputFieldTypes } from "../components/input_field";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import { useLanguage } from "../contexts/language.context";
import { GENERATE_AGENT, GET_ALL_TEMPLATES } from "../services/agents";
import { CONSULT_LABEL_AVAILABILITY, GET_COMPANY_ONBOARDING_DATA, GET_SUGGESTION_AI, SAVE_COMPANY_ONBOARDING_DATA } from "../services/companies";
import { countriesList, sectorList } from "../utility/util";
import useCallAgent from "../components/use_call_agent";
  
  export default function Onboarding({ customer = false, shareLinkId = null, partner = false }){
    const { userData, role, companyHost, loadUserData } = useAuth();

    const navigate = useNavigate();
    const { hash } = useLocation();

    const isMobile = useMediaQuery('(max-width: 900px)');

    const [onboardingCallId, setOnboardingCallId] = useState<string>();
    const [playing, tooglePlay] = useCallAgent({ own: false, onFinish: ({ callId }) => {
        setOnboardingCallId(callId)
    } });
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAgentGeneration, setLoadingAgentGeneration] = useState<boolean>(false);
    const [steps, setSteps] = useState<any[]>([
        { tag: "create-agent", title: "Create Your First Agent", key: "first_agent", subtitle: "Welcome! First things first..." },
        // { tag: "brand", title: "Getting Started", key: "general", subtitle: "Welcome! First things first..." },
        // { tag: "services", title: "Getting Started", key: "services", subtitle: "Welcome! First things first..." },
        // { tag: "portal", title: "Getting Started", key: "whitelabel", subtitle: "Welcome! First things first..." },
        // { tag: "payment", title: "Getting Started", key: "payment", subtitle: "Welcome! First things first..." },
        // { title: "Share With Your Clients", subtitle: "Welcome! First things first..." },
    ]);
    const [data, setData] = useState<any>({});
    const [agentDetails, setAgentDetails] = useState<any>(null);
    
    const [loadingSuggestion, setLoadingSuggestion] = useState<any>({});
  
    const handleChangeGeneral = (dt) => setData(c => ({ ...c, general: {...c.general, ...dt }}))
    const handleChangeWhitelabel = (dt) => setData(c => ({ ...c, whitelabel: {...c.whitelabel, ...dt }}))
  
    const navigateToTab = (index, save = false) => {
        const cb = () => {
            if(index === steps.length){
                loadUserData();
                navigate("/");
            }else setSelectedTab(index);
        }
        const key = steps[index - 1]?.key;
        if(save && key){
            setLoading(true)
            SAVE_COMPANY_ONBOARDING_DATA({ key, data })
            .then(() => {
                setLoading(false);
                cb();
            })
            .catch((err) => {
                notifications.show({ message: err.message, color: "red" })
                setLoading(false)
            })
        }else{
            cb();
        }
    }
    
    const generateAgent = () => {
        if(onboardingCallId){
            setLoadingAgentGeneration(true)
            GENERATE_AGENT({ callId: onboardingCallId })
            .then((data) => {
                // setAgentDetails(data);
                navigate(`/agents/${data?.agent?._id}`)
                setLoadingAgentGeneration(false);
            })
            .catch((err) => {
                notifications.show({ message: err.message, color: "red" })
                setLoadingAgentGeneration(false)
            })
        }
    }

    useEffect(generateAgent, [onboardingCallId])

    const continueForm = <>
        <Box mt="xl">
            <Button
                onClick={() => {
                    if(!playing){
                        if(agentDetails) navigate(`/agents/${agentDetails?.agent?._id}`);
                        else tooglePlay({ agentId: `66e8294e1b93f519d335cfe4` });
                    }
                }}
                type="button"
                loading={loading || loadingAgentGeneration}
                leftSection={playing && <FaMicrophone />}
                color="violet"
                fullWidth
                size="lg">{playing ? `Speak` : agentDetails ? "Go to Dashboard" : "Start Call"}</Button>
        </Box>
        <Box mt="md">
            <Button variant="transparent" fullWidth c="gray" onClick={() => {
                loadUserData();
                navigate("/");
            }}>Skip Onboarding</Button>
        </Box>
    </>
    
    const CallStep = <Box>
        {
            (loadingAgentGeneration)
            ? <Text size="lg" ta="center">Wait for a second, we are generating your first agent!</Text>
            : <Text size="lg" ta="center">Let's start building your agent with Anthony, just start a conversation with him and let him make it for you!</Text>
        }
    </Box>;

    const activeForm = [
      isMobile
      ? { principal: <>
        { CallStep }
      </>}
      : { principal: CallStep, secondary: null, continueRight: false },
    ].filter(nn => nn)[selectedTab]

    useEffect(() => {
        if(data?.general && !data?.general?.country){
            axios.get("https://ipinfo.io")
            .then(({data}) => {
                handleChangeGeneral({ country: data.country });
            })
            .catch(err => {});
        }
    }, [data])

    useEffect(() => {
        setLoading(true);
        GET_COMPANY_ONBOARDING_DATA()
        .then(res => {
            setData(res);
            setLoading(false);
        })
        .catch(err => {
            notifications.show({title: "Ops", message: err.message, color: 'red'})
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        if (hash) {
            const tabIndex = steps.findIndex(s => s.tag === hash.split("#")[1])
            setSelectedTab(tabIndex > -1 ? tabIndex : 0);
        }
      }, [hash, steps]);
  
    if(!role) return null;
    return role?.profile?.title === "Client"
    ? <Navigate to="/" />
    : (
        <div style={{
            backgroundImage: `url(/soundwave.png)`,
            backgroundColor: '#12141d',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundAttachment: 'fixed',
            position: 'relative',
            minHeight: '100vh',
            zIndex: 0,
            ...(
                isMobile
                ? {
                    padding: 20,
                }
                : {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: activeForm?.secondary ? '10vh 10vw' : '10vh 25vw',
                }
            )
        }}>
            <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: '#00000055', zIndex: -1}}></div>
            <Paper p="md" style={{borderColor: '#DFDFDF'}}>
                    {isMobile && activeForm?.secondary
                    ? activeForm?.secondary
                    : <Grid>
                        <Grid.Col span={{ base: 12, md: activeForm?.secondary ? 7 : 12 }}>
                            <div style={{display: 'flex', margin: '40px 0', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                <img alt="awaz" src={companyHost?.image ? companyHost.image : `/logo-white.png`} style={{maxHeight: 30}}/>
                            </div>
                            
                            <Group style={{padding: isMobile ? '0 4px' : '0 60px'}}>
                                <ActionIcon onClick={() => setSelectedTab(i => i-1)} style={{visibility: selectedTab === 0 ? 'hidden' : 'visible'}}><FaChevronLeft /></ActionIcon>
                                <Box style={{flex: 1}}>
                                    <Text ta="center" c="gray" size="sm">{steps[selectedTab]?.subtitle}</Text>
                                    <Title ta="center" order={2}>{steps[selectedTab]?.title}</Title>
                                </Box>
                                <ActionIcon style={{visibility: 'hidden'}}><FaChevronLeft /></ActionIcon>
                            </Group>

                            <Box style={{padding: isMobile ? '20px 10px 0 10px' : '40px 60px 0 60px'}}>
                                <Box style={{padding: '0 0 40px 0'}}>
                                    <Progress
                                        value={(((selectedTab??0)+1)/(steps.length))*100}
                                        color="violet"
                                    />
                                </Box>
                                <form onSubmit={e => {e.preventDefault(); navigateToTab(selectedTab+1, true) }} style={{overflowY: 'auto'}}>
                                    {activeForm?.principal}
                                    {(!activeForm.continueRight || !activeForm.secondary) && continueForm}
                                </form>
                        </Box>
                    </Grid.Col>
                    {activeForm?.secondary && !isMobile && <Grid.Col span={{ base: 12, md: 5 }}>
                        {activeForm.secondary}
                        {activeForm.continueRight && continueForm}
                    </Grid.Col>}
                </Grid>}
            </Paper>
        </div>
    );
};
  

const AgentData = ({ data, onChange }) => {
    const { str } = useLanguage();
    const { agentLanguages } = useApp();

    return <>
        <Group style={{ flexDirection: 'column', alignItems: 'unset', justifyContent: 'unset' }}>
            <InputField
                name="role"
                title={str("AGENTS.ROLE")}
                placeholder={str("AGENTS.ROLE_PLACEHOLDER")}
                value={data?.role}
                onChange={({role}) => onChange({ role })}
            />

            <InputField
                name="language"
                title={str("LANGUAGE")}
                value={data?.language ?? "en-US"}
                fieldType={InputFieldTypes.SELECT}
                clearable={false}
                onChange={({ language }) => onChange({ language })}
                options={agentLanguages}
            />

            <InputField
                name="objective"
                description={""}
                placeholder={""}
                value={data?.data?.objective}
                fieldType={InputFieldTypes.TEXTAREA}
                minRows={8}
                maxRows={8}
                maxLength={1000}
                onChange={({ objective }) => onChange(na => ({ ...na,  objective }))}
            />

            {/* <Group style={{ overflowX: 'scroll', padding: '20px 0', flexWrap: 'nowrap' }}>
                {templates.map((agent) => (
                    <Paper shadow='xs' style={{ padding: 0, backgroundColor: '#242442' }}>
                        <Box p="lg">
                            <Text size="xl" fw="bold">{agent.title}</Text>
                            {agent.description && <Text c="gray" mt="xs" size="md">{agent.description}</Text>}
                        </Box>
                        <Group p="md">
                            <Badge variant="light" color="pink">{agent.language ?? "en-US"}</Badge>
                            <Badge variant="light" color="blue">{agent.role ?? "Other"}</Badge>
                        </Group>
                        <Divider color="#515177" />
                        <Group>
                            <Button m="xs" style={{flex: 1}} variant="transparent" color="white"
                                onClick={() => {  }}
                                leftSection={<FaWrench />}
                            >SELECT TEMPLATE</Button>
                        </Group>
                    </Paper>
                ))}
            </Group> */}
        </Group>
    </>
}