import { ActionIcon, Button, Grid, Group, Modal, Paper, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import React, { useEffect, useState } from 'react'
import { FaPlus, FaTrash } from 'react-icons/fa'
import { SAVE_CONTACT } from '../services/contacts'
import InputField, { InputFieldTypes } from './input_field'
import { useApp } from '../contexts/app.context'
import { useAuth } from '../contexts/auth.context'

export default function NewContact({ open, onClose, data, onChange, onSave = null, lists = [] }: any) {
    const [loadingSave, setLoadingSave] = useState(false);
    
    const { userData } = useAuth();

    const handleSave = () => {
        try {
            if (!data.name) throw new Error("Type the contact name");
            if (!data.identifiers[0].value) throw new Error("Type the contact phone");
            setLoadingSave(true)
            SAVE_CONTACT({ ...data, lists })
                .then(({ contact }) => {
                    setLoadingSave(false)
                    onSave && onSave(contact)
                    onClose()
                })
                .catch(err => {
                    setLoadingSave(false)
                    showNotification({ title: "Ops", message: err.message, color: 'red' })
                })
        } catch (err) {
            setLoadingSave(false)
            showNotification({ title: "Ops", message: err.message, color: 'red' })
        }
    }

    useEffect(() => {
        if (open && (data?.identifiers || []).length === 0) {
            onChange({ identifiers: [{ key: "phone" }, { key: "email" }] })
        }
    }, [open, data]);

    return <Modal
        opened={open}
        onClose={onClose}
        size="xl"
        title={<Title order={2}>New Contact</Title>}
    >
        <Grid mt="lg" mb="lg">
            <Grid.Col span={{base: 12, md: 6}}>
                <InputField
                    name="name"
                    fullWidth
                    title="Full Name"
                    required
                    onChange={onChange}
                    value={data?.name}
                />
            </Grid.Col>
            <Grid.Col span={{base: 12, md: 6}}>
                <InputField
                    name="nickname"
                    fullWidth
                    title="Nickname"
                    onChange={onChange}
                    value={data?.nickname}
                />
            </Grid.Col>
            <Grid.Col span={{base: 12, sm: 12}}>
                <Grid>
                    {(data?.identifiers || []).map((ident, i) =>
                        <Grid.Col span={{base: 12, md: 6}}>
                            <InputField
                                name="value"
                                fullWidth
                                required={ident.key === "phone"}
                                type={ident.key === "email" ? "email" : "phone"}
                                title={ident.key ? ident.key === "email" ? "E-mail" : "Phone" : " "}
                                onChange={({ value }) => onChange({ identifiers: data.identifiers.map((item, j) => i === j ? { ...item, value } : item) })}
                                value={ident.value}
                            />
                        </Grid.Col>

                    )}
                    {(userData?.company?.fields ?? []).map(field => {
                        let extras = data?.extras ?? [];
                        const existentField = extras.find(ot => ot.title === field.title);
                        return (
                            <Grid.Col span={{base: 12, md: 6}}>
                                <InputField
                                    name={"vl"}
                                    fullWidth
                                    title={field.title}
                                    onChange={({ vl }) => {
                                        if(existentField){
                                            extras = extras.map(ot => ot.title === field.title ? { ...ot, value: vl } : ot)
                                        }else{
                                            extras = [...extras, { key: field.title, title: field.title, value: vl }]
                                        }
                                        onChange({ extras })
                                    }}
                                    value={existentField?.value}
                                />
                            </Grid.Col>
                        )
                    })}
                </Grid>
            </Grid.Col>
        </Grid>
        <Group mt="lg" justify='flex-end'>
            <Button onClick={onClose} disabled={loadingSave} variant="white">Cancel</Button>
            <Button onClick={handleSave} disabled={loadingSave} loading={loadingSave} variant='filled'>Save</Button>
        </Group>
    </Modal>

}