import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Divider,
  Grid, Group, Image, Modal, Paper,
  Popover,
  Progress,
  Switch,
  Text,
  Title, Tooltip, UnstyledButton
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { FaCheck, FaCheckCircle, FaChevronLeft, FaInfoCircle } from "react-icons/fa";
import { Link, Location, Navigate, useLocation, useNavigate } from "react-router-dom";
import InputField, { InputFieldTypes } from "../components/input_field";
import { useAuth } from "../contexts/auth.context";
import { api } from "../services/api";
import { extenseRecurrenceType, phoneCountryCodes, useQuery } from "../utility/util";
import { GET_ALL_PLANS } from "../services/plans";
import { PaymentForm } from "../components/payment_form";
import { useApp } from "../contexts/app.context";
import { FORGOT_PASSWORD, SUBSCRIBE } from "../services/auth";
import RenderImage from "../components/render_image";
import { GET_STRIPE_CONFIGS, SAVE_PAYMENT_METHOD } from "../services/payments";
import { useLanguage } from "../contexts/language.context";

export default function Login({ }){
  const { login, user, companyHost } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const {str} = useLanguage();
  const isMobile = useMediaQuery('(max-width: 900px)');
  
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [firstImpression, setFirstImpression] = useState(false);
  const [email, setEmail] = useState("");
  const [forgot, setForgot] = useState(null);
  const [password, setPassword] = useState("");
  const [selectedItem, setSelectedItem] = useState(0);
  const [expandedService, setExpandedService] = useState(null);

  const state = location.state as { from: Location };
  const from = state ? state.from.pathname : "/";

  const brandColor = {
    "black": "#12141d",
    "green": "#00DB72",
    "yellow": "#FFD540",
    "violet": "#FF4D17",
    "white": "#FBFBFB",
    "blue": "#0A39FC",
    "pink": "#FC0A6B",
  }[companyHost?.appearance?.colors?.navbar ?? "black"];

  const contrast = {
    "black": "#FBFBFB",
    "green": "#000000",
    "yellow": "#000000",
    "violet": "#FBFBFB",
    "blue": "#FBFBFB",
    "pink": "#FBFBFB",
    "white": "#000000",
  }[companyHost?.appearance?.colors?.navbar ?? "black"];

  const confirmForgot = e => {
    e.preventDefault();
    const email = forgot.email;
    FORGOT_PASSWORD(email)
    .then(() => {
      setForgot(null);
      notifications.show({title: "Forgot password", message: `A password email has been sent to ${email} with instructions to recover your password`})
    })
    .catch(err => {
      notifications.show({title: "Ops", message: err.message, color: 'red'})
    }) 
  }

  useEffect(() => {
    const tim = setTimeout(() => {
      setSelectedItem(
        selectedItem < (companyHost?.services ?? []).length - 1
        ? selectedItem + 1
        : 0
      )
    }, 4000);
    return () => clearTimeout(tim);
  }, [selectedItem, companyHost?.services])
  
  useEffect(() => {
    setFirstImpression(!companyHost || !(companyHost?.phone && companyHost?.email))
  }, [companyHost])

  return !!user
  ? <Navigate to="/" state={{ from }} />
  : (
    <div style={{
        backgroundImage: `url(/soundwave.png)`,
        backgroundColor: '#12141d',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        position: 'relative',
        minHeight: '100vh',
        zIndex: 0,
        ...(
            isMobile
            ? {
                padding: 20,
            }
            : {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '10vh 0',
            }
        )
    }}>
        <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: '#00000055', zIndex: -1}}></div>
        <Paper p="xl" style={{width: isMobile ? "auto" : 480}}>
          <>
            <div style={{display: 'flex', margin: '40px 0', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <img alt="awaz" src={companyHost?.image ? companyHost?.image : `/logo-white.png`} style={{maxHeight: 80, maxWidth: '180px'}}/>
            </div>
            
            <Group style={{padding: isMobile ? '0 4px' : '0 60px'}} mb="xl">
                <Box style={{flex: 1}}>
                    <Title ta="center" c="white" order={2}>{str("LOGIN.TITLE")}</Title>
                    <Text ta="center" c="#B3B3F2" size="sm" mt="xs">{str("LOGIN.SUBTITLE")}</Text>
                </Box>
            </Group>

            <form
              onSubmit={(e) => {
                setLoadingLogin(true)
                e.preventDefault();
                login(email, password, () => {
                  setLoadingLogin(false)
                  navigate(from, { replace: true });
                }, () => {
                  setLoadingLogin(false)
                });
              }}
            >
              <InputField
                mt="md"
                size="lg"
                title={str("EMAIL")}
                label={null}
                value={email}
                name="value"
                c={contrast}
                autoCapitalize="off"
                onChange={({value}) => setEmail(value)}
                />
              <InputField
                mt="xl"
                size="lg"
                title={str("PASSWORD")}
                name="value"
                label={null}
                fieldType={InputFieldTypes.PASSWORD}
                c={contrast}
                value={password}
                autoCapitalize="off"
                onChange={({value}) => setPassword(value)}
              />
              <UnstyledButton mt="md" onClick={() => setForgot({ email })} style={{width: '100%'}}>
                <Text style={{textDecoration: 'undeline'}} size="sm" c="#8a8a8a" ta="right">{str("LOGIN.FORGOT_PASSWORD_QUESTION")}</Text>
              </UnstyledButton>
              <Box mt="xl" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Button
                  type="submit"
                  loading={loadingLogin}
                  color="violet"
                  variant="filled"
                  size="md">{str("LOGIN.LOGIN")}</Button>
              </Box>
              <Group mt="sm" mb="sm">
                <Divider style={{flex: 1, margin: '0 10px', borderColor: '#6a6a6a'}} />
                <Text c="#6a6a6a">{str("OR").toUpperCase()}</Text>
                <Divider style={{flex: 1, margin: '0 10px', borderColor: '#6a6a6a'}} />
              </Group>
              <Box mt="sm" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Button
                      color="violet"
                      variant="outline"
                      onClick={() => navigate("/signup")}
                      size="md">{str("LOGIN.SIGNUP")}</Button>
              </Box>
            </form>
          </>
        </Paper>

        <Modal size="sm" opened={!!forgot} onClose={() => setForgot(null)}>
          <form onSubmit={confirmForgot}>
            <InputField mt="md" name="email" autoCapitalize="off" placeholder={str("EMAIL_PLACEHOLDER")} title={str("EMAIL")} value={forgot?.email} onChange={({email}) => setForgot(f => ({...f, email}))} />
            
            <Group justify="flex-end" mt="lg">
              {/* <Button color="violet" variant="outline" type="button" mr="xs" onClick={() => setForgot(null)}>Cancel</Button> */}
              <Button color="violet" variant="filled" type="submit">{str("LOGIN.RECOVER_PASSWORD")}</Button>
            </Group>
          </form>
        </Modal>
    </div>
  );
};
