import { ActionIcon, Badge, Box, Checkbox, ColorInput, ComboboxItem, Grid, Group, Indicator, Loader, MantineSize, MultiSelect, Paper, PasswordInput, SegmentedControl, Select, Slider, Switch, Text, Textarea, TextInput, UnstyledButton, useMantineTheme } from '@mantine/core';
import { DatePickerInput, DateTimePicker, TimeInput } from '@mantine/dates';
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FaCheckCircle, FaEnvelope, FaEye, FaFileImage, FaMinus, FaPhone, FaPlus, FaSearch, FaTimesCircle, FaUpload } from 'react-icons/fa';
import InputMask from "react-input-mask";
import NumberFormat from 'react-number-format';
import { UPLOAD_FILES } from '../services/files';
import { groupBy, parseKeyword } from '../utility/util';
import { useLanguage } from '../contexts/language.context';
import { MentionsInput, Mention } from 'react-mentions'
import { SEARCH_CONTACT } from '../services/contacts';

export enum InputFieldTypes{
    STRING = "string",
    NUMBER = "number",
    SELECT = "select",
    SLIDE = "slide",
    SEARCH = "search",
    CHECKBOX = "checkbox",
    TRANSFER = "transfer",
    IMAGE = "image",
    FILE = "file",
    DATE = "date",
    DATETIME = "datetime",
    QUANTITY = "quantity",
    TIME = "time",
    SWITCH = "switch",
    DATERANGE = "daterange",
    BOOLEAN = "boolean",
    EXCEL = "excel",
    TEXTAREA = "textarea",
    BOX = "box",
    COLOR = "color",
    PASSWORD = "password",
}
export interface InputFieldProps{
    name:string
    title?:string;
    size?: MantineSize;
    value?: any;
    onChange?: any;
    loading?: boolean;
    min?: number;
    max?: number;
    options?: {value:string; label:string; group?: string}[];
    fieldType?: InputFieldTypes;
    multiple?: boolean;
    mask?: InputFieldMasks;
    customMask?: string | string[];
    maskPlaceholder?: string;
    maskChar?: string;
    icon?: any;
    style?: any;
    styles?: any;
    variant?: string;
    minRows?: number;
    maxRows?: number;
    placeholder?: string;
    searchable?: boolean;
    clearable?: boolean;
    beforeMaskValueChange?: any;

    [x:string]: any;
}
export enum InputFieldMasks{
    MONEY = "money",
    CUSTOM = "custom",
}
export default function InputField(props : InputFieldProps){
    const {
        name,
        value,
        mask,
        customMask,
        maskPlaceholder = " ",
        maskChar = " ",
        title,
        description,
        fieldType = InputFieldTypes.STRING,
        loading,
        onChange,
        options = [],
        minRows = 5,
        maxRows = 8,
        multiple,
        icon,
        style,
        placeholder,
        clearable = true,
        styles,
        beforeMaskValueChange,
        dimensions = null,
        variant = "filled",
        size = "md",
        min = null,
        max = null,
        ...others
    } = props; 
    const [files, setFiles] = React.useState([]);
    const [replacedValue, setReplacedValue] = React.useState<any[]>([]);
    const [searchOptions, setSearchOptions] = React.useState<any[]>([]);
    const [hashOptions, setHashOptions] = React.useState<any>({});
    const [searchKeyword, setSearchKeyword] = React.useState("");
    const [searchedKeyword, setSearchedKeyword] = React.useState(null);
    const [results, setResults] = React.useState([]);
    const [loadingSearch, setLoadingSearch] = React.useState(false);
    
    const { str } = useLanguage();
    const theme = useMantineTheme();

    const [loadingUpload, setLoadingUpload] = React.useState(false);

    const handleSearch = () => {
        if(others.searchType === "contact"){
            if(searchKeyword.trim().length > 2){
                setLoadingSearch(true)
                SEARCH_CONTACT(searchKeyword)
                .then((contacts) => {
                    setLoadingSearch(false);
                    setResults(contacts);
                    setSearchedKeyword(searchKeyword.trim());
                    let newHash = {};
                    for(let contact of contacts){
                        newHash[contact._id] = contact;
                    }
                    setHashOptions(h => ({ ...h, ...newHash }));
                })
                .catch(err => {
                    setLoadingSearch(false)
                    notifications.show({title: "Ops", message: err.message, color: 'red'})
                })
            }
        }
    }

    const onInsertFiles = (items = []) => {
        setLoadingUpload(true)
        UPLOAD_FILES(items)
        .then(res => {
            console.log({[name]: multiple ? res.map(({url}) => url) : res[0] ? res[0].url : null})
            setLoadingUpload(false)
            setFiles(res.map(({url}) => url))
            onChange && onChange({[name]: multiple ? res.map(({url}) => url) : res[0] ? res[0].url : null}); 
        })
        .catch(err => {
            setLoadingUpload(false)
            notifications.show({title: "Failed to save file", message: err.message, color: 'red'})
        })
    }

    const onChangeTextArea = e => {
        // let fullHtml = e.currentTarget.innerHTML;
        // <div class="content-bdg"></div>
    }

    useEffect(() => {
        if([InputFieldTypes.QUANTITY].includes(fieldType) && min){
            onChange({ [name]: min })
        }
    }, [fieldType, min, name])
    
    useEffect(() => {
        handleSearch()
    }, [searchKeyword])
    
    useEffect(() => {
        const items = [
            value ? value : null,
            ...results,
        ].filter(nn => nn);
        setSearchOptions(groupBy(items, opt => opt._id).map(g => g[1][0]))
    }, [value, results])
    
    // useEffect(() => {
    //     if(fieldType === InputFieldTypes.TEXTAREA){
    //         const nv = [];
    //         nv.push(<div className='content-bdg'>OPAAA</div>);
    //         nv.push(' ');
    //         nv.push(value ?? ' ');
    //         setReplacedValue(nv);
    //     }
    // }, [fieldType, value])
    
    if(fieldType === InputFieldTypes.COLOR){
        return <ColorInput
            label={title}
            value={value ?? undefined}
            title={title}
            placeholder={placeholder ?? title}
            style={style}
            styles={styles}
            size={size}
            variant={variant}
            onChange={(color) => onChange && onChange({[name]: color})}
            {...others}
        />
    }
    if(fieldType === InputFieldTypes.SLIDE){
        return <>
            {title && <Text size="sm">{title}</Text>}
            <Slider
                label={title}
                value={value ?? undefined}
                title={title}
                placeholder={placeholder ?? title}
                style={style}
                styles={styles}
                size={size}
                variant={variant}
                onChange={(color) => onChange && onChange({[name]: color})}
                min={min ?? 0}
                max={max ?? 100}
                {...others}
            />
        </>
    }
    if(fieldType === InputFieldTypes.SEARCH){
        const renderSelectOption = ({ option, checked }) => {
            let item = {...hashOptions[option.value]};
            return <Group flex={1}>
                <Box style={{flex: 1}}>
                    <Text size="sm">{item.name}</Text>
                    <Group>
                        {(item.identifiers ?? []).map(i => <Group mt="xs">
                            {i.key === "phone" ? <FaPhone color="#8a8a8a" size={12} /> : <FaEnvelope color="#8a8a8a" size={12} />}
                            <Text style={{flex: 1}} c="#8a8a8a" size="xs">{i.value}</Text>
                        </Group>)}
                    </Group>
                </Box>
                {checked && <FaCheckCircle color="#8a8a8a" />}
            </Group>
        };
        const optionsFilter = ({ options, search }) => {
            const splittedSearch = search.toLowerCase().trim().split(' ').map(parseKeyword);
            return (options as any[]).filter((option) => {
                let item = {...hashOptions[option.value]};
                let words = [
                    ...item.name.split(" "),
                    ...(item.identifiers ?? []).map(i => i.value),
                ].filter(nn => nn).map(parseKeyword);
                return splittedSearch.every((searchWord) => words.some((word) => word.includes(searchWord)));
            });
        };
        return <Grid mt="lg">
            <Grid.Col span={12}>
                <form onSubmit={e => { e.preventDefault(); handleSearch() }}>
                <Group>
                    <InputField
                        style={{flex: 1}}
                        name="tx"
                        fullWidth
                        placeholder={placeholder ?? str("CONTACTS.SEARCH_HELPER")}
                        clearable={clearable}
                        rightSection={loadingSearch && <Loader size="xs" />}
                        size="md"
                        fieldType={InputFieldTypes.SELECT}
                        options={searchOptions.map(contact => ({
                            value: contact._id,
                            label: contact.name,
                        }))}
                        title={title}
                        filter={optionsFilter}
                        renderOption={renderSelectOption}
                        onSearchChange={(vl) => {
                            setSearchKeyword(vl);
                        }}
                        onChange={({tx}) => {
                            const item = searchOptions.find(v => v._id === tx);
                            onChange({ [name]: item })
                        }}
                        value={value?._id}
                    />
                    {/* <ActionIcon loading={loadingSearch} size="lg" variant='filled' type='submit' disabled={searchKeyword.trim().length <= 2}><FaSearch /></ActionIcon> */}
                </Group>
                </form>
            </Grid.Col>
            {/* <Grid.Col span={12}>
                {
                    results.length === 0 && <Text c="gray">{
                        searchedKeyword
                        ? <>{str("CONTACTS.NO_CONTACT")} <Badge variant='filled' color="violet">{searchedKeyword}</Badge></>
                        : ""
                    }</Text>
                }
                {
                    results.map((contact) => <UnstyledButton mb="md" style={{width: '100%'}} onClick={() => { onChange({ [name]: contact._id }); }}>
                        <Paper shadow="xs" p="md" style={{backgroundColor: '#242442'}}>
                            <Group>
                                <div style={{flex: 1}}>
                                    <Text>{contact.name}&nbsp;</Text>
                                </div>
                            </Group>
                        </Paper>
                    </UnstyledButton>)
                }
            </Grid.Col> */}
        </Grid>
    }
    if([InputFieldTypes.QUANTITY].includes(fieldType)){
        return <>
            {title && <Text size="sm">{title}</Text>}
            <Group {...others} align='center'>
                <ActionIcon disabled={min && parseInt(value) <= min} size={"sm"} color='gray' variant='filled' onClick={() => {
                    if(min && (parseInt(value) - 1) <= min) onChange({[name]: min});
                    else onChange({[name]: value ? parseInt(value) - 1 : 0});
                }}><FaMinus size="12px" /></ActionIcon>
                <Box style={{width: 100}}>
                    <InputField
                        name="vl"
                        value={value || "0"}
                        type="number"
                        size={"xs"}
                        placeholder={placeholder ?? title}
                        onChange={({vl}) => {
                            vl = isNaN(vl) ? 0 : parseInt(vl || "0");
                            if(min && parseInt(vl) <= min) onChange({[name]: min});
                            else if(max && parseInt(vl) >= max) onChange({[name]: max});
                            else onChange({[name]: parseInt(vl)});
                        }}
                        readOnly
                        {...others}
                        style={{...style, cursor: 'pointer', fontWeight: 'bold'}}
                        styles={{input: {textAlign: 'center'}}}
                    />
                    {others.centerSection}
                </Box>
                <ActionIcon disabled={max && parseInt(value) >= max} size={"sm"} color='gray' variant='filled' onClick={() => {
                    if(max && (parseInt(value) + 1) >= max) onChange({[name]: max});
                    else onChange({[name]: value ? parseInt(value) + 1 : 1});
                }}><FaPlus size="12px" /></ActionIcon>
            </Group>
        </>
    }
    if([InputFieldTypes.SWITCH].includes(fieldType)){
        return <Switch
            label={title}
            checked={!!value}
            onChange={() => onChange({[name]: !value})}
            style={{...style, cursor: 'pointer', fontWeight: 'bold'}}
            {...others}
        />
    }
    if([InputFieldTypes.CHECKBOX].includes(fieldType)){
        return <Checkbox
            label={title}
            checked={!!value}
            onChange={() => onChange({[name]: !value})}
            style={{...style, cursor: 'pointer', fontWeight: 'bold'}}
            {...others}
        />
    }
    if([InputFieldTypes.BOX].includes(fieldType)){
        return <Box {...others}>
            {title && <Text size="md">{title}</Text>}
            {placeholder && <Text size="sm" c="gray">{placeholder}</Text>}
            <SegmentedControl
                value={value ? value : null}
                onChange={(vl) => onChange({[name]: vl})}
                data={groupBy(options ?? [], opt => opt.value).map(g => g[1][0])}
                fullWidth
                color="violet"
            />
        </Box>
    }
    if([InputFieldTypes.SELECT].includes(fieldType)){
        return multiple
        ? <MultiSelect    
            label={title}
            value={value ? value : []}
            placeholder={placeholder || str("SELECT")}
            searchable
            data={groupBy(options ?? [], opt => opt.value).map(g => g[1][0]).map(opt => ({label: `${opt.label}`, value: `${opt.value}`}))}
            clearable={clearable}
            size={size}
            variant={variant}
            onChange={(v) => onChange({[name]: v})}
            style={style}
            {...others}
        />
        : <Select
            value={value ? `${value}` : null}
            // label={value ? title : null}
            label={title}
            placeholder={placeholder ?? str("SELECT")}
            searchable
            size={size}
            variant={variant}
            data={groupBy(options ?? [], opt => opt.value).map(g => g[1][0]).map(opt => ({label: `${opt.label}`, value: `${opt.value}`}))}
            clearable={clearable}
            onChange={(v) => onChange({[name]: v})}
            style={style}
            {...others}
            autoFocus={false}
            // sx={{ ["& .mantine-Select-label"]: { fontWeight: "bold" } }}
        />
    }
    if([InputFieldTypes.DATE].includes(fieldType)){
        return <div>
            <DatePickerInput
                label={title}
                value={value ? value : null}
                placeholder={placeholder ?? str("SELECT")}
                closeOnChange
                valueFormat="DD/MM/YYYY"
                // dateParser={(dateString) => moment(dateString, "DD/MM/YYYY").toDate()}
                clearable={clearable}
                // allowFreeInput
                size={size}
                variant={variant}
                onChange={(v) => onChange({[name]: v})}
                {...others}
            />
        </div>
    }
    if([InputFieldTypes.DATETIME].includes(fieldType)){
        return <DateTimePicker
            label={title}
            value={value ? value : null}
            placeholder={placeholder ?? str("SELECT")}
            valueFormat="DD/MM/YYYY HH:mm"
            // dateParser={(dateString) => moment(dateString, "DD/MM/YYYY").toDate()}
            clearable={clearable}
            // allowFreeInput
            onChange={(v) => onChange({[name]: v})}
            size={size}
            variant={variant}
            style={style}
            {...others}
        />
    }
    if([InputFieldTypes.TIME].includes(fieldType)){
        return <TimeInput
            label={title}
            value={value ? value : null}
            placeholder={placeholder ?? str("SELECT")}
            onChange={(v) => onChange({[name]: v.target.value})}
            size={size}
            variant={variant}
            style={style}
            {...others}
        />
    }
    if([InputFieldTypes.DATERANGE].includes(fieldType)){
        return <div style={{position: 'relative'}}>
            <DatePickerInput
                allowSingleDateInRange
                label={title}
                closeOnChange
                value={(value && value.length > 0) ? value : [null, null]}
                placeholder={placeholder ?? str("SELECT")}
                clearable={clearable}
                variant={variant}
                size={size}
                valueFormat="DD/MM/YYYY"
                type="range"
                renderDay={(date) => (
                    <Indicator size={6} color="red" offset={8} disabled={moment().format("YYYY-MM-DD") !== moment(date).format("YYYY-MM-DD")}>
                        <div>{date.getDate()}</div>
                    </Indicator>
                )}
                onChange={(v: any) => {
                    // const qt = v.filter(v => v).length;
                    onChange({[name]: v})
                }}
                {...others}
            />
        </div>
    }
    if([InputFieldTypes.BOOLEAN].includes(fieldType)){
        return <Checkbox
            label={title}
            checked={value ? ["true", true].includes(value) : null}
            onChange={(v) => onChange({[name]: !!v.target.checked})}
        />
    }
    if([InputFieldTypes.IMAGE, InputFieldTypes.FILE, InputFieldTypes.EXCEL].includes(fieldType)){
        return <Box style={{position: 'relative'}} {...others}>
            {title && <Text fw="bold" size="md">{title}</Text>}
            {title && <Text fw="bold" size="md">{value}</Text>}
            {description && <Text style={{fontSize: 12, marginTop: 4}}>{description}</Text>}
            <Dropzone
                onDrop={(files) => {
                    onInsertFiles(files)
                }}
                accept={
                    others.accept ||
                    {
                        [InputFieldTypes.IMAGE]: IMAGE_MIME_TYPE,
                        [InputFieldTypes.EXCEL]: MS_EXCEL_MIME_TYPE,
                    }[fieldType] || [
                        MIME_TYPES.csv, MIME_TYPES.doc, MIME_TYPES.doc, MIME_TYPES.docx,
                        MIME_TYPES.exe, MIME_TYPES.gif, MIME_TYPES.jpeg, MIME_TYPES.mp4,
                        MIME_TYPES.pdf, MIME_TYPES.png, MIME_TYPES.ppt, MIME_TYPES.svg,
                        MIME_TYPES.xls, MIME_TYPES.xlsx, MIME_TYPES.zip, MIME_TYPES.webp,
                    ]
                }
                multiple={multiple}
                style={{ margin: 0, padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', ...style }}
                maxSize={2000000}
                loading={loading}
            >
                <Group
                    align="center"
                    style={{ pointerEvents: "none" }}
                >
                    <Dropzone.Accept>
                        <FaUpload size={35} />
                        {((!!value && (!multiple || value.length > 0)) || files.length > 0) && (
                        <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                            <FaCheckCircle size={35} />
                            <div style={{padding: '0 20px'}}>
                                <Text size="md" inline>
                                    File uploaded
                                </Text>
                                <Text size="sm" c="dimmed" inline mt={7}>
                                    {value?.name || title}
                                </Text>
                            </div>
                        </div>)}
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <FaTimesCircle size={35} />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        {(!!value && (!multiple || value.length > 0))
                            ? multiple
                                ? <Group>
                                    {value.map(v => <img style={{height: '200px', width: `160px`, objectFit: 'contain'}} src={v} />)}
                                </Group>
                                : <img style={{height: '200px', width: '100%', objectFit: 'contain'}} src={value} />
                            : <div style={{flexDirection: 'row', padding: 20, display: 'flex', alignItems: 'center'}}>
                                <FaFileImage size={35} />
                                <div style={{flex: 1, padding: '0 20px'}}>
                                    <Text size="md" inline>Drag or click here to select a file</Text>
                                    <Text size="xs" c="dimmed" inline mt={7}>The file can't exceed 2MB</Text>
                                </div>
                            </div>
                        }
                    </Dropzone.Idle>
                </Group>
            </Dropzone>
            {!!loadingUpload
            ? <ActionIcon
                size='xl'
                color="violet"
                variant="filled"
                style={{position: 'absolute', bottom: 5, right: 5}}
            ><Loader size="xs" color="white" /></ActionIcon>
            : !!value && (!multiple || value.length > 0) && <ActionIcon
                size='xl'
                color="violet"
                variant="filled"
                onClick={() => multiple ? value.forEach(v => window.open(v, "_blank")) : window.open(value, "_blank")}
                style={{position: 'absolute', bottom: 5, right: 5}}><FaEye /></ActionIcon>
            }
        </Box>
    }
    if(fieldType === InputFieldTypes.TEXTAREA){
        return <Box {...others} onPaste={(e) => {
            e.preventDefault();
            // const text = e.clipboardData.getData('text/plain');
            // document.execCommand('inserttext', false, text);
        }}>
            <Text size="md">{title}</Text>
            {description && <Text size="sm" mb="xs" c="gray">{description}</Text>}
            <MentionsInput
                value={value}
                placeholder={placeholder}
                style={{
                    control: {
                        minHeight: 30 + (20 * minRows),
                        maxHeight: 30 + (20 * maxRows),
                    },
                    input: {
                        padding: '10px 15px',
                        border: 0,
                        minHeight: 30 + (20 * minRows),
                        maxHeight: 30 + (20 * maxRows),
                        fontSize: 16,
                        borderRadius: 5,
                        overflowY: 'auto',
                        backgroundColor: '#242442',
                    },
                    suggestions: {
                        list: {},
                        item: {
                            padding: '5px 15px',
                            fontSize: 16,
                            backgroundColor: '#242442',
                            borderBottom: '1px solid #9a9a9a',
                            '&focused': { backgroundColor: '#24244288', },
                        }
                    },
                }}
                onChange={(e, nv, nvpt) => {
                    onChange && onChange({[name]: nv})
                }}
            >
                <Mention
                    trigger="{{"
                    markup="{{__display__}}"
                    data={options.map(opt => ({ id: opt.value, display: opt.label }))}
                    displayTransform={(id, display) => `{{${display}}}`}
                    style={{ backgroundColor: '#242442' }}
                />
                {/* <Mention
                    trigger="#"
                    data={this.requestTag}
                    renderSuggestion={this.renderTagSuggestion}
                /> */}
            </MentionsInput>            
        </Box>
        return <Textarea
            autosize
            minRows={minRows}
            maxRows={maxRows}
            label={title}
            value={![null, undefined].includes(value) ? value : ""}
            title={title}
            size={size}
            variant={variant}
            placeholder={placeholder ?? title}
            style={style}
            styles={styles}
            description={description}
            onChange={(e) => onChange && onChange({[name]: e.target.value})}
            {...others}
            // sx={{ ["& .mantine-Textarea-label"]: { fontWeight: "bold" } }}
        />
    }
    return <>
        {/* <TextInput style={{display: 'none'}}/> */}
        {
            mask === InputFieldMasks.MONEY
            ? <NumberFormat 
                value={value ? parseFloat(value) : ""} 
                displayType={'input'} 
                thousandSeparator=','
                decimalSeparator="."
                customInput={TextInput}
                decimalScale={2}
                fixedDecimalScale
                prefix={'$ '}
                onValueChange={(values) => {
                    const { value } = values;
                    onChange && onChange({ [name]: value });
                }}
                inputMode="decimal"
                label={title}
                placeholder={placeholder ?? title}
                style={style}
                styles={styles}
                variant={variant}
                size={size}
                description={description}
                {...others}
                // sx={{ ["& .mantine-TextInput-label"]: { fontWeight: "bold" } }}
                type={fieldType === InputFieldTypes.NUMBER ? "number" : others.type}
            />
            : mask === InputFieldMasks.CUSTOM
            ? <InputMask
                mask={customMask}
                value={props.value}
                maskPlaceholder={maskPlaceholder}
                maskChar={maskChar}
                onChange={(e) => onChange && onChange({[name]: e.target.value})}
                beforeMaskedValueChange={beforeMaskValueChange}
            >
                {(props) => 
                <TextInput
                    label={title}
                    value={value}
                    title={title}
                    placeholder={placeholder ?? title}
                    icon={icon}
                    style={style}
                    variant={variant}
                    size={size}
                    description={description}
                    {...others}
                    {...props}
                />}
            </InputMask>
            : fieldType === InputFieldTypes.PASSWORD
            ? <PasswordInput
                value={![null, undefined].includes(value) ? value : ""}
                title={title}
                placeholder={placeholder ?? title}
                // label={value ? title : null}
                label={title}
                style={style}
                variant={variant}
                size={size}
                description={description}
                onChange={(e) => onChange && onChange({[name]: e.target.value})}
                {...others}
            />
            : <TextInput
                value={![null, undefined].includes(value) ? value : ""}
                title={title}
                placeholder={placeholder ?? title}
                // label={value ? title : null}
                label={title}
                style={style}
                styles={styles}
                variant={variant}
                size={size}
                description={description}
                onChange={(e) => onChange && onChange({[name]: e.target.value})}
                {...others}
                color="red"
                type={fieldType === InputFieldTypes.NUMBER ? "number" : others.type}
                // sx={{ ["& .mantine-TextInput-label"]: { fontWeight: "bold" } }}
            />
        }
    </>
} 