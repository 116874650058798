import { ActionIcon, Avatar, Badge, Box, Button, Divider, Group, Image, Loader, LoadingOverlay, Menu, Modal, Paper, Text, Title, Tooltip, UnstyledButton } from '@mantine/core';
import { notifications, showNotification } from '@mantine/notifications';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { FaAlignJustify, FaArrowDown, FaCheck, FaEye, FaPaperclip, FaRegClock, FaRegComment, FaShare, FaShareAlt, FaTimes, FaTrash, FaTrashAlt } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MessageBox from "../components/messages_box";
import { GET_JOB_DETAILS, CHANGE_JOB_RESPONSIBLE, SEND_JOB_MESSAGE, GET_JOB_STATUS_OPTIONS, SAVE_JOB_STATUS, ACCEPT_JOB, DECLINE_JOB, CONFIRM_PAYMENT_JOB, UPDATE_PAYMENT_LINK_JOB, CHANGE_JOB_CUSTOMER, DELETE_JOB, ARCHIVE_JOB } from '../services/jobs';
import { useAuth } from '../contexts/auth.context';
import { PaymentForm } from '../components/payment_form';
import { GET_ALL_USERS } from '../services/users';
import { useClipboard, useMediaQuery } from '@mantine/hooks';
import { useApp } from '../contexts/app.context';
import { extenseRecurrenceType, getExtenseDatetime, useQuery } from '../utility/util';
import InputField, { InputFieldMasks, InputFieldTypes } from '../components/input_field';
import { APPLY_FORM } from '../services/forms';
import { ActionsRender } from '../components/actions_render';
import { GET_STRIPE_CONFIGS, REFUND_PAYMENT, SAVE_PAYMENT_METHOD } from '../services/payments';

export const JobKanban = ({ order }) => {
    return <Paper key={`${order.id}`} mb="xs" p="xs" shadow='xs' style={{border: '1px solid black', maxWidth: 320}}>
        <Group gap={5}>
            <Badge size="xs" color="gray" variant='filled' maw="100%">{order.service?.plan_name}</Badge>
            {order?.customer?.name && <Badge size="xs" color="gray" variant='light' maw="100%">{order?.customer?.name}</Badge>}
        </Group>
        <Box pt="xs" pb="xs">
            <Text size="md" c="black">{order.name}</Text>
        </Box>
        <Group style={{flex: 1}}>
            <Group gap={5}>
                <FaRegClock color="#9a9a9a" size="15px" />
                <Text size="12px" c="#9a9a9a">{moment(order.createdAt).format("MMM DD")}</Text>
            </Group>
            <FaAlignJustify color="#9a9a9a"  size="15px" />
            <Group gap={5}>
                <FaRegComment color={order.unread ? "#fab007" : "#9a9a9a"}  size="15px" />
                <Text c={order.unread ? "#fab007" : "#9a9a9a"} size="12px">{order.messages ?? order.unread ?? 0}</Text>
            </Group>
            <Box style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                {/* <Menu disabled={role?.profile?.title === "Client"}>
                    <Menu.Target>
                        <UnstyledButton> */}
                            <Group>
                                {(order.responsibles || []).length === 0 && <Avatar size="sm" style={{borderRadius: 40, border: '1px solid black'}} />}
                            </Group>
                            <Group>
                            {(order.responsibles || []).map((us, i) => (
                                <Tooltip label={us.name}>
                                    <Avatar size="sm" style={{borderRadius: 40, border: '1px solid black', marginLeft: i === 0 ? 0 : -32, zIndex: i+1}} c="violet" variant="filled" src={us.image} />
                                </Tooltip>
                            ))}
                            </Group>
                        {/* </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                        {users.map((us, i) => (
                            <Menu.Item
                                onClick={() => changeResponsible(order.id, us)}
                                rightSection={<FaCheck style={{marginLeft: 20}} size={20} color={(order.responsibles || []).some(r => r._id === us._id) ? "green" : "gray"} />}
                                leftSection={<Avatar size="sm" variant="filled" src={us.image} />}
                            >{us.name}</Menu.Item>
                        ))}
                    </Menu.Dropdown>
                </Menu> */}
            </Box>
        </Group>
        {/* <Group style={{justifyContent: 'space-between'}} p="xs">

            <Menu>
                <Menu.Target>
                    <ActionIcon color="violet"><FaEllipsisV /></ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item c="gray" icon={<FaEye />} onClick={() => navigate(`/orders/${order.id}`)}>Details</Menu.Item>
                    <Menu.Item c="yellow" icon={<FaArchive />} onClick={() => {
                        confirmDialog({text: `Realmente deseja ${order.archived ? "desarquivar" : "arquivar"} o job?`}, ({confirmed}) => {
                            if(confirmed) archiveJob(order.id, !order.archived);
                        })
                    }}>{order.archived ? "Desarquivar" : "Arquivar"}</Menu.Item>
                    <Menu.Item c="red" icon={<FaTrash />} onClick={() => {
                        confirmDialog({text: "Realmente deseja deletar o job?"}, ({confirmed}) => {
                            if(confirmed) deleteJob(order.id);
                        })
                    }}>Deletar</Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Group> */}
    </Paper>
}

export const JobPaper = ({ data, statusOptions, canDetails = true, onAccepted = null, onDeclined = null, ...others }) => {
    const { role, planData } = useAuth();
    const { startPlan } = useApp();

    const navigate = useNavigate();

    const [openAccepting, setOpenAccepting] = useState(false);
    const [acceptParams, setAcceptParams] = useState<any>({});
    const [customPrices, setCustomPrices] = useState<any>({});

    const activeStatus = statusOptions.find(s => s._id === data?.status?._id);
    const anyCustom = data?.service?.pricing_structure === "custom" || (data?.table ?? []).some(t => t.pricing_structure === "custom")

    const acceptJob = () => {
        if(!planData.active){
            startPlan();
        }else{
            ACCEPT_JOB(data?.id ?? data?._id, {...acceptParams, custom: customPrices})
            .then(res => {
                onAccepted && onAccepted();
                setOpenAccepting(false);
                notifications.show({message: "Order accepted", color: "green"});
            })
            .catch(err => {
                notifications.show({title: "Ops", message: err.message, color: 'red'})
            })
        }
    }
    
    const declineJob = () => {
        if(!planData.active){
            startPlan();
        }else{
            DECLINE_JOB(data?.id ?? data?._id)
            .then(res => {
                onDeclined && onDeclined();
                notifications.show({message: "Order declined", color: "green"});
            })
            .catch(err => {
                notifications.show({title: "Ops", message: err.message, color: 'red'})
            })
        }
    }

    return <Paper p="xs" mb="md" shadow='xs' {...others}>
        <Badge size="xs" color="gray" variant="filled" maw="100%">{data?.service?.plan_name}</Badge>
        <Group>
            <Text style={{flex: 1}} size="md" c="black" fw="bold" mt="xs">{data?.name}</Text>
            {canDetails && <ActionIcon
                color="gray"
                variant='outline'
                onClick={() => navigate(`/orders/${data?.id ?? data?._id}`)}
            ><FaEye /></ActionIcon>}
        </Group>
        {/* <Text size="xs" c="gray">{(data?.description ?? "").split("\n").map((d) => <div style={{minHeight: 14}}>{d}</div>)}</Text> */}

        <Box mb="md" mt="sm" p="md" style={{border: '1px solid black', borderRadius: 3}}>
            {data?.service?.plan_type === "subscription" && data?.service?.charge_setup && <><Group>
                <Text size="xs" fw="bold" style={{flex: 1}}>Setup Fee</Text>
                {
                    data?.setup_value
                    ? <Text size="xs" fw="bold">{data?.currency} {parseFloat(data?.setup_value || "0").toFixed(2)}</Text>
                    : <Text size="xs" fw="bold">Custom</Text>
                }
            </Group>
            <Divider mt="xs" mb="xs" /></>}
            <Group>
                <Text size="xs" fw="bold" style={{flex: 1}}>{data?.service?.plan_type === "one-time" ? 'One time value' : `${extenseRecurrenceType(data?.service?.recurrence_type)}`}</Text>
                {
                    data?.total_price
                    ? <Text size="xs" fw="bold">{data?.currency} {parseFloat(data?.total_price || "0").toFixed(2)}</Text>
                    : <Text size="xs" fw="bold">Custom</Text>
                }
            </Group>
            <Divider mt="xs" mb="xs" />
            {(data?.service?.plan_type === "one-time" || data?.service.subscription_period_type === "limited" )&& <Group>
                <Text size="xs" fw="bold" style={{flex: 1}}>Delivery Time</Text>
                {
                    data?.service?.plan_type === "one-time"
                    ? data?.delivery_period
                        ? <Text size="xs" fw="bold">{data?.delivery_period || "0"} days</Text>
                        : <Text size="xs" fw="bold">Custom</Text>
                    : <Text size="xs" fw="bold">{data?.service?.recurrence_cycles} {data?.service?.recurrence_type ?? "month"}s</Text>
                }
            </Group>}
        </Box>

        {
            (data?.declined || activeStatus)
            ? data?.declined
                ? <Button fullWidth variant='outline' className='draw-button' color="red">Request Declined</Button>
                : <Button fullWidth variant='outline' className='draw-button' color="green">Request Accepted</Button>
            : <>
                <Button fullWidth color="green" className='draw-button' variant='filled'
                    onClick={() => {
                        if(role?.profile?.title === "Client"){
                            showNotification({ message: "This updates needs agency action", color: 'yellow' })
                        }else{
                            if(anyCustom) setOpenAccepting(true);
                            else acceptJob();
                        }
                    }}>Accept Order</Button>
                <Button className='draw-button' fullWidth color="gray" variant='outline' mt="xs"
                    onClick={() => {
                        if(role?.profile?.title === "Client"){
                            showNotification({ message: "This updates needs agency action", color: 'yellow' })
                        }else{
                            declineJob();
                        }
                    }}>Decline</Button>
            </>
        }

        <Modal
            opened={openAccepting}
            onClose={() => setOpenAccepting(false)}
        >
            {data?.service?.charge_setup && <InputField
                mt="lg"
                title="One-time Set-up Fee"
                name="setup_value"
                mask={InputFieldMasks.MONEY}
                value={acceptParams.setup_value}
                onChange={({setup_value}) => setAcceptParams(fd => ({...fd, setup_value}))}
            />}
            {data?.service?.pricing_structure === "custom"
            ? <>
                <InputField
                    mt="lg"
                    title={`Price${data?.service?.plan_type === "subscription" ? ` (${extenseRecurrenceType(data?.service?.recurrence_type)})` : ""}`}
                    name="unity_price"
                    mask={InputFieldMasks.MONEY}
                    value={acceptParams.unity_price}
                    onChange={({unity_price}) => setAcceptParams(fd => ({...fd, unity_price}))}
                />
                {data?.service?.plan_type === "one-time" && <InputField
                    mt="lg"
                    title={`Delivery Period (in days)`}
                    name="delivery_period"
                    fieldType={InputFieldTypes.NUMBER}
                    value={acceptParams.delivery_period}
                    onChange={({delivery_period}) => setAcceptParams(fd => ({...fd, delivery_period}))}
                />}
            </>
            : <>
                {(data?.table ?? []).map((t, i) => t.pricing_structure === "custom" && <>
                    <Text size="lg" mt="lg">{`${t.quantity} ${t.unit} - ${t.title}`}</Text>
                    <Group>
                        <InputField
                            title={'Unity Price'}
                            name="price"
                            style={{flex: 1}}
                            customMask={InputFieldMasks.MONEY}
                            value={customPrices[`${i}`]?.price}
                            onChange={({price}) => setCustomPrices(fd => ({ ...fd, [`${i}`]: { ...{...fd}[`${i}`], price }}))}
                        />
                        {data?.service?.plan_type === "one-time" && <InputField
                            title={`Delivery period`}
                            name="period"
                            style={{flex: 1}}
                            fieldType={InputFieldTypes.NUMBER}
                            value={customPrices[`${i}`]?.period}
                            onChange={({period}) => setCustomPrices(fd => ({ ...fd, [`${i}`]: { ...{...fd}[`${i}`], period }}))}
                        />}
                    </Group>
                </>
                )}
            </>}

            <Group justify='flex-end' mt="lg">
                <Button size="md" onClick={acceptJob} color="green" className="draw-button">Accept Request</Button>
            </Group>
        </Modal>
    </Paper>
}

export default function JobPage({ orderId }){
    const [dataOrders, setDataOrders] = React.useState<{[key: string]: any}>({});
    const [formApplication, setFormApplication] = React.useState<any>(null);
    const [anchor, setAnchor] = React.useState<string>(null);
    const [paymentForm, setPaymentForm] = React.useState<any>({});
    const [statusOptions, setStatusOptions] = React.useState<any[]>([]);
    const [users, setUsers] = React.useState<any[]>([]);
    const [loadingData, setLoadingData] = React.useState<boolean>(false);
    const [loadingPayment, setLoadingPayment] = React.useState<boolean>(false);
    const [loadingRefund, setLoadingRefund] = React.useState<boolean>(false);
    const [updatingPaymentLink, setUpdatingPaymentLink] = useState("");
    const [customers, setCustomers] = useState<any[]>([]);
    const [refunding, setRefunding] = useState(null);

    const query = useQuery();
    const { orderId : paramsOrderId } = useParams();
    const queryOrderId = query.get("order");
    orderId = orderId ?? paramsOrderId ?? queryOrderId;
    
    const data = dataOrders[orderId];
    const { confirmDialog } = useApp();

    const clipboard = useClipboard();
    const { role, planData, userData } = useAuth();
    const { hash } = useLocation();
    const location = useLocation()

    useEffect(() => {
        if (hash) { setAnchor(hash.split("#")[1]); }
        else{ setAnchor(null) }
    }, [hash]);

    const handleClose = () => {
        if(paramsOrderId) navigate("/orders");
        if(queryOrderId){
            query.delete("order");
            navigate(`${location.pathname}${query.toString() ? `?${query.toString()}` : ''}`);
        }
    }

    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 900px)');

    const activeStatus = statusOptions.find(s => s._id === data?.status?._id);

    const handleChangePaymentForm = (dt) => setPaymentForm(pf => ({ ...pf, ...dt }))
    
    const loadData = (setLoading = true) => {
        setLoadingData(setLoading)
        GET_JOB_DETAILS(orderId)
        .then(res => {
            setDataOrders(pv => ({ ...pv, [orderId]: res }));
            setLoadingData(false)
        })
        .catch(err => {
            setLoadingData(false)
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const loadUsers = () => {
        GET_ALL_USERS()
        .then(res => {
            setUsers(res.filter(r => r.profile.title !== "Client"));
            setCustomers(res.filter(r => r.profile.title === "Client"));
        })
        .catch(err => {
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const loadStatusOptions = () => {
        GET_JOB_STATUS_OPTIONS()
        .then(res => {
            setStatusOptions(res);
        })
        .catch(err => {
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const handleChangeStatus = (status: string) => {
        SAVE_JOB_STATUS(orderId, status)
        .then(res => {
            loadData();
            notifications.show({message: "Status changed", color: "green"});
        })
        .catch(err => {
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const handleApplyForm = () => {
        APPLY_FORM(formApplication.form._id, formApplication.data)
        .then(res => {
            loadData();
            setFormApplication(null);
            notifications.show({message: "Form applied", color: "green"});
        })
        .catch(err => {
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const handleChangeCustomer = (customerId: string) => {
        CHANGE_JOB_CUSTOMER(orderId, customerId)
        .then(res => {
            loadData();
            notifications.show({message: "Customer Changed", color: "green"});
        })
        .catch(err => {
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const changeResponsible = (user) => {
        const active = !(data?.responsibles || []).some(r => r._id === user._id);
        let previous = data;
        setDataOrders(pv => ({
            ...pv,
            [orderId]: {
                ...pv[orderId],
                responsibles: active
                    ? [...pv[orderId].responsibles, user]
                    : (pv[orderId].responsibles || []).filter(r => r._id !== user._id)
            }
        }));
        CHANGE_JOB_RESPONSIBLE(orderId, user._id, active)
        .then(() => {})
        .catch(err => {
            setDataOrders(pv => ({
                ...pv,
                [orderId]: previous
            }));
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const archiveJob = (archive = true) => {
        ARCHIVE_JOB(orderId, archive)
        .then(res => {
            setLoadingData(false)
            navigate("/orders")
            notifications.show({message: "Order Archived", color: "green"});
        })
        .catch(err => {
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const deleteJob = () => {
        setLoadingData(true);

        DELETE_JOB(orderId)
        .then(res => {
            setLoadingData(false)
            navigate("/orders")
            notifications.show({message: "Order Deleted!", color: "green"});
        })
        .catch(err => {
            setLoadingData(false)
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const confirmPayment = async () => {
        setLoadingPayment(true);
        if(paymentForm.method === "new-card"){
            try{
                const { company } = await GET_STRIPE_CONFIGS("company")
                const stripe = require('stripe')(company);
                const pm = await stripe.paymentMethods.create({
                    type: 'card',
                    card: paymentForm?.card,
                })
                const method = await SAVE_PAYMENT_METHOD("company", pm, { email: data?.customer?.email, name: data?.customer?.name })
                handlePay(method._id, { push: true });
            }catch(err){
                setLoadingPayment(false);
                notifications.show({ message: err?.message ?? "Stripe not configured" });
            }
        }else{
            handlePay(paymentForm.method);
        }
    }

    const handlePay = (method, others = {}) => {
        setLoadingPayment(true);
        
        CONFIRM_PAYMENT_JOB(orderId, { method, ...others })
        .then((res) => {
            setLoadingPayment(false);
            loadData();
            setPaymentForm({});
            navigate(`/orders/${orderId}`)
            if(res.payed){
                notifications.show({message: "Payment confirmed!", color: "green"});
            }else{
                if(res.return_url) window.open(res.return_url, "_blank");
                else notifications.show({ message: "You need to finish the payment process in your bank. Check for notifications and approve the transaction to finish", color: "yellow" })
            }
        })
        .catch(err => {
            setLoadingPayment(false);
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const handleSendMessage = ({ text, files }) => {
        SEND_JOB_MESSAGE(orderId, { text, files })
        .then(res => {
            loadData();
        })
        .catch(err => {
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const handleRefund = () => {
        setLoadingRefund(true);
        REFUND_PAYMENT(refunding.payment._id, refunding.value)
        .then(res => {
            setLoadingRefund(false)
            setRefunding(null);
            loadData();
        })
        .catch(err => {
            notifications.show({title: "Ops", message: err.message, color: 'red'})
            setLoadingRefund(false)
        })
    }

    const { UploadedFiles, PrincipalInput, BoxMessages} = MessageBox({
        onSubmit: handleSendMessage,
        minRows: 4,
        messages: [
            ...(data?.messages || []),
            ...(data?.job_status ?? []).map(js => ({
                createdAt: js.createdAt,
                render: () => <Paper mt="md" mb="md" style={{padding: 5, border: 0, background: '#F8F8F8'}}>
                    {/* {JSON.stringify(js)} */}
                    <Text size="xs" c="violet" ta="center">Moved to "{js?.status?.title}"</Text>
                    <Text size="xs" c="gray" ta="center">{getExtenseDatetime(js.createdAt)}</Text>
                </Paper>,
            })),
            ...(data?.payments ?? []).map(p => ({
                createdAt: p.payed_at ?? p.createdAt,
                render: () => <Paper shadow='xs' p="xs" mb="md" mt="md">
                    <Group mb="xs">
                        <Box style={{flex: 1}}>
                            <Text fw="bold">Stripe Payment Charge</Text>
                        </Box>
                        <Box>
                            <Text c="violet">{p.currency ?? "USD"} {p.value}</Text>
                            {!p.payed_at && <Text fw="bold" size="xs" c="red">Payment Failed</Text>}
                            {p.refund_at && <Text fw="bold" size="xs" c="red">Refund {p.refund_value}</Text>}
                        </Box>
                        <ActionsRender
                            actions={[
                                !p.refund_at && role?.profile?.title !== "Client" && { title: "Refund", color: 'red', onClick: () => { setRefunding({ payment: p, value: p.value }) } }
                            ].filter(nn => nn)}
                        />
                    </Group>
                    <Text ta="right" c="gray" size="xs">{getExtenseDatetime(p.createdAt ?? p.payed_at)}</Text>
                </Paper>
            })),
            ...(data?.service?.forms || []).reduce((pv, form, fi) => pv.concat([
                // {
                //     createdAt: moment(data?.createdAt).subtract((fi+1) * 1, "minutes"),
                //     render: () => <div style={{display: 'flex', flexDirection: 'column', alignItems: role?.profile?.title === "Client" ? 'flex-start' : 'flex-end'}}>
                //         <Paper m="md" p="xs" shadow='xs' mb="md" style={{width: '70%'}}>
                //             <Group align="flex-start">
                //                 <Box style={{flex: 1}}>
                //                     <Text size="lg" c="black" fw="bold">{form.title}</Text>
                //                     <Text size="sm" mt="xs" c="gray" fw="bold">{form.description}</Text>
                //                 </Box>
                //             </Group>
                //             <Button
                //                 onClick={() => setFormApplication({
                //                     form,
                //                     data: {
                //                         job: data?._id,
                //                         answers: (form?.questions ?? []).map(question => ({question})),
                //                     }
                //                 })}
                //                 className='draw-button'
                //                 mt="md"
                //                 fullWidth
                //                 color="violet"
                //                 variant='filled'
                //                 disabled={(data?.forms_applications ?? []).some(fa => fa.form === form._id)}
                //             >Submit Requirements</Button>
                //         </Paper>
                //     </div>
                // },
                (data?.forms_applications ?? []).some(fa => fa.form === form._id) && {
                    createdAt: moment((data?.forms_applications ?? []).find(fa => fa.form === form._id).createdAt),
                    render: () => {
                        const reply = (data?.forms_applications ?? []).find(fa => fa.form === form._id)
                        return <div style={{display: 'flex', flexDirection: 'column', whiteSpace: 'pre-wrap', wordBreak: 'break-word', alignItems: role?.profile?.title === "Client" ? 'flex-end' : 'flex-start'}}>
                            <Paper m="md" p="xs" shadow='xs' mb="md" style={{width: '70%'}}>
                                <Text size="md" c="black" fw="bold">Form Application: {form?.title}</Text>
                                {
                                    reply.answers.map(answer => <Box mb="xs">
                                        <Text size="md" c="black">{answer?.question?.title}</Text>
                                        {["image", "file"].includes(answer?.question?.type)
                                        ? (answer.values ?? []).map(vl => (
                                            answer?.question?.type === "file"
                                            ? <div onClick={() => window.open(vl, "_blank")} style={{padding: 20, borderRadius: 10, cursor: 'pointer', background: '#FAFAFA', textAlign: 'center'}}><FaPaperclip /> {"Attachment"}</div>
                                            : <Image onClick={() => window.open(vl, "_blank")} src={vl} fit='contain' style={{height: 80, maxWidth: '100%', cursor: 'pointer'}} />
                                        ))
                                        : <Text size="sm" c="gray" fw="bold">
                                            {
                                                (answer.values ?? []).length === 0
                                                ? "N/A"
                                                : (answer.values ?? []).join(", ")
                                            }
                                        </Text>}
                                    </Box>)
                                }
                                <Text ta="right">{getExtenseDatetime(reply.createdAt)}</Text>
                            </Paper>
                        </div>
                    }
                },
            ].filter(nn => nn)), []),
            ...(
                (data?.service?.forms || []).some(f => !(data?.forms_applications ?? []).some(fa => fa.form === f._id))
                ? []
                : [
                    {
                        createdAt: moment(data?.createdAt),
                        render: () => <div style={{display: 'flex', flexDirection: 'column', alignItems: role?.profile?.title === "Client" ? 'flex-end' : 'flex-start'}}>
                            <JobPaper
                                data={data}
                                statusOptions={statusOptions}
                                onAccepted={loadData}
                                onDeclined={() => navigate("/orders")}
                                canDetails={false}
                                m="md"
                                mb="md"
                                style={{width: '70%'}}
                            />
                        </div>
                    },
                    activeStatus && (userData.company.paymentConfigured || data?.payment_link || role?.profile?.title !== "Client") && {
                        createdAt: moment(data?.job_status[0]?.createdAt),
                        render: () => <div style={{display: 'flex', flexDirection: 'column', alignItems: role?.profile?.title === "Client" ? 'flex-start' : 'flex-end'}}>
                            <Paper m="md" p="xs" shadow='xs' mb="md" style={{width: '70%'}}>
                                <Group align="flex-start">
                                    <Box style={{flex: 1}}>
                                        <Text size="lg" c="black" fw="bold">Order Accepted!</Text>
                                        <Text size="sm" c="gray" fw="bold">Get your order started.</Text>
                                    </Box>
                                    {false && role?.profile?.title !== "Client" && <Box>
                                        <Button color="gray" variant='outline' size="xs">Mark as Payed</Button>
                                    </Box>}
                                </Group>
                                {
                                    (data?.payments || []).some(p => p.payed_at)
                                    ? <Button className='draw-button' mt="md" fullWidth color="green" variant='filled'>Order Payed</Button>
                                    : (data?.customer && (userData.company.paymentConfigured || data?.payment_link))
                                    ? (
                                        role?.profile?.title === "Client"
                                        ? <Button
                                            className='draw-button' mt="md" fullWidth color="green" variant='filled'
                                            onClick={() => {
                                                if(data?.payment_link){
                                                    window.open(data?.payment_link);
                                                }else{
                                                    if(role?.profile?.title === "Client") navigate(`/orders/${orderId}#payment`);
                                                }
                                            }}>Confirm & Pay</Button>
                                        : <>
                                            {userData.company.paymentConfigured && !data?.payment_link &&
                                                <Button
                                                    className='draw-button' mt="md" fullWidth color="green" variant='filled'
                                                    leftSection={<FaCheck />}
                                                    onClick={() => {
                                                        notifications.show({ message: "Your stripe account is configured. If you want to use a custom payment link click in the button bellow." })
                                                    }}>Stripe Configured</Button>
                                            }
                                            <Button
                                                className='draw-button' mt="md" fullWidth color="yellow" variant='filled'
                                                onClick={() => {
                                                    setUpdatingPaymentLink(data?.payment_link)
                                                    navigate(`/orders/${orderId}#paymentlink`);
                                                }}>{data?.payment_link ? 'Update' : 'Send'} Payment Link</Button>
                                        </>
                                    ) : <Menu>
                                        <Menu.Target>
                                            <Button className='draw-button' mt="md" fullWidth color="red" variant='filled'>Payment not configured</Button>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Item
                                                onClick={() => { !["Client"].includes(role?.profile?.title) && navigate(`/settings#integrations`)}}
                                            >Configure Stripe
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={() => {
                                                    navigate(`/orders/${orderId}#paymentlink`)
                                                }}
                                            >Send Payment Link
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                }
                            </Paper>
                        </div>
                    },
                ]
            ),
        ].filter(nn => nn),
    });

    useEffect(() => {
        if(orderId){
            setDataOrders(pv => {
                loadData(!pv[orderId]);
                return pv;
            })
            loadUsers();
            loadStatusOptions();
        }
    }, [orderId]);
    
    if(!orderId) return; 
    return <div>
        <LoadingOverlay visible={loadingData} />

        <Box style={{padding: 0, border: 0, position: 'relative'}}>
            <div style={{height: 'calc(100vh)', paddingBottom: isMobile ? 0 : 140, overflowY: 'auto'}}>
                <Box>
                    <Group p="md">
                        {role?.profile?.title !== "Client" && activeStatus && <Menu>
                            <Menu.Target>
                                <Button color={{
                                    "in-progress": "yellow",
                                    "done": "green",
                                }[activeStatus?.category] || "gray"} rightSection={<FaArrowDown />}>{activeStatus?.title || "Open"}</Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                {statusOptions.filter(so => so._id !== activeStatus?._id).map(so => (
                                    <Menu.Item onClick={() => handleChangeStatus(so._id)}>
                                        {so.title}
                                    </Menu.Item>
                                ))}
                            </Menu.Dropdown>
                        </Menu>}
                        <div style={{flex: 1}}>
                        </div>
                        <Tooltip label="Delete Order">
                            <ActionIcon color="gray" variant='transparent' size="lg" onClick={() => {
                                confirmDialog({
                                    text: "Are you sure that you want to delete this order? All of it's data will be lost once you confirm.",
                                }, ({ confirmed }) => {
                                    if(confirmed) deleteJob();
                                })        
                            }}><FaTrash /></ActionIcon>
                        </Tooltip>
                        <ActionIcon color="gray" variant='outline' size="lg" onClick={handleClose}><FaTimes /></ActionIcon>
                    </Group>

                    <Divider />

                    <Box p="md">
                        <Title order={3}>{data?.name}</Title>

                        {(data?.service?.forms ?? []).map(f => <Group mt="md">
                            <Box style={{flex: 0.3}}>
                                <Text size="sm" c="gray" fw="bold">{f.title}</Text>
                            </Box>
                            <Box style={{flex: 0.7}}>
                                <Button
                                    fullWidth
                                    onClick={() => setFormApplication({
                                        form: f,
                                        data: {
                                            job: data?._id,
                                            answers: (f?.questions ?? []).map(question => ({question})),
                                        }
                                    })}
                                    disabled={(data?.forms_applications ?? []).some(fa => fa.form === f._id)}>Fill in Form</Button>
                            </Box>
                        </Group>)}

                        {data?.service?.plan_type === "one-time" && <Group mt="md">
                            <Box style={{flex: 0.3}}>
                                <Text size="sm" c="gray" fw="bold">Deadline</Text>
                            </Box>
                            <Box style={{flex: 0.7}}>
                                <InputField
                                    name="dt"
                                    disabled
                                    fieldType={InputFieldTypes.DATE}
                                    value={
                                        data?.deadline
                                        ? moment(data?.deadline).toDate()
                                        : moment().add(data?.delivery_period, "days").toDate()
                                    }
                                />
                            </Box>
                        </Group>}

                        {role?.profile?.title !== "Client" && !data?.customer && <Group mt="md">
                            <Box style={{flex: 0.3}}>
                                <Text size="sm" c="gray" fw="bold">Magic Link</Text>
                            </Box>
                            <Box style={{flex: 0.7}}>
                                <Tooltip label="Share this order with your clients using a magic link to onboard them">
                                    <Button
                                        variant='outline'
                                        color="violet"
                                        onClick={() => {
                                            clipboard.copy(`https://${userData?.company?.defaultDomain}/o-${data?._id}`);
                                            notifications.show({ message: "Copied to clipboard" })
                                        }}
                                        leftSection={<FaShare />}
                                    >Copy To Share</Button>
                                </Tooltip>
                            </Box>
                        </Group>}

                        {role?.profile?.title !== "Client" && <Group mt="md">
                            <Box style={{flex: 0.3}}>
                                <Text size="sm" c="gray" fw="bold">Client</Text>
                            </Box>
                            <Box style={{flex: 0.7}}>
                                <Menu>
                                    <Menu.Target>
                                        <Button disabled={!!data?.customer} variant='outline' color="violet"
                                        leftSection={<FaArrowDown />}>{data?.customer?.name ?? "Assign Customer"}</Button>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        {customers.map(customer => (
                                            <Menu.Item onClick={() => handleChangeCustomer(customer._id)}>
                                                {customer.name}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Dropdown>
                                </Menu>
                            </Box>
                        </Group>}

                        <Group mt="md">
                            <Box style={{flex: 0.3}}>
                                <Text size="sm" c="gray" fw="bold">Assignee</Text>
                            </Box>
                            <Box style={{flex: 0.7}}>
                                <Menu disabled={role?.profile?.title === "Client"}>
                                    <Menu.Target>
                                        <UnstyledButton>
                                            <Group>
                                                {(data?.responsibles || []).length === 0 && <Avatar size="md" style={{borderRadius: 40, border: '1px solid black'}} />}
                                            </Group>
                                            <Group>
                                                {(data?.responsibles || []).map((us, i) => (
                                                    <Group gap={10}>
                                                        <Avatar size="md" style={{borderRadius: 40, border: '1px solid black'}} c="violet" variant="filled" src={us.image} />
                                                        <Text size="md">{us.name}</Text>
                                                    </Group>
                                                ))}
                                            </Group>
                                        </UnstyledButton>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        {users.map((us, i) => (
                                            <Menu.Item
                                                onClick={() => changeResponsible(us)}
                                                variant="light"
                                                c={(data?.responsibles || []).some(r => r._id === us._id) ? "white" : "black"}
                                                style={{
                                                    background: (data?.responsibles || []).some(r => r._id === us._id) ? "#5dc86d" : "#F8F8F8"
                                                }}
                                                leftSection={<Avatar size="md" variant="filled" src={us.image} />}
                                            >{us.name}</Menu.Item>
                                        ))}
                                    </Menu.Dropdown>
                                </Menu>
                            </Box>
                        </Group>

                        {(data?.quantity ?? 1) > 1 && <Group mt="md">
                            <Box style={{flex: 0.3}}>
                                <Text size="sm" c="gray" fw="bold">Quantity</Text>
                            </Box>
                            <Box style={{flex: 0.7}}>
                                <Text size="md">{data?.quantity ?? 1}</Text>
                            </Box>
                        </Group>}

                        <Box mt="md">
                            <Text size="sm" c="gray" fw="bold">Description</Text>
                            <Text size="sm" c="black" mt="xs">{(data?.description ?? "").split("\n").map((d) => <div style={{minHeight: 14}}>{d}</div>)}</Text>
                        </Box>
                        {data?.service?.pricing_table && <Box mt="md">
                            <Text size="sm" c="gray" fw="bold">Items</Text>
                            {
                                (data?.table || []).map(t => (
                                    <Group mt="xs" pb="xs" style={{borderBottom: '1px solid #DFDFDF'}}>
                                        <Box style={{flex: 0.05, border: '1px solid #DFDFDF', padding: '5px 20px', textAlign: 'center', color: '#8a8a8a', borderRadius: 5}} size="xs"><Text>{t.quantity ?? 1}</Text></Box>
                                        <Box style={{flex: 0.3}}>
                                            <Text size="sm">{t.title}</Text>
                                        </Box>
                                        <Box style={{flex: 0.65}}>
                                            {(data?.service?.plan_type === "one-time" && t.delivery_period && parseInt(t.delivery_period))
                                                ? <Text ta="right" size="xs" c="gray">Delivery Period<br/>{t.delivery_period} days</Text>
                                                : null}
                                        </Box>
                                    </Group>
                                ))
                            }
                        </Box>}
                    </Box>
                </Box>

                {/* <Box mt="md">
                    <Title c="gray" order={4}>Order Billing</Title>
                    <Group mt="md">
                        {
                            data?.service.plan_type !== "one-time" && data?.service?.offer_trial &&
                            <Paper style={{minWidth: 220 , border: 0}} shadow='xs' p="sm" mr="xs">
                                <Text size="sm" ta="center" c="gray">{`${data?.service.trial_period} Day Trial`}</Text>
                                <Text size="xs" ta="center" c="gray">{data?.service?.currency?.toUpperCase()} {data?.service?.unity_price?.toFixed(2)}</Text>
                            </Paper>
                        }
                        {
                            data?.service.plan_type !== "one-time" && data?.next_payment &&
                            <Paper style={{minWidth: 220 , border: 0}} shadow='xs' p="sm" mr="xs">
                                <Text size="sm" ta="center" c="gray">Next Payment</Text>
                                <Text size="sm" ta="center" c="gray">{getExtenseDatetime(data?.next_payment, false)}</Text>
                                <Text size="xs" ta="center" c="gray">{data?.service?.currency?.toUpperCase()} {data?.service?.unity_price?.toFixed(2)}</Text>
                            </Paper>
                        }
                    </Group>
                    <Group justify='flex-end'>
                        <Button onClick={() => navigate(`/orders/${orderId}#payments`)} size="xs" color="gray" variant='light'>View All</Button>
                    </Group>
                </Box> */}
                <Divider mt="xl" color="#E0E0E0" />
                <Box p="md" style={{ background: '#F8F8F8' }}>
                    <Text size="sm" c="gray" fw="bold">Conversation</Text>
                </Box>
                <Box p="md" style={{}}>
                    {BoxMessages}
                    {/* {FullComponent} */}
                </Box>
                {isMobile && <div style={{background: 'white', borderTop: '1px solid #DFDFDF'}}>
                    {PrincipalInput}
                </div>}
            </div>

            {!isMobile && <div style={{position: 'absolute', background: 'white', borderTop: '1px solid #DFDFDF', left: 0, right: 0, bottom: 0}}>
                {PrincipalInput}
            </div>}
            {UploadedFiles}
        </Box>

        <Modal
            opened={anchor === "payments"}
            onClose={() => {navigate(`/orders/${orderId}`)}}
            title="Order Payments"
        >
            {
                (data?.payments ?? []).length === 0
                ? <Title c="gray" order={4} ta="center">No payment processed yet.</Title>
                : <Box>
                    {(data?.payments ?? []).sort((a,b) => a.createdAt > b.createdAt ? -1 : 1).map(p => <Paper style={{ border: 0}} shadow='xs' p="sm" mb="xs">
                        <Text size="md" ta="center" c="gray">{p.payed_at ? getExtenseDatetime(p.payed_at, false) : "Pendent Payment"}</Text>
                        <Text size="sm" ta="center" c="gray">{p.data.currency.toUpperCase()} {(p.data.amount/100).toFixed(2)}</Text>
                    </Paper>)}
                </Box>
            }
        </Modal>
        
        <Modal
            opened={!!refunding}
            onClose={() => { setRefunding(null) }}
            title="Refund Payment"
        >
            {
                refunding && <>
                    <InputField
                        name="value"
                        value={refunding.value}
                        mask={InputFieldMasks.MONEY}
                        onChange={({value}) => setRefunding(r => ({ ...r, value }))}
                    />
                
                </>
            }

            <Group justify='flex-end' mt="xl">
                <Button
                    onClick={() => setRefunding(null)}
                    color="gray"
                    variant='subtle'
                    disabled={loadingRefund}
                >Cancel</Button>
                <Button
                    disabled={!refunding?.value}
                    onClick={handleRefund}
                    color="red"
                    loading={loadingRefund}
                    variant='filled'
                >Refund</Button>
            </Group>
        </Modal>
        
        <Modal
            opened={!!formApplication}
            onClose={() => { setFormApplication(null) }}
            title={<Title c="violet" order={3}>{formApplication?.form?.title}</Title>}
            size="lg"
        >
            {
                (formApplication?.data?.answers ?? []).map((answer, ai) =>
                <Paper style={{ border: '1px solid #DFDFDF'}} p="sm" mb="xs">
                    <Text size="md" fw="bold" c="black">{answer?.question?.title}{answer?.question?.required ? " *" : ""}</Text>
                    
                    {
                        <InputField
                            name="k"
                            mt="xs"
                            value={
                                answer?.question?.type === "multiple-choice"
                                ? (answer.values ?? [])
                                : (answer.values ?? [])[0]}
                            multiple={answer?.question?.type === "multiple-choice"}
                            fieldType={
                                (
                                    (answer?.question?.options ?? []).length <= 5 &&
                                    ["evaluation", "yes-no", "single-choice"].includes(answer?.question?.type)
                                )
                                ? InputFieldTypes.BOX
                                : ["multiple-choice", "evaluation", "yes-no", "single-choice"].includes(answer?.question?.type)
                                ? InputFieldTypes.SELECT
                                : ["image"].includes(answer?.question?.type)
                                ? InputFieldTypes.IMAGE
                                : ["file"].includes(answer?.question?.type)
                                ? InputFieldTypes.FILE
                                : answer?.question?.type === "text"
                                ? InputFieldTypes.TEXTAREA
                                : InputFieldTypes.STRING
                            }
                            options={
                                answer?.question?.type === "yes-no"
                                ? [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]
                                : answer?.question?.type === "evaluation"
                                ? [1,2,3,4,5].map(opt => ({ value: `${opt}`, label: opt }))
                                : (answer?.question?.options ?? []).map(opt => ({
                                    label: opt.value, value: opt.value
                                }))
                            }
                            onChange={({k}) => setFormApplication(fd => ({
                                ...fd,
                                data: { ...fd.data, answers: fd.data.answers.map((a, aj) => (
                                    ai === aj ? { ...a, values: answer?.question?.type === "multiple-choice" ? k : [k] } : a
                                )) }
                            }))}
                        />
                    }
                </Paper>)
            }

            <Group justify='flex-end' mt="xl">
                <Tooltip label={
                        (formApplication?.data?.answers ?? []).some(a => a.question.required && !(a.values ?? [])[0])
                        ? "Answer all questions to submit reply" : "Submit"}>
                    <Button
                        disabled={(formApplication?.data?.answers ?? []).some(a => a.question.required && !(a.values ?? [])[0])}
                        size="md" color="violet" onClick={handleApplyForm}>Submit</Button>
                </Tooltip>
            </Group>
        </Modal>
        
        <Modal
            opened={anchor === "paymentlink" && role?.profile?.title !== "Client"}
            onClose={() => {navigate(`/orders/${orderId}`)}}
        >
            <InputField
                name="l"
                title="Payment Link"
                value={updatingPaymentLink}
                onChange={({l}) => setUpdatingPaymentLink(l)}
            />

            <Button 
                onClick={() => {
                    UPDATE_PAYMENT_LINK_JOB(orderId, updatingPaymentLink)
                    .then(() => {
                        notifications.show({ message: "Payment Link Updated", color: "green" });
                        loadData();
                        navigate(`/orders/${orderId}`);
                    })
                    .catch(err => {
                        notifications.show({ message: err.message, color: "red" });
                    })
                }}
                color="green" fullWidth mt="md" className="draw-button">Update Payment Link</Button>
            {data?.payment_link && <Button 
                onClick={() => {
                    UPDATE_PAYMENT_LINK_JOB(orderId, "")
                    .then(() => {
                        notifications.show({ message: "Payment Link Removed", color: "green" });
                        loadData();
                        navigate(`/orders/${orderId}`);
                    })
                    .catch(err => {
                        notifications.show({ message: err.message, color: "red" });
                    })
                }}
                color="red" fullWidth size="xs" mt="md" variant="transparent">Remove Payment Link</Button>}
        </Modal>
        
        <Modal
            opened={anchor === "payment"}
            onClose={() => navigate(`/orders/${orderId}`)}
            title="Order Payment"
        >
            {
                (data?.payments || []).length > 0
                ? <Title c="green" order={4} ta="center">Order payed successfully!</Title>
                : <Box>
                    <PaymentForm
                        data={paymentForm}
                        methods={userData?.user?.paymentMethods || []}
                        onChange={handleChangePaymentForm}
                    />

                    <Group justify="flex-end">
                        <Button color="green" variant='filled' loading={loadingPayment} onClick={confirmPayment}>Confirm Payment</Button>
                    </Group>
                </Box>
            }
        </Modal>
    </div>
}
