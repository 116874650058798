import {
  ActionIcon,
    Box,
    Button,
    Divider,
    Grid,
    Group,
    Menu,
    Paper,
    Text,
    UnstyledButton
} from "@mantine/core";
import React from "react";
import { useLanguage } from "../../contexts/language.context";
import InputField, { InputFieldTypes } from "../input_field";
import { FaPlus, FaTrash } from "react-icons/fa";
import { timezones } from "../../utility/util";

export default function AddActions({ onSelect, actionOptions = [], whatsapps = [], calendars = [] }: any){
    const { str } = useLanguage();

    return <Box p="md">
    <Text fw="bold" size="lg">{str("AGENTS.ADD_ACTION_TITLE")}</Text>
    <Text fw="lighter" c="gray" mb="md" size="sm">{str("AGENTS.ADD_ACTION_SUBTITLE")}</Text>
    <Grid>
      {
        actionOptions.map((ao) => (
          <Grid.Col span={{base: 12, md: 4}}>
            <UnstyledButton
              style={{width: '100%'}}
              onClick={() => { onSelect && onSelect({
                type: ao.type,
                description: ao.description,
                timezone: ao.type === "book_appointment" ? Intl.DateTimeFormat().resolvedOptions().timeZone : undefined,
              }) }}
            >
              <Paper p="md" style={{backgroundColor: '#242442'}}>
                <Text fw="bold" mb="xs">{ao.title}</Text>
                <Text c="gray" size="sm">{ao.subtitle}</Text>
              </Paper>
            </UnstyledButton>
          </Grid.Col>
        ))
      }
    </Grid>
  </Box>
}