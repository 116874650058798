import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { GET_ALL_WHATSAPPS, SAVE_WHATSAPP } from "../../services/phones";
import InputField, { InputFieldTypes } from "../input_field";
import { ActionIcon, Avatar, Box, Button, Grid, Group, Loader, Modal, Paper, Text, Title, UnstyledButton } from "@mantine/core";
import { FaCartPlus, FaPhone, FaPlus, FaWhatsapp } from "react-icons/fa";
import { countriesList } from "../../utility/util";
import { useApp } from "../../contexts/app.context";
import { useAuth } from "../../contexts/auth.context";
import QRCode from "react-qr-code";
import { useLanguage } from "../../contexts/language.context";

export default function Whatsapps(){
    const [data, setData] = useState<any[]>([]);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [newPhone, setNewPhone] = useState<any>(null);
    const [availableWhatsapps, setAvailableWhatsapps] = useState<any[]>([]);
    
    const { socket } = useApp();
    const { userData } = useAuth();
    const { str } = useLanguage();

    const handleChange = dt => setData(u => ({ ...u, ...dt }));
    
    const loadWhatsapps = () => {
        setLoading(true);
        GET_ALL_WHATSAPPS()
        .then((res) => {
            setLoading(false);
            setData(res)
            if(res.some(r => r.instance?.status?.isQr)){
                setTimeout(loadWhatsapps, 10000);
            }
        })
        .catch(err => {
            setLoading(false);
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const handleSave = () => {
        setLoadingSave(true)
        SAVE_WHATSAPP({ })
        .then((res) => {
            setLoadingSave(false)
            loadWhatsapps()
            setNewPhone(null);
            notifications.show({message: str("WHATSAPPS.REGISTERED"), color: 'green'})
        })
        .catch(err => {
            setLoadingSave(false)
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => {
        const func = ({ configured, data, whatsapp_id }) => {
            loadWhatsapps();
        }
        socket.on(`whatsapp_updated_${userData?.company?._id}`, func);
        return () => {
            socket.removeAllListeners(`whatsapp_updated_${userData?.company?._id}`);
        };
    }, [socket, userData])
    React.useEffect(() => { loadWhatsapps() }, [])

    return <>
        <Paper p="md">
            <Group>
                <FaPhone />
                {loading && <Loader />}
                <Title style={{flex: 1}} order={5}>Whatsapps</Title>
                <Button
                    leftSection={<FaPlus />}
                    onClick={() => { setNewPhone({  }) }}
                >{str("ADD")} Whatsapp</Button>
            </Group>
        </Paper>

        <Grid mt="md">
            {data.map((phone : any) => (
                <Grid.Col span={{base: 12, md: (!phone?.configured && phone?.data?.qrcode) ? 12 : 6}}>
                    {/* <UnstyledButton style={{width: '100%'}} onClick={() => { setSelectedKnowledgeCategory(phone)}}> */}
                        <Paper p="md" shadow="xs" ta="center" style={{backgroundColor: '#242442'}}>
                            {
                                (!phone?.configured && phone?.data?.qrcode)
                                ? <>
                                    <Text ta="center" mb="lg" fw="bold" size="md">{str("WHATSAPPS.QR_INSTRUCTIONS")}</Text>
                                    <QRCode value={phone?.data?.qrcode} />
                                    {/* <img
                                        style={{width: '100%'}}
                                        src={`${process.env.REACT_APP_SERVER_URL}/phones/whatsapps/${phone?._id}/qrcode`} /> */}
                                </>
                                : <Group>
                                    <ActionIcon
                                        size="lg"
                                        color={phone?.configured ? "green" : "red"}
                                        // onClick={() => {
                                            
                                        // }}
                                    ><FaWhatsapp /></ActionIcon>
                                    <Box style={{flex: 1}}>
                                        <Text ta="center" fw="bold" size="sm">{phone?.data?.phone}</Text>
                                    </Box>
                                </Group>
                            }
                        </Paper>
                    {/* </UnstyledButton> */}
                </Grid.Col>
            ))}
        </Grid>
        
        <Modal
            opened={!!newPhone}
            onClose={() => setNewPhone(null)}
        >
            {/* <Text size="md" ta="center" mb="md">Each WhatsApp account cost $40 monthly</Text> */}

            <Group justify="flex-end">
                <Button onClick={handleSave}>{str("WHATSAPPS.CONNECT")}</Button>
            </Group>
        </Modal>
    </>
}
