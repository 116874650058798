import {
  Box,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Paper, Progress, Switch, Text, Title
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useRef, useState } from "react";
import InputField, { InputFieldMasks } from "../components/input_field";
import { PaymentForm } from "../components/payment_form";
import Table from "../components/table";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import { APPLY_COUPON, GET_BILLING_DETAILS, SAVE_COMPANY_PAYMENT_METHOD, SAVE_AUTO_RECHARGE, UNSUBSCRIBE_COMPANY } from "../services/companies";
import { GET_PLAN_PAYMENTS, GET_STRIPE_CONFIGS, SAVE_PAYMENT_METHOD } from "../services/payments";
import { extenseRecurrenceType, getExtenseDatetime } from "../utility/util";
import moment from "moment";
import { useLanguage } from "../contexts/language.context";

export default function Billing(){
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const [coupon, setCoupon] = useState<any>(null);
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [autoRecharge, setAutoRecharge] = useState<any>({});
  const [loadingUpdatePlan, setLoadingUpdatePlan] = useState<boolean>(false);
  const [loadingUpdateCoupon, setLoadingUpdateCoupon] = useState<boolean>(false);

  const autoRechargeLoadedRef = useRef(false);

  const { userData, loadUserData, ip } = useAuth();
  const { confirmDialog, startPlan, recharge } = useApp();
  const { str } = useLanguage();

  const loadDetails = () => {
    setLoading(true)
    GET_BILLING_DETAILS()
    .then((dt) => {
      setLoading(false)
      setData(dt);
      setAutoRecharge(dt.autoRecharge);
      autoRechargeLoadedRef.current = true;
    })
    .catch((err) => {
      setLoading(false)
      notifications.show({ message: err.message, color: "red" })
    })
  }
  
  const saveAutoRecharge = () => {
    SAVE_AUTO_RECHARGE(autoRecharge)
    .then((dt) => {})
    .catch((err) => { notifications.show({ message: err.message, color: "red" }) })
  }

  const handleUpdateMethod = (methodId, others = {}) => {
    setLoadingUpdatePlan(true)
    SAVE_COMPANY_PAYMENT_METHOD({ payment: { method: methodId, ...others } })
    .then(res => {
      loadUserData();
      setLoadingUpdatePlan(false);
    })
    .catch(err => {
      setLoadingUpdatePlan(false)
      notifications.show({ message: err.message, color: "red" })
    })
  }

  const handleChangePaymentMethod = async () => {
      setLoadingUpdatePlan(true);
      if(paymentMethod.method === "new-card"){
      try{
        const { awaz } = await GET_STRIPE_CONFIGS("awaz")
        const stripe = require('stripe')(awaz);
        const pm = await stripe.paymentMethods.create({
          type: 'card',
          card: paymentMethod?.card,
        })
        const method = await SAVE_PAYMENT_METHOD("awaz", pm, { email: userData.company.email, name: userData.company.name })
        handleUpdateMethod(method._id, { push: true });
      }catch(err){
        setLoadingUpdatePlan(false)
        notifications.show({ message: err?.message ?? "Stripe not configured" });
      }
    }else{
      handleUpdateMethod(paymentMethod.method);
    }
  }
  
  // const handleUpdateCoupon = () => {
  //   setLoadingUpdateCoupon(true)
  //   APPLY_COUPON(coupon)
  //   .then(res => {
  //     loadUserData();
  //     setLoadingUpdateCoupon(false);
  //     notifications.show({ message: "Discount applied", color: "green" })
  //   })
  //   .catch(err => {
  //     setLoadingUpdateCoupon(false)
  //     notifications.show({ message: err.message, color: "red" })
  //   })
  // }
  
  useEffect(() => {
    loadDetails()
  }, [])
  
  useEffect(() => {
    if(autoRechargeLoadedRef.current) saveAutoRecharge();
  }, [autoRecharge])

  useEffect(() => {
    setPaymentMethod({ method: userData?.activePaymentMethod?._id });
  }, [userData])

  return <>
      <LoadingOverlay visible={loading} />
      <Grid mb="md">
        {((userData?.coupon?.value ?? 0) < 100) && <Grid.Col span={{base: 12, md: 12}}>
          <Paper p="md" style={{minHeight: 280, display: 'flex', flexDirection: 'column'}}>
            <Title order={4}>{str("BILLING.YOUR_PLAN_TITLE")}</Title>
            <Title order={5} fw="lighter" mb="md">{str("BILLING.YOUR_PLAN_SUBTITLE")}</Title>

            <Box style={{flex: 1}} mb="md">
              <Paper shadow='xs' p="md" style={{border: '1px solid #DFDFDF', cursor: 'pointer'}} onClick={() => startPlan()}>
                <Title order={4}>{userData?.plan?.title ?? "No plan selected"}</Title>
                {userData?.plan && <>
                  <Text c="gray" size="sm">{userData?.plan?.currency ?? "USD"} {parseFloat(userData?.plan?.price).toFixed(2)}</Text>
                  <Text c="gray" size="xs">{str(`RECURRENCES.${userData?.plan?.recurrence_type ?? "month"}`.toLocaleUpperCase())}</Text>
                  {
                    userData?.activePaymentMethod &&
                    <Text size="xs" ta="right">{str("BILLING.NEXT_PAYMENT")}: {moment(userData?.nextPayment).add(1, "days").format("MMM DD, YYYY")}</Text>
                  }
                </>}
                <Text c="gray" size="sm" mt="md">{userData?.plan?.description}</Text>

                <Group mt="md" justify="flex-end">
                  <Button onClick={() => startPlan()}>{str("UPGRADE")}</Button>
                </Group>
              </Paper>
              {userData?.activePaymentMethod && <Button mt="md" color="white" variant="light" onClick={() => {
                confirmDialog({ text: str("BILLING.UNSUBSCRIBE_CONFIRM") }, ({ confirmed }) => {
                  if(confirmed) UNSUBSCRIBE_COMPANY()
                  .then(() => {
                    notifications.show({ message: str("BILLING.UNSUBSCRIBE_CONFIRMED"), color: "yellow" });
                    loadUserData();
                  })
                  .catch((err) => {
                    notifications.show({ message: err.message, color: "red" })
                  })
                })
              }}>{str("BILLING.UNSUBSCRIBE")}</Button>}
            </Box>
          </Paper>
        </Grid.Col>}
        <Grid.Col span={{base: 12, md: 6}}>
          <Paper p="md" style={{ minHeight: 360, display: 'flex', flexDirection: 'column'}}>
            <Box style={{flex: 1}} mb="md">
              <Paper shadow='xs' p="md" style={{border: '1px solid #DFDFDF', cursor: 'pointer'}} onClick={() => recharge()}>
                <Title order={4} mt="sm">{str("BILLING.BALANCE")}</Title>
                <Title order={2} mt="sm">$ {((data?.totalCredits ?? 0) - (data?.creditsUsage ?? 0)).toFixed(2)}</Title>
                {/* <Progress
                  mt="md"
                  value={data?.totalCredits > 0 ? 100*((data?.creditsUsage ?? 0)/data?.totalCredits) : 100}
                />
                <Text ta="right" mt="xs" c="gray" size="xs">{((data?.creditsUsage ?? 0) / 60).toFixed(2)} / {(data?.totalCredits ?? 0).toFixed(2)}</Text> */}

                <Group justify="flex-end">
                  <Button onClick={() => recharge()}>{str("BILLING.RECHARGE")}</Button>
                </Group>
              </Paper>
            </Box>
            <Switch
              checked={autoRecharge?.enabled}
              label={str("BILLING.AUTO_RECHARGE")}
              fw="bold"
              mt="md"
              onChange={() => {
                setAutoRecharge(ar => ({ ...ar, enabled: !ar.enabled, base: ar.base ?? 10, value: ar.value ?? 10 }))
              }}
            />
            {autoRecharge?.enabled && <Group mt="md" align="flex-end">
              <InputField
                title={str("BILLING.WHEN_BALANCE_UNDER")}
                value={autoRecharge?.base}
                name="base"
                style={{flex: 1}}
                mask={InputFieldMasks.MONEY}
                onChange={({base}) => {
                  setAutoRecharge(ar => ({ ...ar, base }))
                }}
              />
              <InputField
                title={str("BILLING.RECHARGES")}
                value={autoRecharge?.value}
                name="value"
                style={{flex: 1}}
                mask={InputFieldMasks.MONEY}
                onChange={({value}) => {
                  setAutoRecharge(ar => ({ ...ar, value }))
                }}
              />
            </Group>}
          </Paper>
        </Grid.Col>

        <Grid.Col span={{base: 12, md: 6}}>
          <Paper p="md" style={{minHeight: 360, display: 'flex', flexDirection: 'column'}}>
            <Title order={4}>{str("BILLING.PAYMENT_METHOD")}</Title>
            <Title order={5} fw="lighter" mb="md">{str("BILLING.PAYMENT_METHOD_SUBTITLE")}</Title>

            <Box style={{flex: 1}}>
              <PaymentForm
                data={paymentMethod}
                methods={userData?.paymentMethods}
                onChange={setPaymentMethod}
              />
            </Box>
            <Group justify="flex-end">
              <Button loading={loadingUpdatePlan} onClick={handleChangePaymentMethod}>{str("UPDATE")}</Button>
            </Group>
          </Paper>
        </Grid.Col>

        <Grid.Col span={{base: 12, md: 12}}>
          <Paper p="md">
            <Title order={4} mb="md">{str("BILLING.PLAN")}</Title>

            {
              (data?.payments ?? []).length === 0
              ? <Text ta="center" mt="md" size="md" c="gray">{str("BILLING.NO_PAYMENT")}</Text>
              : <Table
                data={(data?.payments ?? []).filter(p => p.payed_at && !p.refund_at).sort((a,b) => a.payed_at > b.payed_at ? -1 : 1)}
                columns={[
                  {
                    title: str("DATE"),
                    key: "createdAt",
                    render: (item) => item.createdAt ? getExtenseDatetime(item.createdAt) : ""
                  },
                  {
                    title: str("DESCRIPTION"),
                    key: "details",
                    render: (item) => item.details?.text
                  },
                  {
                    title: str("VALUE"),
                    key: "value",
                    render: (item) => `${item.data.currency.toUpperCase()} ${(item.data.amount/100).toFixed(2)}`
                  }
                ]}
              />
            }

          </Paper>
        </Grid.Col>
      </Grid>


  </>
};
