import { ActionIcon, Badge, Grid, Group, Modal, Paper, Text, Title, UnstyledButton } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import React, { useState } from 'react'
import { FaEnvelope, FaPhone, FaSearch } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../contexts/language.context'
import { SEARCH_CONTACT } from '../services/contacts'
import InputField, { InputFieldTypes } from './input_field'

export default function SearchContact({ open, onClose }){
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [searchedKeyword, setSearchedKeyword] = useState(null);
    const [results, setResults] = useState<any[]>([]);

    const navigate = useNavigate();
    const { str } = useLanguage();

    const handleSearch = () => {
        if(keyword.trim().length > 2){
            setLoadingSearch(true)
            SEARCH_CONTACT(keyword)
            .then((contacts) => {
                setLoadingSearch(false);
                setResults(contacts);
                setSearchedKeyword(keyword.trim());
            })
            .catch(err => {
                setLoadingSearch(false)
                showNotification({title: "Ops", message: err.message, color: 'red'})
            })
        }
    }

    return <Modal
        opened={open}
        onClose={onClose}
        size="md"
        title={<Title order={4}>{str("CONTACTS.SEARCH")}</Title>}
    >
        <InputField
            name="contact"
            fullWidth
            size="md"
            fieldType={InputFieldTypes.SEARCH}
            onChange={({contact}) => {
                if(contact?._id){
                    navigate(`/contacts/${contact._id}`);
                    onClose();
                }
            }}
            searchType="contact"
            value={keyword}
        />
    </Modal>

}