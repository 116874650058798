import { api } from "./api"

export const GET_ALL_CALENDARS = async () => {
    const {data} = await api.get("/calendars/all")
    return data;
}

export const GET_CALENDAR_SCHEDULES = async (calendarId, datetime) => {
    const {data} = await api.get(`/calendars/${calendarId}/schedules`, { params: { datetime } })
    return data;
}

export const GET_ALL_AGENTS_SCHEDULES = async () => {
    const {data} = await api.get("/calendars/agents/schedules")
    return data;
}

export const DELETE_CALENDAR = async (id) => {
    const {data} = await api.delete(`/calendars/${id}`)
    return data;
}

export const DELETE_SCHEDULE = async (scheduleId) => {
    const {data} = await api.delete(`/calendars/schedules/${scheduleId}`)
    return data;
}

export const DELETE_AGENT_SCHEDULE = async (scheduleId) => {
    const {data} = await api.delete(`/calendars/agents/schedules/${scheduleId}`)
    return data;
}

export const SAVE_CALENDAR = async (params: any) => {
    const {data} = await api.post("/calendars/save", params)
    return data;
}

export const SAVE_AGENT_SCHEDULE = async (params: any) => {
    const {data} = await api.post("/calendars/save/agent/schedule", params)
    return data;
}

