import {
    ActionIcon,
    Box,
    Button,
    Grid,
    Group,
    Menu,
    Modal,
    Paper,
    Text,
    UnstyledButton
} from "@mantine/core";
import React, { useState } from "react";
import { useLanguage } from "../../contexts/language.context";
import InputField, { InputFieldTypes } from "../input_field";
import { FaPlus } from "react-icons/fa";
import { useAgentVariables, useApp } from "../../contexts/app.context";
import { FaTrash } from "react-icons/fa6";

export default function Prompt({ data, setData }: any){
    const { str } = useLanguage();
    const { confirmDialog } = useApp();
    const companyVariables = useAgentVariables();

    const [addingStackInstructions, setAddingStackInstructions] = useState(null);

    const handleAddStack = item => {
        setData(d => ({
            ...d,
            data: {
                ...d.data,
                instructions_stack: [
                    ...(d.data.instructions_stack ?? []),
                    item
                ]
            }
        }))
    }

    const handleChangeStack = (index, dt) => {
        setData(d => ({
            ...d,
            data: {
                ...d.data,
                instructions_stack: (
                    d.data.instructions_stack.map((is, isi) => (
                        isi === index
                        ? { ...is, ...dt }
                        : is
                    ))
                )
            }
        }))
    }
    
    const handleAddStackInstructions = (index, dt) => {
        setData(d => ({
            ...d,
            data: {
                ...d.data,
                instructions_stack: (
                    d.data.instructions_stack.map((is, isi) => (
                        isi === index
                        ? { ...is, instructions: [...is.instructions, dt] }
                        : is
                    ))
                )
            }
        }))
    }
    
    const handleUpdateStackInstructions = (index, updateIndex, dt) => {
        setData(d => ({
            ...d,
            data: {
                ...d.data,
                instructions_stack: (
                    d.data.instructions_stack.map((is, isi) => (
                        isi === index
                        ? { ...is, instructions: is.instructions.map((i, ii) => (
                            ii === updateIndex
                            ? { ...i, ...dt }
                            : i
                        )) }
                        : is
                    ))
                )
            }
        }))
    }
    
    const handleRemoveStack = (index) => {
        setData(d => ({
            ...d,
            data: {
                ...d.data,
                instructions_stack: (
                    d.data.instructions_stack.filter((is, isi) => (
                        isi !== index
                    ))
                )
            }
        }))
    }

    return <Box p="md">
        <Group align="flex-start">
            <InputField
                name="initial_message_option"
                title={str("AGENTS.WHO_SPEAKS_FIRST")}
                value={data?.data?.initial_message_option}
                style={{flex: 1}}
                fieldType={InputFieldTypes.SELECT}
                onChange={({initial_message_option}) => setData(na => ({...na, data: { ...na?.data, initial_message_option }}))}
                options={[
                { value: "user-initiate", label: str("AGENTS.USER_INITIATE") },
                { value: "ai-initiate-dynamic", label: str("AGENTS.AI_INITIATE_DYNAMIC") },
                { value: "ai-initiate-defined", label: str("AGENTS.AI_INITIATE_DEFINED") },
                ]}
            />
            <Box style={{flex: 1}}>
                {data?.data?.initial_message_option === "ai-initiate-defined" && <>
                <InputField
                name="initial_message"
                title={str("AGENTS.FIRST_MESSAGE_TITLE")}
                placeholder={str("AGENTS.FIRST_MESSAGE_PLACEHOLDER")}
                value={data?.data?.initial_message}
                options={companyVariables}
                onChange={({initial_message}) => setData(na => ({...na, data: { ...na?.data, initial_message }}))}
                />
                <Menu>
                <Menu.Target><Button mt="xs" color="white" variant="outline" size="xs" leftSection={<FaPlus />}>{str("VARIABLE")}</Button></Menu.Target>
                <Menu.Dropdown>
                    {
                        (companyVariables).map(v => (
                        <Menu.Item onClick={() => {
                            setData(na => ({...na, data: { ...na?.data, initial_message: `${na?.data?.initial_message ?? ""}{{${v.value}}}` }}))
                        }}>{v.label}</Menu.Item>
                        ))
                    }
                </Menu.Dropdown>
                </Menu></>}
            </Box>
        </Group>
        {/* <InputField
        name="description"
        title="Description"
        mt="md"
        value={data?.data?.description}
        style={{flex: 1}}
        onChange={({description}) => setData(na => ({...na, data: { ...na?.data, description }}))}
        /> */}

        {
            (data?.data?.instructions_stack ?? []).map((stack, si) => <>
                <Group mt="md">
                    <InputField
                        name="title"
                        style={{flex: 1}}
                        size="sm"
                        value={stack.title}
                        onChange={({title}) => handleChangeStack(si, { title })}
                    />
                    <Button
                        variant="light"
                        size="sm"
                        onClick={() => setAddingStackInstructions({ index: si })}
                        leftSection={<FaPlus />}
                    >Instruction</Button>
                    <ActionIcon
                        variant="light"
                        size="lg"
                        onClick={() => {
                            confirmDialog(
                                { text: "Are you sure about deleting this instruction?" },
                                ({ confirmed }) => {
                                    if (confirmed){ handleRemoveStack(si) }
                                }
                            );
                        }}
                    ><FaTrash /></ActionIcon>
                </Group>

                <Group pb="md" style={{ flexWrap: 'nowrap', overflowX: 'scroll' }}>
                    {stack.instructions.map((inst, ii) => (
                        <UnstyledButton
                            style={{ maxWidth: 300, minWidth: 300 }}
                            onClick={() => {
                                setAddingStackInstructions({ index: si, updateIndex: ii, data: inst })
                            }}
                        >
                            <Paper p="md" mt="md" style={{ backgroundColor: '#242442', height: 140 }}>
                                <Text lineClamp={1} size="md">{inst.title}</Text>
                                <Text lineClamp={3} size="sm" mt="xs" c="gray">{inst.description}</Text>
                            </Paper>
                        </UnstyledButton>
                    ))}
                </Group>
            </>)
        }
        <Menu>
            <Menu.Target>
                <Button
                    mt="md"
                    variant="light"
                    leftSection={<FaPlus />}
                >Instructions Category</Button>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item
                    onClick={() => {
                        handleAddStack({
                            title: "Response Guideline",
                            instructions: [
                                { title: "Overcome ASR errors", description: "This is a real-time transcript, expect there to be errors. If you can guess what the user is trying to say,  then guess and respond. When you must ask for clarification, pretend that you heard the voice and be colloquial (use phrases like \"didn't catch that\", \"some noise\", \"pardon\", \"you're coming through choppy\", \"static in your speech\", \"voice is cutting in and out\"). Do not ever mention \"transcription error\", and don't repeat yourself." },
                                { title: "Always stick to your role", description: "Think about what your role can and cannot do. If your role cannot do something, try to steer the conversation back to the goal of the conversation and to your role. Don't repeat yourself in doing this. You should still be creative, human-like, and lively." },
                                { title: "Create smooth conversation", description: "Your response should both fit your role and fit into the live calling session to create a human-like conversation. You respond directly to what the user just said." },
                            ],
                        })
                    }}
                >Response Guideline</Menu.Item>
                <Menu.Item
                    onClick={() => {
                        handleAddStack({
                            title: "Style Guardrails",
                            instructions: [
                                { title: 'Be concise', description: "Keep your response succinct, short, and get to the point quickly. Address one question or action item at a time. Don't pack everything you want to say into one utterance." },
                                { title: 'Do not repeat', description: "Don't repeat what's in the transcript. Rephrase if you have to reiterate a point. Use varied sentence structures and vocabulary to ensure each response is unique and personalized." },
                                { title: 'Be conversational', description: "Speak like a human as though you're speaking to a close friend -- use everyday language and keep it human-like. Occasionally add filler words, while keeping the prose short. Avoid using big words or sounding too formal." },
                                { title: 'Reply with emotions', description: " You have human-like emotions, attitudes, and dispositions. When appropriate: use tone and style to create more engaging and personalized responses; incorporate humor or wit; get emotional or empathetic; apply elements of surprise or suspense to keep the user engaged. Don't be a pushover." },
                                { title: 'Be proactive', description: "Lead the conversation and do not be passive. Most times, engage users by ending with a question or suggested next step." },
                            ],
                        })
                    }}
                >Style Guardrails</Menu.Item>
                <Menu.Item
                    onClick={() => {
                        handleAddStack({
                            title: "Instructions",
                            instructions: [],
                        })
                    }}
                >Custom</Menu.Item>
            </Menu.Dropdown>
        </Menu>

        <InputField
            name="instructions"
            title={str("AGENTS.PROMPT")}
            mt="md"
            fieldType={InputFieldTypes.TEXTAREA}
            value={data?.data?.instructions}
            options={companyVariables}
            minRows={20}
            maxRows={20}
            onChange={({instructions}) => setData(na => ({...na, data: { ...na?.data, instructions }}))}
        />
        <Menu>
            <Menu.Target><Button variant="outline" color="white" mt="xs" size="xs" leftSection={<FaPlus />}>{str("VARIABLE")}</Button></Menu.Target>
            <Menu.Dropdown>
                {
                    (companyVariables).map(v => (
                    <Menu.Item onClick={() => {
                        setData(na => ({...na, data: { ...na?.data, instructions: `${na?.data?.instructions ?? ""}{{${v.value}}}` }}))
                    }}>{v.label}</Menu.Item>
                    ))
                }
            </Menu.Dropdown>
        </Menu>

        <Modal
            opened={!!addingStackInstructions}
            onClose={() => setAddingStackInstructions(null)}
            size="lg"
        >
            <Group style={{flexDirection: 'column', alignItems: 'unset'}}>
                <InputField
                    name="title"
                    placeholder="Instruction title"
                    value={addingStackInstructions?.data?.title}
                    onChange={({title}) => setAddingStackInstructions(asi => ({ ...asi, data: { ...asi.data, title }}))}
                />
                <InputField
                    name="description"
                    placeholder="Type the instruction here"
                    value={addingStackInstructions?.data?.description}
                    fieldType={InputFieldTypes.TEXTAREA}
                    options={companyVariables}
                    minRows={10}
                    maxRows={10}
                    onChange={({description}) => setAddingStackInstructions(asi => ({ ...asi, data: { ...asi.data, description }}))}
                />
                <Menu>
                    <Menu.Target><Button mt="xs" color="white" variant="outline" size="xs" leftSection={<FaPlus />}>{str("VARIABLE")}</Button></Menu.Target>
                    <Menu.Dropdown>
                        {
                            (companyVariables).map(v => (
                            <Menu.Item onClick={() => {
                                setAddingStackInstructions(na => ({...na, data: { ...na?.data, description: `${na?.data?.description ?? ""}{{${v.value}}}` }}))
                            }}>{v.label}</Menu.Item>
                            ))
                        }
                    </Menu.Dropdown>
                </Menu>

                <Group justify="flex-end">
                    <Button
                        disabled={!addingStackInstructions?.data?.title || !addingStackInstructions?.data?.description}
                        onClick={() => {
                            if(!isNaN(addingStackInstructions?.updateIndex)) handleUpdateStackInstructions(addingStackInstructions?.index, addingStackInstructions?.updateIndex, addingStackInstructions?.data);
                            else handleAddStackInstructions(addingStackInstructions?.index, addingStackInstructions?.data);

                            setAddingStackInstructions(null);
                        }}
                    >{str("SAVE")}</Button>
                </Group>
            </Group>
        </Modal>
    </Box>
}