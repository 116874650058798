import { api } from "./api"

export const GET_ALL_CAMPAIGNS = async () => {
    const {data} = await api.get(`/campaigns`)
    return data.campaigns || [];
}

export const SAVE_CAMPAIGN = async (params) => {
    const {data} = await api.post(`/campaigns`, params)
    return data.item;
}

export const SEND_CAMPAIGN = async (campaignId, params) => {
    const {data} = await api.post(`/campaigns/${campaignId}`, params)
    return data;
}

export const UPDATE_CAMPAIGN = async (campaignId, params) => {
    const {data} = await api.patch(`/campaigns/${campaignId}`, params)
    return data;
}

export const DELETE_CAMPAIGN = async (campaignId) => {
    const {data} = await api.delete(`/campaigns/${campaignId}`)
    return data;
}
