import { Button, Grid, Group, Paper } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Service } from "../components/service";
import { SAVE_PLAN, GET_ALL_PLANS, DELETE_PLAN } from "../services/plans";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { notifications } from "@mantine/notifications";
import { useApp } from "../contexts/app.context";

export default function PlansPage(){
    const [plans, setPlans] = useState<any[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const { confirmDialog } = useApp();

    const loadPlans = () => {
        GET_ALL_PLANS()
        .then(res => {
            setPlans(res);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
        })
    }
    
    const handleSave = (res) => {
        let params = { ...selectedPlan };

        setLoadingSave(true)
        SAVE_PLAN(params)
        .then((res) => {
            loadPlans();
            setSelectedPlan(res)
            setLoadingSave(false);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
            setLoadingSave(false);
        })
    }

    useEffect(loadPlans, []);
    
    return <div style={{position: 'relative'}}>
        <Grid>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper p="md" mb="md">
                    <Group>
                        <InputField
                            name="pl"
                            style={{flex: 1}}
                            onChange={({pl}) => {
                                pl === "new"
                                ? setSelectedPlan({  })
                                : setSelectedPlan(plans.find(p => p._id === pl))
                            }}
                            value={selectedPlan ? selectedPlan?._id || "new" : null}
                            fieldType={InputFieldTypes.SELECT}
                            clearable={false}
                            searchable={false}
                            options={[
                                ...plans.map(p => ({label: p.title, value: p._id})),
                                { value: "new", label: "Add new plan" }
                            ]}
                        />
                        {selectedPlan?._id && <Button color="gray" variant="outline"
                            onClick={() => {
                                confirmDialog(
                                    { text: "Do you realy wants to delete this plan?" },
                                    ({ confirmed }) => {
                                        if(confirmed) DELETE_PLAN(selectedPlan?._id)
                                        .then(() => {
                                            loadPlans();
                                            setSelectedPlan(null);
                                        })
                                        .catch(err => {
                                            notifications.show({ message: err.message })
                                        })
                                    }
                                )
                            }}
                        >Delete plan</Button>}
                    </Group>
                </Paper>

                {selectedPlan && <>
                    <Paper p="md" mb="md">
                        <Group>
                            <InputField
                                name="price"
                                mask={InputFieldMasks.MONEY}
                                onChange={({price}) => setSelectedPlan(sp => ({...sp, price}))}
                                value={selectedPlan?.price}
                                title="Price"
                                style={{flex: 1}}
                            />
                            <InputField
                                name="recurrence_type"
                                title="Recurrence Type"
                                value={selectedPlan?.recurrence_type ?? "month"}
                                fieldType={InputFieldTypes.SELECT}
                                placeholder='Period'
                                searchable={false}
                                clearable={false}
                                style={{flex: 1}}
                                onChange={({recurrence_type}) => { setSelectedPlan(sp => ({...sp, recurrence_type})) }}
                                options={[
                                    { value: "month", label: "Month(s)" },
                                    // { value: "week", label: "Week(s)" },
                                    { value: "year", label: "Year(s)" },
                                ]}
                            />
                        </Group>
                        <Group mt="md">
                            <InputField
                                name="add_on_calls"                                
                                mask={InputFieldMasks.MONEY}
                                onChange={({add_on_calls}) => setSelectedPlan(sp => ({...sp, add_on_calls}))}
                                value={selectedPlan?.add_on_calls}
                                title="Add On Calls"
                                style={{flex: 1}}
                            />
                            <InputField
                                name="max_agents"
                                fieldType={InputFieldTypes.NUMBER}
                                onChange={({max_agents}) => setSelectedPlan(sp => ({...sp, max_agents}))}
                                value={selectedPlan?.max_agents}
                                title="Max. Agents"
                                style={{flex: 1}}
                            />
                            <InputField
                                name="max_users"
                                fieldType={InputFieldTypes.NUMBER}
                                onChange={({max_users}) => setSelectedPlan(sp => ({...sp, max_users}))}
                                value={selectedPlan?.max_users}
                                title="Max Users"
                                style={{flex: 1}}
                            />
                        </Group>
                        {/* <InputField
                            name="exceed_price"
                            mt="md"
                            mask={InputFieldMasks.MONEY}
                            onChange={({exceed_price}) => setSelectedPlan(sp => ({...sp, exceed_price}))}
                            value={selectedPlan?.exceed_price}
                            title="Exceed Price"
                        /> */}
                    </Paper>
                    <Paper p="md">
                        <InputField
                            name="title"
                            mt="md"
                            onChange={({title}) => setSelectedPlan(sp => ({...sp, title}))}
                            value={selectedPlan?.title}
                            title="Plan Title"
                        />
                        <InputField
                            name="description"
                            mt="md"
                            fieldType={InputFieldTypes.TEXTAREA}
                            onChange={({description}) => setSelectedPlan(sp => ({...sp, description}))}
                            value={selectedPlan?.description}
                            title="Plan Desciption"
                        />
                    </Paper>
                    <Button mt="md" loading={loadingSave} onClick={handleSave}>Save</Button>
                </>}
            </Grid.Col>
        </Grid>
    </div>
}
