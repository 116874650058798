import { Box, Paper, Text } from "@mantine/core";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { FaPaperclip } from "react-icons/fa";

export default function ConversationBox({
  messages = [],
  reverse = false,
  ...others
}) {
  const scrollRef = useRef<any>();

  useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollTo({
        top: scrollRef.current?.scrollHeight,
        behavior: "smooth",
      });
    }, 300);
  }, [scrollRef, messages.length]);

  const removeHash = /--0000000000.*$/;

  const renderMessage = (message: any) => {
    if(message.render) return message.render();
    const selfMessage = message.role === "agent"

    return (
      <div style={{ textAlign: selfMessage ? "right" : "left", display: 'flex', flexDirection: 'column', alignItems: selfMessage ? 'flex-end' : 'flex-start' }}>
        <Paper
          m="xs"
          p="xs"
          style={{
            border: 0,
            maxWidth: "80%",
            minWidth: "60%",
            // display: "inline-block",
            backgroundColor: selfMessage
                ? "#228be6"
                : "#eaf3fd",
            borderRadius: selfMessage
                ? "10px 10px 0px 10px"
                : "10px 10px 10px 0px",
          }}
        >
          {(message.files || []).map((file: any) => <div
            onClick={() => window.open(file.url,"_blank") }
            style={{
              cursor: "pointer",
              padding: "10px 0",
            }}
          >
            {file.mimetype?.includes("image")
            ? <div
              style={{
                maxWidth: '100%',
                height: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // background: "#F4F4F4",
                // border: "1px solid #EFEFEF",
                borderRadius: 10,
                backgroundPosition: "center center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${file.url})`,
              }}
            />
            // : file.mimetype?.includes("pdf")
            // ? <PDFThumbnail pdfBlobUrl={file.url} />
            : <div style={{padding: 20, borderRadius: 10, background: '#FAFAFA', textAlign: 'center'}}><FaPaperclip /> {file.original_name ?? "Attachment"}</div>}
            </div>
          )}
          
          <Text c={selfMessage ? "white" : "blue"} size="sm" className="conversation-box-text" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {message.content}
          </Text>
          {/* {(message.error || message.status === "failed") && (
            <Text size="xs" c="red">
              {message.error || "Message Failed"}
            </Text>
          )} */}
          {/* <Text c={selfMessage ? "white" : "blue"} size="xs" ta="right" mt="md">
            {moment(message.createdAt).format("LLL")}
          </Text> */}
        </Paper>
      </div>
    );
  };

  return (
    <Box p="md" style={{ overflowY: "auto" }} ref={scrollRef} {...others}>
      {[
        ...(messages || []).map((item: any) => ({
          type: "message",
          action: "",
          datetime: item.createdAt,
          item,
        })),
      ]
        .sort((a: any, b: any) => (a.datetime > b.datetime ? (reverse ? -1 : 1) : (reverse ? 1 : -1)))
        .map(({ item, type, datetime, action }) =>
          ["tool_call_result", "tool_call_invocation"].includes(item.role) ? (
            <Text
              size="xs"
              c="blue"
              m="md"
              style={{
                textAlign: "center",
                background: "#F9F9F9",
                border: "1px solid #EFEFEF",
                padding: "2px 20px",
              }}
            >
              {item.role} - {item.name} - {item.arguments ?? item.content}
            </Text>
          ) : (
            renderMessage(item)
          )
        )}
    </Box>
  );
}
