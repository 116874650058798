import { ActionIcon, Badge, Box, Button, Divider, Grid, Group, Image, Loader, Paper, Switch, Text, Title, UnstyledButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaCheck, FaLongArrowAltRight, FaTimes } from 'react-icons/fa';
import InputField, { InputFieldTypes } from '../components/input_field';
import { PaymentForm } from '../components/payment_form';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { SAVE_COMPANY_PLAN, SEARCH_COUPON } from '../services/companies';
import { GET_ALL_PLANS } from '../services/plans';
import { extenseRecurrenceType } from '../utility/util';
import { GET_STRIPE_CONFIGS, SAVE_PAYMENT_METHOD } from '../services/payments';
import { useLanguage } from '../contexts/language.context';

export default function ChoosePlan({ onClose = null }){
    const [loadingPlans, setLoadingPlans] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [plans, setPlans] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>({});
    const [searchCoupon, setSearchCoupon] = useState<string>("");
    const [selectedRecurrencyType, setSelectedRecurrencyType] = useState<string>("month");
    const [coupons, setCoupons] = useState<any>({ });

    const selectedPlan = plans.find(s => s._id === formData?.plan);

    const { startPlan, agents } = useApp();
    const { str } = useLanguage();
    const { planData, userData, loadUserData, ip } = useAuth();

    let vl = parseFloat(selectedPlan?.price ?? 0);
    if(coupons[searchCoupon]) vl = coupons[searchCoupon].type === "static"
        ? vl - coupons[searchCoupon].value
        : vl * ((100 - coupons[searchCoupon].value)/100);

    const handleSave = async () => {
        if(formData?.payment?.method){
            setLoadingSave(true);
            if(formData?.payment?.method === "new-card"){
                try{
                    const { awaz } = await GET_STRIPE_CONFIGS("awaz")
                    const stripe = require('stripe')(awaz);
                    const pm = await stripe.paymentMethods.create({
                        type: 'card',
                        card: formData?.payment?.card,
                    })
                    const method = await SAVE_PAYMENT_METHOD("awaz", pm, { email: userData.company.email, name: userData.company.name })
                    finishSave(method._id, { push: true });
                }catch(err){
                    setLoadingSave(false)
                    notifications.show({ message: err?.message ?? "Stripe not configured" });
                }
            }else{
                finishSave(formData?.payment?.method);
            }
        }else{
            notifications.show({ message: str("BILLING.CHOOSE_A_PAYMENT_METHOD") });
        }
    }

    const finishSave = (method, others = {}) => {
        setLoadingSave(true);

        SAVE_COMPANY_PLAN({ ...formData, payment: { method, ...others }, coupon: coupons[searchCoupon] })
        .then(res => {
            setLoadingSave(false);
            startPlan(false);
            // if(res.payed_at){
            //     loadUserData();
                if(selectedPlan){
                    (window as any).fbq('track', 'Subscribe', {
                        ip,
                        name: userData?.user?.name,
                        email: userData?.user?.email,
                        phone: `+${userData?.user?.phone_code}${userData?.user?.phone.replace(/\(|\)|\-|\.| /g, "")}`,
                        plan: selectedPlan?.title,
                        currency: selectedPlan?.currency ?? "USD",
                        value: selectedPlan?.price
                    });
                }
            // }else{
            //     if(res.return_url) window.open(res.return_url, "_blank");
            //     else notifications.show({ message: "You need to finish the payment process in your bank. Check for notifications and approve the transaction to finish", color: "yellow" })
            // }
        })
        .catch(err => { 
            setLoadingSave(false)
            notifications.show({message: err.message, color: "red"})
        })
    }
    
    const loadPlans = () => {
        setLoadingPlans(true);
        
        GET_ALL_PLANS()
        .then(res => {
            setPlans(res);
            setLoadingPlans(false);
        })
        .catch(err => { 
            setLoadingPlans(false)
            notifications.show({message: err.message, color: "red"})
        })
    }

    useEffect(() => {
        loadPlans()
    }, []);
    
    useEffect(() => {
        if(searchCoupon.length > 3) SEARCH_COUPON(searchCoupon)
        .then(res => {
            setCoupons(c => ({...c, [searchCoupon]: res }))
        })
        .catch(err => {
        })
    }, [searchCoupon]);
    
    useEffect(() => {
        setFormData(fd => ({...fd, seats: (planData?.seats || []).length <= 0 ? 1 : (planData?.seats || []).length }))
    }, [planData]);
    
    return <Box p="md">
        {!selectedPlan && <>
            <Group align='flex-start'>
                <div style={{flex: 1}}>
                    <Title order={3}>{str("SIGNUP.PLAN_PACKAGE")}</Title>
                    <Title order={5} style={{fontWeight: 'normal'}}>{str("SIGNUP.PRICE_SUBTITLE")}</Title>
                </div>
                {onClose && <ActionIcon onClick={onClose} variant='outline'>
                    <FaTimes />
                </ActionIcon>}
            </Group>

            <Group pb="md" pt="md" style={{justifyContent: 'center'}} align='center'>
                <Group gap={5} style={{borderRadius: 40, padding: '10px', border: '1px solid #DFDFDF', whiteSpace: 'nowrap'}}>
                    <Button
                        style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "month" ? '1px solid #DFDFDF' : 0 }}
                    
                        onClick={() => setSelectedRecurrencyType("month")}
                        variant={selectedRecurrencyType === "month" ? "filled" : "light"} size="xs">{str("RECURRENCES.MONTH")}</Button>
                    <Button
                        style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "year" ? '1px solid #DFDFDF' : 0 }}
                    
                        onClick={() => setSelectedRecurrencyType("year")}
                        variant={selectedRecurrencyType === "year" ? "filled" : "light"} size="xs">{str("RECURRENCES.YEAR")}</Button>
                </Group>
            </Group>
        
            {loadingPlans && <Loader mt="xl" />}
            <Grid mt="md" mb="md">
                {plans
                .filter(p => (selectedRecurrencyType === "month" && !p.recurrence_type) || p.recurrence_type === selectedRecurrencyType)
                .map(plan => (
                    <Grid.Col span={{ base: 12, md: 4 }}>
                        <UnstyledButton style={{width: '100%'}} onClick={() => {
                            setFormData(fd => ({...fd, plan: plan._id}))
                        }}>
                            <Paper shadow='xs' p="md" style={{borderColor: '#DFDFDF'}}>
                                <Title order={3}>{plan.title}</Title>
                                <Text c="gray" size="sm">{plan.description}</Text>

                                <Group mt="md" align='flex-end' gap="1px">
                                    <Title size="22px">{plan.curreny ?? "USD"} {parseFloat(plan.price).toFixed(2)}</Title>
                                    <Text size="sm">/ {str(`RECURRENCES.${plan.recurrence_type ?? "month"}`.toUpperCase())}</Text>
                                </Group>
                                {/* <Group align='center' justify='flex-end' mt="xs">
                                    {(plan.service.badges ?? []).map(s => ( <Badge variant='filled' c={s.textColor} color={s.color}>{s.text}</Badge> ))}
                                </Group> */}
                                {/* <Text c="gray" size="sm">Per user</Text> */}
                                <Button mt="md" fullWidth>{str("GET_STARTED")}</Button>
                            </Paper>
                        </UnstyledButton>
                    </Grid.Col>
                ))}
            </Grid>
        </>}

        {selectedPlan && <>
            <Grid>
                <Grid.Col span={{ base: 12, md: 12 }}>
                    <Group>
                        <ActionIcon size="xl" variant="outline" color="gray" onClick={() => setFormData(fd => ({...fd, plan: null}))}><FaArrowLeft /></ActionIcon>
                        <Title order={3}>Checkout</Title>
                    </Group>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12 }}>
                    <Paper style={{borderColor: "#DFDFDF"}} p="xl">
                        <Group align="flex-start">
                            <Box style={{flex: 0.6}}>
                                <Title order={3}>{selectedPlan.title}</Title>
                                <Text size="sm">{selectedPlan.description}</Text>
                            </Box>
                            <Box style={{flex: 0.4}}>
                                <Group align='flex-end' gap="1px">
                                    <Title size="22px">{selectedPlan.currency ?? "USD"} {parseFloat(selectedPlan.price).toFixed(2)}</Title>
                                    <Text c="gray" size="xs">{str(`RECURRENCES.${selectedPlan?.recurrence_type ?? "month"}`.toUpperCase())}</Text>
                                </Group>
                                {/* <Text mb="md" c="gray" size="sm">Per user</Text>
                                <InputField
                                    title="Add Users"
                                    name="seats"
                                    fieldType={InputFieldTypes.QUANTITY}
                                    value={formData.seats}
                                    onChange={({seats}) => {
                                        let minimun = (planData?.seats || []).length;
                                        minimun = (minimun > 1 ? minimun : 1);
                                        if(parseInt(seats) >= minimun){
                                            setFormData(fd => ({...fd, seats}));
                                        }
                                    }}
                                    size="xs"
                                /> */}

                                <InputField
                                    title={str("COUPON_CODE")}
                                    name="cp"
                                    mt="sm"
                                    size={"md"}
                                    value={searchCoupon}
                                    onChange={({cp}) => { setSearchCoupon(cp) }}
                                    rightSection={coupons[searchCoupon] && <FaCheck />}
                                />
                            </Box>
                            
                        </Group>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 7 }}>
                    <Paper style={{borderColor: "#DFDFDF"}} p="xl">
                        {
                            <>
                                <Title order={4}>{str("SIGNUP.PAYMENT_METHOD")}</Title>
                                <Box mt="sm">
                                    <PaymentForm
                                        data={formData?.payment}
                                        methods={userData.paymentMethods}
                                        onChange={(dt) => setFormData(fd => ({
                                            ...fd,
                                            payment: { ...fd?.payment, ...dt }
                                        }))}
                                    />
                                </Box>
                            </>
                        }
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 5 }}>
                    <Paper style={{borderColor: "#DFDFDF"}} p="xl">
                        <Title order={4}>{str("TOTAL")}</Title>

                        <Paper mt="md" p="md" style={{border: "1px solid #242442"}}>
                            {/* <Text size="md">Due Today</Text> */}
                            <Text size="xs">{str("SIGNUP.WEB_PLATFORM")}</Text>
                            <Text size="xs" mt="xs" c="gray">{"USD"} {(vl).toFixed(2)}</Text>
                        </Paper>
                    </Paper>
                    
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12 }}>
                    <Button loading={loadingSave} onClick={handleSave} mt="lg" variant="filled" fullWidth size="lg" rightSection={<FaLongArrowAltRight />}>{str("CONFIRM")}</Button>
                </Grid.Col>
            </Grid>
        </>}
    </Box>
}
