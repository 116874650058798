import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { SAVE_OAUTH } from '../services/oauths';

export enum OAUTH {
    CALENDAR = "calendar",
}

const useOauth = () => {
    const [externalPopup, setExternalPopup] = useState<any>(null);
    const [funcs, setFuncs] = useState<any>(null);
    const [origin, setOrigin] = useState<OAUTH | null>(null);

    const onCode = (code) => {
        SAVE_OAUTH({ ...funcs?.options, origin, data: { ...funcs?.options?.data, code } })
        .then((res) => {
            funcs.onConnect && funcs.onConnect(res);
            showNotification({ message: `${origin} connected.`.toUpperCase(), color: 'green' });
        })
        .catch(err => {
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    const generateRandomString = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const handleLogin = (origin: OAUTH, onConnect, options = null) => {
        clear();
        setOrigin(origin);
        if(externalPopup){
            externalPopup.close();
            setExternalPopup(null);
        }
        const redirectUri = `${window.location.origin}${`/oauth/${origin}`}`;
        const width = 600;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;

        let scope = {
            [`${OAUTH.CALENDAR}`]: 'https://www.googleapis.com/auth/calendar openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        }[`${origin}`];
        let authURL = {
            [`${OAUTH.CALENDAR}`]: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline&include_granted_scopes=true&prompt=consent`,
        }[`${origin}`];
        
        const authWindow = window.open(
            authURL,
            "_blank"
            // 'Login X',
            // `width=${width},height=${height},left=${left},top=${top}`
        );

        setExternalPopup(authWindow);
        setFuncs({ onConnect, options });
    };

    const clear = () => {
        localStorage.removeItem(`${origin}_oauth_code`);
        localStorage.removeItem(`${origin}_oauth_error`);
    }

    useEffect(() => {
        if (!externalPopup) { return; }
    
        const timer = setInterval(() => {
            if (!externalPopup) {
                timer && clearInterval(timer);
                return;
            }
            try{
                const code = localStorage.getItem(`${origin}_oauth_code`);
                const error = localStorage.getItem(`${origin}_oauth_error`);
                if (code) {
                    console.log(`The popup URL has URL code param = ${code}`);
                    clear();
                    onCode(code);
                }else if(error){
                    clear();
                    showNotification({ message: `Falha ao conectar ao ${origin} - ${error}`, color: "red" });
                }
            }catch(err){ console.log(err) }
        }, 500);
        
        return () => { clearInterval(timer) };
    }, [externalPopup, funcs, origin])

    return {
        connect: handleLogin
    };
};

export default useOauth;
