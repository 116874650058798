import { ActionIcon, Avatar, Badge, Box, Button, Grid, Group, LoadingOverlay, Modal, Pagination, Paper, Progress, Text, Tooltip, UnstyledButton } from '@mantine/core';
import { notifications, showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaAddressBook, FaCheckCircle, FaEdit, FaEnvelope, FaPencilAlt, FaPhone, FaPlus, FaTimes, FaTrashAlt, FaUpload } from 'react-icons/fa';
import { BsFileEarmarkPerson, BsChatLeftText } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import InputField, { InputFieldTypes } from '../components/input_field';
import SimpleHeader from '../components/simple_header';
import NewContact from '../components/new_contact';
import { DELETE_CONTACT_LIST, GET_ALL_CONTACTS_LIST, GET_CONTACTS, SAVE_CONTACT_LIST } from '../services/contacts';
import { parseKeyword } from '../utility/util';
import { Dropzone } from '@mantine/dropzone';
import { useLanguage } from '../contexts/language.context';
import { useApp } from '../contexts/app.context';
import { GET_ALL_TAGS } from '../services/tags';
import { useMediaQuery } from '@mantine/hooks';

export default function ContactsPage() {
    const [contactListModal, setContactListModal] = useState<any>(null);
    const [searchContact, setSearchContact] = useState<string>("");
    const [tags, setTags] = useState<any[]>([]);
    const [tagsCategories, setTagsCategories] = useState<any[]>([]);
    const [contactLists, setContactLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shownContacts, setShownContacts] = useState([]);
    const [page, setPage] = useState(1);
    const [quantity, setQuantity] = useState(10);

    const isMobile = useMediaQuery('(max-width: 991px)');

    const navigate = useNavigate();
    const {str} = useLanguage();
    const {confirmDialog} = useApp();

    const loadContactLists = () => {
        setLoading(true)
        GET_ALL_CONTACTS_LIST()
        .then(items => {
                setLoading(false)
                setContactLists(items)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    const loadTags = () => {
        GET_ALL_TAGS()
          .then(({tags = [], categories = []}) => {
            setTags(tags);
            setTagsCategories(categories);
          })
          .catch((err) => {});
      };

    const deleteContactList = (listId) => {
        DELETE_CONTACT_LIST(listId)
        .then(() => {
            loadContactLists();
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" });
        })
    }

    const saveList = () => {
        const formData = new FormData();
        if(contactListModal._id) formData.append("_id", contactListModal._id);
        formData.append("title", contactListModal.title);
        if (contactListModal.file) formData.append("file", contactListModal.file);
        SAVE_CONTACT_LIST(formData)
            .then(({ list }) => {
                loadContactLists();
                setContactListModal(null);
            })
            .catch(err => {
                showNotification({ title: "Ops", message: err.message, color: "red" })
            })
    }

    useEffect(() => {
        loadContactLists();
        loadTags();
    }, []);

    return <Box>
        <LoadingOverlay visible={loading} />
        <Group mb="xl">
            <Button
                onClick={() => setContactListModal({})}
                variant='filled'
                leftSection={<FaPlus />}
            >
                {str("CONTACTS.ADD_NEW_LIST")}
            </Button>
            <div style={{flex: 1}}></div>
            {/* <InputField
                fullWidth
                value={searchContact}
                onChange={({ s }) => setSearchContact(s)}
                name="s"
                placeholder="Search..."
                autoComplete="off"
            />
            <InputField
                name="list"
                placeholder="Filter by Lists"
                fieldType={InputFieldTypes.SELECT}
                value={selectedList?._id}
                onChange={({ list }) => {
                    setPage(1);
                    setSelectedList(list
                        ? contactLists.find(l => l._id === list)
                        : null);
                }}
                options={contactLists.map((cl) => ({
                    label: `${cl.title} (${(cl.contacts || []).length})`,
                    value: cl._id,
                }))}
            /> */}
        </Group>

        {/* {shownContacts.length === 0 && <div style={{ textAlign: 'center', padding: 40 }}>
            <UnstyledButton onClick={() => setNewContact({})}>
                <Paper p="lg" shadow="xs">
                    <Group>
                        <ActionIcon><FaEdit /></ActionIcon>
                        <Text c="gray">Create new contact</Text>
                    </Group>
                    {searchContact.length > 0 && <Text ta="center" c="violet">{searchContact}</Text>}
                </Paper>
            </UnstyledButton>
        </div>} */}
        {!isMobile && <Paper style={{backgroundColor: '#242442'}} p="xs" mb="md">
            <Grid p="sm">
                <Grid.Col span={{base: 12, md: 2}}>
                    <Text ta="center" fw="bold">{str("CONTACTS.LIST_NAME")}</Text>
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 4}}>
                    <Text ta="center" fw="bold">{str("CONTACTS.TOTAL_CONTACTS")}</Text>
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 4}}>
                    {/* <Text ta="center" fw="bold">Tags</Text> */}
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 1}}>
                    <Text ta="center" fw="bold">{str("EDIT")}</Text>
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 1}}>
                    <Text ta="center" fw="bold">{str("DELETE")}</Text>
                </Grid.Col>
            </Grid>
        </Paper>}
        {contactLists.map(list => <>
            <Paper shadow="xs" p="xs" mb="md">
                <Grid p="sm">
                    <Grid.Col span={{base: 12, md: 2}}>
                        <Text>{list.title}</Text>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, md: 4}} ta="center">
                        <Progress.Root size="xl">
                            <Tooltip label={`${list.contacted ?? 0} contacteds`}>
                                <Progress.Section value={((list.contacted ?? 0)/(list.contacts ?? 0))*100} color="blue">
                                </Progress.Section>
                            </Tooltip>
                            <Tooltip label={`${(list.contacts ?? 0) - (list.contacted ?? 0)} not contacted`}>
                                <Progress.Section value={(((list.contacts ?? 0) - (list.contacted ?? 0))/(list.contacts ?? 0))*100} color="transparent">
                                </Progress.Section>
                            </Tooltip>
                        </Progress.Root>
                        <Group>
                            <Text size="xs">{list.contacted ?? 0} contacted</Text>
                            <div style={{flex: 1}}></div>
                            <Text size="xs">{list.contacts ?? 0}</Text>
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, md: 4}} ta="center">
                        {/* {(list.tags ?? []).map(t => <Badge fullWidth mb="xs" color="blue">{t.title} ({t.quantity})</Badge>)} */}
                    </Grid.Col>
                    <Grid.Col span={{base: 12, md: 1}} ta="center">
                        <ActionIcon
                            color="gray"
                            variant="outline"
                            onClick={() => navigate(`/lists/${list._id}`)}
                            // onClick={() => { 
                                // setContactListModal({ _id: list._id, title: list.title })
                            // }}
                        ><FaPencilAlt /></ActionIcon>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, md: 1}} ta="center">
                        {list._id !== "unsigned" && <ActionIcon
                            color="gray"
                            variant="outline"
                            onClick={() => {
                                confirmDialog({ text: str("CONTACTS.LISTS_DELETE_CONFIRM") }, ({confirmed}) => {
                                    if(confirmed){
                                        deleteContactList(list._id)
                                    }
                                })
                            }}
                        ><FaTrashAlt /></ActionIcon>}
                    </Grid.Col>
                </Grid>
            </Paper>
        </>)}
        {/* <Group justify='flex-end'>
            <Pagination m="lg" total={(shownContacts.length/quantity) + 1} value={page} onChange={setPage} />
        </Group> */}
        <Modal opened={!!contactListModal} onClose={() => setContactListModal(null)}>
            <InputField
                name="title"
                title={str("CONTACTS.LIST_NAME")}
                placeholder={str("CONTACTS.LIST_NAME_PLACEHOLDER")}
                value={contactListModal?.title}
                onChange={({ title }) => setContactListModal(l => ({ ...l, title }))}
                mb="lg"
            />
            {/* <Dropzone
                name="file"
                title='CSV File'
                onDrop={(files) => setContactListModal(l => ({ ...l, file: files[0] }))}
            >
                <Group>
                    <FaUpload size={35} />
                    
                    <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                        <div style={{padding: '0 20px'}}>
                            {
                                !!contactListModal?.file
                                ? <Text size="md" inline>
                                    File uploaded
                                </Text>
                                : <Text size="sm" c="dimmed" inline mt={7}>
                                    UPLOAD YOUR CSV FILE
                                </Text>
                            }
                        </div>
                    </div>
                </Group>
            </Dropzone> */}
            <Group mt="xl" justify='flex-end'>
                <Button onClick={saveList}>{str("SAVE")}</Button>
            </Group>
        </Modal>
    </Box>
}