import { ActionIcon, Button, Grid, Group, Paper, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Service } from "../components/service";
import { SAVE_COUPON, GET_ALL_COUPONS, DELETE_COUPON } from "../services/coupons";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { notifications } from "@mantine/notifications";
import { useApp } from "../contexts/app.context";
import { GET_ALL_USERS } from "../services/users";
import { FaTrash } from "react-icons/fa";

export default function CouponsPage(){
    const [coupons, setCoupons] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [selectedCoupon, setSelectedCoupon] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const { confirmDialog } = useApp();

    const loadCoupons = () => {
        GET_ALL_COUPONS()
        .then(res => {
            setCoupons(res);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
        })
    }
    
    const loadUsers = () => {
        GET_ALL_USERS({ admin: 1 })
        .then(res => {
            setUsers(res);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
        })
    }
    
    const handleSave = () => {
        let params = { ...selectedCoupon };

        setLoadingSave(true)
        SAVE_COUPON(params)
        .then((res) => {
            notifications.show({ message: "Coupon saved!", color: "green" })
            loadCoupons();
            setSelectedCoupon(res)
            setLoadingSave(false);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
            setLoadingSave(false);
        })
    }

    useEffect(loadCoupons, []);
    useEffect(loadUsers, []);
    
    return <div style={{position: 'relative'}}>
        <Grid>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper p="md" mb="md">
                    <Group>
                        <InputField
                            name="pl"
                            style={{flex: 1}}
                            onChange={({pl}) => {
                                pl === "new"
                                ? setSelectedCoupon({  })
                                : setSelectedCoupon(coupons.find(p => p._id === pl))
                            }}
                            value={selectedCoupon ? selectedCoupon?._id || "new" : null}
                            fieldType={InputFieldTypes.SELECT}
                            clearable={false}
                            options={[
                                ...coupons.map(p => ({label: p.title, value: p._id})),
                                { value: "new", label: "Add new coupon" }
                            ]}
                        />
                        {selectedCoupon?._id && <ActionIcon size="lg" color="gray" variant="outline"
                            onClick={() => {
                                confirmDialog(
                                    { text: "Do you realy wants to delete this coupon?" },
                                    ({ confirmed }) => {
                                        if(confirmed) DELETE_COUPON(selectedCoupon?._id)
                                        .then(() => {
                                            loadCoupons();
                                            setSelectedCoupon(null);
                                        })
                                        .catch(err => {
                                            notifications.show({ message: err.message })
                                        })
                                    }
                                )
                            }}
                        ><FaTrash /></ActionIcon>}
                    </Group>
                </Paper>

                {selectedCoupon && <>
                    <Paper p="md" mb="md">
                        <Group>
                            <InputField
                                name="title"
                                style={{flex: 1}}
                                onChange={({title}) => setSelectedCoupon(sp => ({...sp, title}))}
                                value={selectedCoupon?.title}
                                title="Coupon Name"
                            />
                            <InputField
                                name="user"
                                style={{flex: 1}}
                                onChange={({user}) => setSelectedCoupon(sp => ({...sp, user}))}
                                value={selectedCoupon?.user}
                                title="Vinculate User"
                                searchable
                                clearable
                                fieldType={InputFieldTypes.SELECT}
                                options={users.map(u => ({ value: u._id, label: `${u.name} - ${u.email}` }))}
                            />
                        </Group>
                    </Paper>
                    <Paper p="md">
                        <Group grow>
                            <InputField
                                name="type"
                                mb="md"
                                onChange={({type}) => setSelectedCoupon(sp => ({...sp, type}))}
                                value={selectedCoupon?.type}
                                title="Discount type"
                                fieldType={InputFieldTypes.SELECT}
                                seachable={false}
                                clearable={false}
                                options={[
                                    {label: "Percent (%)", value: "percent"},
                                    {label: "Static Value", value: "static"},
                                ]}
                            />
                            <InputField
                                name="value"
                                mb="md"
                                onChange={({value}) => setSelectedCoupon(sp => ({...sp, value}))}
                                value={selectedCoupon?.value}
                                title="Discount Value"
                                rightSection={selectedCoupon?.type === "percent" && <Text>%</Text>}
                                fieldType={selectedCoupon?.type === "percent" ? InputFieldTypes.NUMBER : undefined}
                                mask={selectedCoupon?.type === "percent" ? undefined : InputFieldMasks.MONEY}
                            />
                        </Group>
                        <InputField
                            name="maximum_usage"
                            mb="md"
                            onChange={({maximum_usage}) => setSelectedCoupon(sp => ({...sp, maximum_usage}))}
                            value={selectedCoupon?.maximum_usage}
                            title="Maximum Usage"
                            fieldType={InputFieldTypes.NUMBER}
                        />
                    </Paper>
                    <Group justify="flex-end" mt="md">
                        <Button size="md" onClick={handleSave} loading={loadingSave}>Save Coupon</Button>
                    </Group>
                </>}

            </Grid.Col>
        </Grid>
    </div>
}
