import { api } from "./api"

export const SEND_INVITE = async (formData : any) => {
    const {data} = await api.post("/companies/send/invite", formData)
    return data;
}

export const SAVE_AUTO_RECHARGE = async (params : any) => {
    const {data} = await api.post("/companies/auto/recharge", params)
    return data;
}

export const DELETE_INVITE = async (inviteId: string) => {
    const {data} = await api.delete(`/companies/invite/${inviteId}`)
    return data;
}

export const GET_ALL_COMPANIES = async () => {
    const {data} = await api.get(`/companies/all`)
    return data;
}

export const GET_BILLING_DETAILS = async () => {
    const {data} = await api.get(`/companies/billing`)
    return data;
}

export const GET_ALL_VOICES = async () => {
    const {data} = await api.get(`/companies/voices`)
    return data;
}

export const GET_ALL_INVITES = async () => {
    const {data} = await api.get("/companies/invites")
    return data.invites || [];
}

export const SEARCH_COUPON = async (code) => {
    const {data} = await api.get("/auth/search/coupon", { params: { code } })
    return data;
}

export const SAVE_COMPANY_DOMAIN = async (params: any) => {
    const {data} = await api.post("/companies/domains", params)
    return data;
}

export const SAVE_SEAT_PLAN = async (seatId: string, planId: string) => {
    const {data} = await api.patch("/companies/seat/plan", { seatId, planId })
    return data;
}

export const APPLY_COUPON = async (code: string) => {
    const {data} = await api.patch("/companies/apply/coupon", { code })
    return data;
}

export const SAVE_COMPANY_PLAN = async (params: any) => {
    const {data} = await api.post("/companies/plans/start", params)
    return data;
}

export const RECHARGE_WALLET = async (params: any) => {
    const {data} = await api.post("/companies/recharge", params)
    return data;
}

export const SAVE_COMPANY_PAYMENT_METHOD = async (params: any) => {
    const {data} = await api.patch("/companies/payment/methods", params)
    return data;
}

export const UPGRADE_SEATS_QUANTITY = async (quantity: any, planId?: string) => {
    const {data} = await api.patch("/companies/seats/quantity", { quantity, planId })
    return data;
}

export const GET_COMPANY_DOMAINS = async () => {
    const {data} = await api.get("/companies/domains")
    return data;
}

export const GET_COMPANY_INTEGRATION = async (key: string) => {
    const {data} = await api.get(`/companies/integrations/${key}`)
    return data;
}

export const SET_COMPANY_INTEGRATION = async (key: string, params: any, generates: {key: string}[] = []) => {
    const {data} = await api.post(`/companies/integrations/${key}`, {...params, generates})
    return data;
}

export const GET_COMPANY_AUTOMATIONS = async () => {
    const {data} = await api.get(`/companies/automations`)
    return data;
}

export const SAVE_COMPANY_AUTOMATIONS = async (params) => {
    const {data} = await api.post(`/companies/automations`, {...params})
    return data;
}

export const UPDATE_SELF_COMPANY = async (params) => {
    const {data} = await api.patch("/companies/self", params)
    return data;
}

export const UNSUBSCRIBE_COMPANY = async () => {
    const {data} = await api.patch("/companies/unsubscribe")
    return data;
}

export const UPDATE_COMPANY_FIELDS = async (fields = []) => {
    const {data} = await api.patch("/companies/fields", { fields })
    return data;
}

export const UPDATE_COMPANY_PREFERENCES = async (params) => {
    const {data} = await api.patch("/companies/preferences", params)
    return data;
}

export const GET_COMPANY_PREFERENCES = async () => {
    const {data} = await api.get("/companies/preferences")
    return data;
}

export const EXPORT_COMPANIES = async () => {
    const {data} = await api.post("/companies/export")
    return data;
}

export const CONSULT_LABEL_AVAILABILITY = async (label) => {
    const {data} = await api.get("/companies/label/availability", { params: { label } })
    return data;
}

export const GET_COMPANY_ONBOARDING_DATA = async () => {
    const {data} = await api.get("/companies/onboarding")
    return data;
}

export const GET_SUGGESTION_AI = async (params: any) => {
    const {data} = await api.post("/companies/suggestion", params)
    return data;
}

export const SAVE_COMPANY_ONBOARDING_DATA = async (params) => {
    const {data} = await api.post("/companies/onboarding", params)
    return data;
}

export const PUBLISH_WEBFLOW = async (params) => {
    const {data} = await api.post("/companies/webflow", params)
    return data;
}
