import { ActionIcon, Badge, Box, Button, Divider, Grid, Group, Image, Loader, Paper, Switch, Text, Title, UnstyledButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaCheck, FaLongArrowAltRight, FaTimes } from 'react-icons/fa';
import InputField, { InputFieldTypes } from '../components/input_field';
import { PaymentForm } from '../components/payment_form';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { RECHARGE_WALLET, SAVE_COMPANY_PLAN, SEARCH_COUPON } from '../services/companies';
import { GET_ALL_PLANS } from '../services/plans';
import { extenseRecurrenceType } from '../utility/util';
import { GET_STRIPE_CONFIGS, SAVE_PAYMENT_METHOD } from '../services/payments';
import { useLanguage } from '../contexts/language.context';

export default function Recharge({ onClose = null }){
    const [loadingSave, setLoadingSave] = useState(false);
    const [formData, setFormData] = useState<any>({
        credits: 50,
    });
    const [searchCoupon, setSearchCoupon] = useState<string>("");
    const [coupons, setCoupons] = useState<any>({ });

    const { startPlan, agents } = useApp();
    const { str } = useLanguage();
    const { userData, loadUserData, ip } = useAuth();

    const handleSave = async () => {
        if(formData?.payment?.method){
            setLoadingSave(true);
            if(formData?.payment.method === "new-card"){
                try{
                    const { awaz } = await GET_STRIPE_CONFIGS("awaz")
                    const stripe = require('stripe')(awaz);
                    const pm = await stripe.paymentMethods.create({
                        type: 'card',
                        card: formData?.payment?.card,
                    })
                    const method = await SAVE_PAYMENT_METHOD("awaz", pm, { email: userData.company.email, name: userData.company.name })
                    finishSave(method._id, { push: true });
                }catch(err){
                    setLoadingSave(false)
                    notifications.show({ message: err?.message ?? "Stripe not configured" });
                }
            }else{
                finishSave(formData?.payment?.method);
            }
        }else{
            notifications.show({ message: str("BILLING.CHOOSE_A_PAYMENT_METHOD") });
        }
    }

    const finishSave = (method, others = {}) => {
        setLoadingSave(true);

        RECHARGE_WALLET({ ...formData, payment: { method, ...others } })
        .then(res => {
            setLoadingSave(false);
            startPlan(false);
            if(res.payed){
                loadUserData();
            }else{
                if(res.return_url) window.open(res.return_url, "_blank");
                else notifications.show({ message: "You need to finish the payment process in your bank. Check for notifications and approve the transaction to finish", color: "yellow" })
            }
        })
        .catch(err => { 
            setLoadingSave(false)
            notifications.show({message: err.message, color: "red"})
        })
    }
    
    // useEffect(() => {
    //     if(searchCoupon.length > 3) SEARCH_COUPON(searchCoupon)
    //     .then(res => {
    //         setCoupons(c => ({...c, [searchCoupon]: res }))
    //     })
    //     .catch(err => {
    //     })
    // }, [searchCoupon]);
    
    return <Box p="md">
        <Grid>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Group>
                    <ActionIcon size="xl" variant="outline" color="gray" onClick={() => onClose && onClose()}><FaArrowLeft /></ActionIcon>
                    <Title order={3}>Checkout</Title>
                </Group>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Paper style={{borderColor: "#DFDFDF"}} p="xl">
                    <Group align="flex-start">
                        <Box style={{flex: 1}}>
                            <Title order={3}>Recharge your wallet</Title>
                            {/* <Text size="sm">{selectedPlan.description}</Text> */}
                        </Box>
                        <Box>
                            <Group align='flex-end' gap="1px">
                                <Title size="22px">USD {parseFloat(formData.credits).toFixed(2)}</Title>
                                {/* <Text size="sm">/ Monthly</Text> */}
                            </Group>
                            <InputField
                                title="Add Credits"
                                name="credits"
                                fieldType={InputFieldTypes.QUANTITY}
                                value={formData.credits}
                                onChange={({credits}) => {
                                    credits = formData.credits + ((credits - formData.credits) * 50)
                                    let minimun = 50;
                                    if(parseInt(credits) >= minimun){
                                        setFormData(fd => ({...fd, credits}));
                                    }else{
                                        setFormData(fd => ({...fd, credits: minimun}));
                                    }
                                }}
                                size="xs"
                            />
                        </Box>
                    </Group>
                </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 7 }}>
                <Paper style={{borderColor: "#DFDFDF"}} p="xl">
                    {
                        // (formData?.creits) > 0 && 
                        <>
                            <Title order={4}>Payment Method</Title>
                            <Box mt="sm">
                                <PaymentForm
                                    data={formData?.payment}
                                    methods={userData.paymentMethods}
                                    onChange={(dt) => setFormData(fd => ({
                                        ...fd,
                                        payment: { ...fd?.payment, ...dt }
                                    }))}
                                />
                            </Box>
                        </>
                    }
                </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 5 }}>
                <Paper style={{borderColor: "#DFDFDF"}} p="xl">
                    <Title order={4}>{str("TOTAL")}</Title>

                    {/* <InputField
                        title="Coupon Code"
                        name="cp"
                        mt="sm"
                        size={"md"}
                        value={searchCoupon}
                        onChange={({cp}) => { setSearchCoupon(cp) }}
                        rightSection={coupons[searchCoupon] && <FaCheck />}
                    /> */}

                    <Paper mt="md" p="md" style={{background: '#242442', borderColor: "#DFDFDF"}}>
                        {/* <Text size="xs">Credits</Text> */}
                        {/* <Text size="xs" mt="xs" c="gray">{selectedPlan.curreny ?? "USD"} {vl.toFixed(2)} x {formData.seats ?? 1} agency users</Text> */}
                        {/* <Text size="xs" mt="xs" c="gray">{formData?.credits} minutes / month</Text> */}
                        {/* <Divider c="#DFDFDF" mt="md" mb="md" /> */}
                        <Text size="xs">Total Amount</Text>
                        <Text size="xs" mt="xs" fw="bold">{"USD"} {(formData?.credits ?? 0).toFixed(2)}</Text>
                    </Paper>
                </Paper>
                
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12 }}>
                <Button loading={loadingSave} onClick={handleSave} mt="lg" variant="filled" style={{borderWidth: 1, borderColor: 'black'}} fullWidth size="lg" color="violet" rightSection={<FaLongArrowAltRight />}>Confirm</Button>
            </Grid.Col>
        </Grid>
    </Box>
}
