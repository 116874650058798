import { api } from "./api"

export const GET_SELF_PROFILE = async () => {
    const {data} = await api.get("/users/self/profile")
    return data.profile || null;
}

export const FIND_USER_BY_EMAIL = async (email: string) => {
    const {data} = await api.get("/users/search/by/email", {params: {email}})
    return data.profile || null;
}

export const GET_USER_DATA = async (id : string = undefined) => {
    const {data} = id
        ? await api.get(`/users/${id}`)
        : await api.get("/users/data")
    return data;
}

export const GET_ALL_USERS = async (others = {}) => {
    const {data} = await api.get("/users", { params: { status: 1, ...others }})
    return data.users || [];
}

export const GET_ALL_NOTIFICATIONS = async () => {
    const {data} = await api.get("/users/notifications")
    return data;
}

export const READ_NOTIFICATION = async (id: string) => {
    const {data} = await api.patch(`/users/notifications/${id}/read`)
    return data;
}

export const DELETE_USER = async (id) => {
    const {data} = await api.delete(`/users/${id}` )
    return data;
}

export const CREATE_USER = async (params : any) => {
    const {data} = await api.post(`/users`, params)
    return data;
}

export const UPDATE_USER = async (id, params : any) => {
    const {data} = await api.patch(`/users/${id}`, params)
    return data;
}

export const CHANGE_USER_STATUS = async (status) => {
    const {data} = await api.patch(`/users/status/change`, {status})
    return data;
}

export const SAVE_KEYWORDS = async (keywords : string[]) => {
    const {data} = await api.patch(`/companies/self`, {keywords})
    return data;
}

