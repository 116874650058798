import { existsSync } from 'fs';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

const availableLanguages = [
    { key: 'en-US', title: "English", icon: "/countries/us.png" },
    { key: 'pt-BR', title: "Português", icon: "/countries/br.png" },
    { key: 'de-DE', title: "Deutsch", icon: "/countries/de.png" },
    { key: 'ko-KO', title: "한국어", icon: "/countries/ko.png" },
    { key: 'es-ES', title: "Español", icon: "/countries/es.png" },
    { key: 'fr-FR', title: "Français", icon: "/countries/fr.png" },
];

interface LanguageContextProps{
    lang: string;
    setLang: (lang: string) => void;
    str: (key: string) => string;
    availableLanguages: { key: string; title: string; icon?: string }[];
}

const LanguageContext = createContext<LanguageContextProps>({} as LanguageContextProps);

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
    const localLang = localStorage.getItem("@awaz/language");
    const defaultStrings = require(`../strings/en-US.json`);
    const [lang, setLang] = useState<string>(localLang ? localLang : navigator.language);
    const [strings, setStrings] = useState({});

    const str = (key: string, isDefault = false) => {
        let res = isDefault ? defaultStrings : strings;
        // let res = isDefault ? strings : strings;
        key.split(".").forEach(k => { res = {...res}[k] })
        return res ? res.toString() : isDefault ? key : str(key, true);
    }
    
    const value = useMemo(() => ({
        lang,
        setLang,
        str,
        availableLanguages,
    }), [lang, strings])

    useEffect(() => {
        if(!availableLanguages.some(al => al.key === lang)) setLang("en-US");
    }, [lang])
    
    useEffect(() => {
        localStorage.setItem("@awaz/language", lang);
    }, [lang])
    
    useEffect(() => {
        setStrings(null);
        setTimeout(() => {
            try{
                setStrings(require(`../strings/${lang}.json`));
            }catch(err){
                console.error(err.message);
                setStrings(defaultStrings)
            }
        }, 10);
    }, [lang])

    return <LanguageContext.Provider value={value}>
        {strings && children}
    </LanguageContext.Provider>
}

export const useLanguage = () => useContext(LanguageContext);