import { ActionIcon, Divider, Group, Menu, Paper, Text, Title } from '@mantine/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/auth.context';
import Table from '../components/table';
import { notifications } from '@mantine/notifications';
import { GET_ALL_FILES } from '../services/files';
import { formatFileSize } from '../utility/util';
import { FaEllipsisV, FaEye } from 'react-icons/fa';

export default function OverviewPage(){
    const { user: currentUser, role } = useAuth();

    let [items, setItems] = useState([]);
    const [loading, setLoading] = React.useState(false);

    const loadFiles = () => {
        setLoading(true);

        GET_ALL_FILES()
        .then(res => {
            setItems(res);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            notifications.show({message: err.message, color: 'red'});
        })
    }

    useEffect(loadFiles, [])

    return <>
        <Paper p="md" shadow='xs'>
            <Title order={4} c="violet" ta='right'>Total: {formatFileSize(items.reduce((pv, r) => (pv+r.size),0))}</Title>
            <Table
                loading={loading}
                data={items}
                columns={[
                    { title: "Arquivo", key: "original_name" },
                    { title: "Tamanho", key: "size", render: item => formatFileSize(item.size) },
                    { title: " ", key: "options", render: (item) => <Menu>
                        <Menu.Target><ActionIcon><FaEllipsisV /></ActionIcon></Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item leftSection={<FaEye />} color="violet" onClick={() => window.open(item.url, "_blank")}>Abrir</Menu.Item>
                        </Menu.Dropdown>
                    </Menu> },
                ]}
            />
        </Paper>
    </>
}
