import { api } from "./api"

export const GET_ALL_TAGS = async () => {
    const {data} = await api.get("/tags/all")
    return data;
}

export const SAVE_TAG_CATEGORY = async (params: any) => {
    const {data} = await api.post("/tags/categories/save", params)
    return data;
}

export const DELETE_TAG = async (id) => {
    const {data} = await api.delete(`/tags/${id}`)
    return data;
}

export const DELETE_TAG_CATEGORY = async (id) => {
    const {data} = await api.delete(`/tags/categories/${id}`)
    return data;
}

export const SAVE_TAG = async (params: any) => {
    const {data} = await api.post("/tags/save", params)
    return data;
}

export const GET_ALL_TAGS_ROUTING = async () => {
    const {data} = await api.get("/tags/all/routing")
    return data;
}

