import React, { useEffect } from 'react';
import { FaEllipsisV, FaPen, FaPlus, FaTags, FaTimes, FaTrash } from 'react-icons/fa';
import { BiTag } from "react-icons/bi";
import SimpleHeader from '../components/simple_header';
import { ActionIcon, Badge, Button, Divider, Grid, Group, LoadingOverlay, Menu, Modal, Paper, Text, Title, UnstyledButton } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { GET_ALL_TAGS, DELETE_TAG, SAVE_TAG_CATEGORY, SAVE_TAG, DELETE_TAG_CATEGORY } from '../services/tags';
import InputField, { InputFieldTypes } from '../components/input_field';
import { useApp } from '../contexts/app.context';
import { useLanguage } from '../contexts/language.context';

export default function TagsPage() {
    const [tagModal, setTagModal] = React.useState<any>(null);
    const [categoryModal, setCategoryModal] = React.useState<any>(null);
    const [tags, setTags] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);

    const app = useApp();
    const {str} = useLanguage();

    const loadData = () => {
        setLoading(true);

        GET_ALL_TAGS()
            .then(({ tags = [], categories = [] }) => {
                setLoading(false);
                setTags(tags);
                setCategories(categories);
            })
            .catch(err => {
                setLoading(false);
                showNotification({ message: err.message, color: 'red' });
            })
    }

    const saveCategory = () => {
        SAVE_TAG_CATEGORY(categoryModal)
            .then(() => {
                setCategoryModal(null);
                loadData();
            })
            .catch(err => {
                setLoading(false);
                showNotification({ message: err.message, color: 'red' });
            })
    }

    const deleteTag = (tagId) => {
        DELETE_TAG(tagId)
            .then(() => {
                loadData();
            })
            .catch(err => {
                showNotification({ message: err.message, color: 'red' });
            })
    
        }
    const deleteTagCategory = (categoryId) => {
        DELETE_TAG_CATEGORY(categoryId)
            .then(() => {
                loadData();
            })
            .catch(err => {
                showNotification({ message: err.message, color: 'red' });
            })
    }

    const saveTag = () => {
        SAVE_TAG(tagModal)
            .then(() => {
                setTagModal(null);
                loadData();
            })
            .catch(err => {
                setLoading(false);
                showNotification({ title: "Ops", message: err.message, color: 'red' });
            })
    }

    useEffect(loadData, []);

    const TurnedTag = () => {
        return <BiTag color="white" size="1.5rem" style={{ transform: 'scaleX(-1)' }} />;
    };

    return <>
        <LoadingOverlay visible={loading} />

        <SimpleHeader Icon={TurnedTag} title={str("TAGS.TITLE")} buttons={[
            { leftSection: <FaPlus />, title: str("TAGS.ADD"), onClick: () => { setCategoryModal({}) } },
            // { leftSection: <FaPlus />, title: "Mensagem", onClick: () => { setCategoryModal({}) } },
        ]} />

        {/* <Paper shadow="xs" m="md" p="md">
            <Grid pl="xs">
                <Grid.Col md={3}>
                    <Text weight="bold" >Buscar mensagem</Text>
                    <InputField
                        fullWidth
                        name="s"
                        placeholder="Buscar mensagem"
                        autoComplete="off"
                    />
                </Grid.Col>
                <Grid.Col md={3}>
                    <Text weight="bold" >Grupo</Text>
                    <InputField
                        name="lists"
                        fieldType={InputFieldTypes.SELECT}
                    />
                </Grid.Col>
            </Grid>
        </Paper>

        <Grid mt="lg" columns={24}>
            <Grid.Col ml="xl" span={4}>
                <Text weight="bold">Categoria</Text>
            </Grid.Col>
            <Grid.Col span={3}>
                <Text weight="bold">Tag</Text>
            </Grid.Col>
            <Grid.Col span={3} offset={1}>
                <Text weight="bold">Criado Em</Text>
            </Grid.Col>
            <Grid.Col span={4} offset={1}>
                <Text weight="bold">Palavras-Chave</Text>
            </Grid.Col>
        </Grid>
        <Paper shadow="xs" m="xs">
            <Grid p="sm" columns={36}>
                <Grid.Col span={6}>
                    Outros
                </Grid.Col>
                <Grid.Col span={6}>
                    <Badge color="orange" radius="sm" variant="filled">Nome Tag</Badge>
                </Grid.Col>
                <Grid.Col ml="md" span={4}>
                    16/06/2023
                </Grid.Col>
                <Grid.Col span={15} offset={2}>
                    <Badge mr="xs" color="orange" radius="sm" variant="filled">Exemplo</Badge>
                    <Badge color="orange" radius="sm" variant="filled">Nome Tag</Badge>
                </Grid.Col>
                <Grid.Col span={2}>
                    <FaTrash style={{ color: '#5F3FBF', marginRight: '5px' }} />
                    <FaPen style={{ color: '#5F3FBF' }} />
                </Grid.Col>
            </Grid>
        </Paper> */}

        <Grid>
            {[...categories, null].map(category => <Grid.Col span={{base:12, md: 6}} key={category?._id || "null"}>
                <Paper shadow='md' p="md">
                    <Group>
                        <Title style={{flex: 1}} order={5}>{category ? category.title : str("OTHERS")}</Title>
                        <Menu>
                            <Menu.Target>
                                <ActionIcon variant='light' color="gray"><FaEllipsisV /></ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item
                                    onClick={() => {
                                        setCategoryModal(category)
                                    }}
                                >{str("EDIT")}</Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        app.confirmDialog(
                                            { text: str("TAGS.DELETE_CONFIRM") },
                                            (({ confirmed }) => {
                                                if (confirmed) deleteTagCategory(category._id);
                                            })
                                        )
                                    }}
                                >{str("DELETE")}</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                    <Divider mt="md" mb="md" />
                    {tags.filter(t => t.category === category?._id).map(t => (
                        <Menu>
                            <Menu.Target>
                                <UnstyledButton>
                                    <Badge rightSection={<FaEllipsisV />} key={t._id} mr="xs" mb="xs" color="violet">
                                        <Group>
                                            {t.title}
                                            {/* <ActionIcon color="gray" size="xs"></ActionIcon> */}
                                        </Group>
                                    </Badge>
                                </UnstyledButton>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item
                                    onClick={() => {
                                        setTagModal(t)
                                    }}
                                >{str("EDIT")}</Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        app.confirmDialog(
                                            { text: str("TAGS.DELETE_CONFIRM") },
                                            (({ confirmed }) => {
                                                if (confirmed) deleteTag(t._id);
                                            })
                                        )
                                    }}
                                >{str("DELETE")}</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    ))}
                    <Group mt="md" justify='flex-end'>
                        <Button size="xs" variant="light" onClick={() => setTagModal({ category: category?._id })} leftSection={<FaPlus />}>{str("ADD")}</Button>
                    </Group>
                </Paper>
            </Grid.Col>)}
        </Grid>

        <Modal opened={!!categoryModal} onClose={() => setCategoryModal(null)}>
            <InputField
                name="title"
                title={str("TAGS.CATEGORY")}
                value={categoryModal?.title}
                onChange={({ title }) => setCategoryModal(m => ({ ...m, title }))}
            />
            <Group justify='flex-end' mt="xl"><Button onClick={saveCategory} variant='filled'>{str("SAVE")}</Button></Group>
        </Modal>

        <Modal opened={!!tagModal} onClose={() => setTagModal(null)}>
            <InputField
                name="title"
                title="Tag Title"
                value={tagModal?.title}
                onChange={({ title }) => setTagModal(m => ({ ...m, title }))}
            />
            <Group justify='flex-end' mt="xl"><Button onClick={saveTag} variant='filled'>{str("SAVE")}</Button></Group>
        </Modal>
    </>
}
