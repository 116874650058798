import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  Paper,
  Tabs,
  Text,
  Title,
  Tooltip,
  UnstyledButton
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useRef, useState } from "react";
import {
  FaCheck,
  FaPhone,
  FaTrash
} from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import ActionForm from "../components/agents/action_form";
import Actions from "../components/agents/actions";
import AddActions from "../components/agents/add_actions";
import OthersPrompt from "../components/agents/others_prompt";
import OthersSettings from "../components/agents/others_settings";
import OthersVariables from "../components/agents/others_variables";
import Prompt from "../components/agents/prompt";
import Settings from "../components/agents/settings";
import Variables from "../components/agents/variables";
import AudioPlayer from "../components/audio_player";
import InputField, { InputFieldTypes } from "../components/input_field";
import useCallAgent from "../components/use_call_agent";
import { useAgentVariables, useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import { useLanguage } from "../contexts/language.context";
import { DELETE_AGENT, GET_AGENT_DETAILS, SAVE_AGENT } from "../services/agents";
import { GET_ALL_CALENDARS } from "../services/calendars";
import { TRIGGER_CALL } from "../services/calls";
import { GET_ALL_VOICES } from "../services/companies";
import { GET_ALL_KNOWLEDGES } from "../services/knowledges";
import { GET_ALL_PHONES, GET_ALL_WHATSAPPS } from "../services/phones";
import { GET_ALL_TAGS } from "../services/tags";
import { retellVoices } from "../utility/util";

export default function Agent({ }) {
  const { user: currentUser } = useAuth();
  const { userData } = useAuth();

  const navigate = useNavigate();

  const app = useApp();
  const { str } = useLanguage();
  const updateRef = useRef(false);

  const { id: agentId } = useParams();
  
  const utf8 = require("utf8");

  const [newCall, setNewCall] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [action, setAction] = useState<any>(null);
  const [contacts, setContacts] = useState<any>([]);
  const [voice, setVoice] = useState<any>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [tags, setTags] = useState<any[]>([]);
  const [knowledges, setKnowledges] = useState<any[]>([]);
  const [voices, setVoices] = useState<any[]>([]);
  const [contactName, setContactName] = useState("");
  const [loadingDelete, setLoadingDelete] = React.useState({});
  const [loadingCall, setLoadingCall] = React.useState(false);
  const [playingTest, setPlayingTest] = React.useState(false);
  const [someUpdate, setSomeUpdate] = React.useState(false);
  const [phones, setPhones] = useState<any[]>([]);
  const [calendars, setCalendars] = React.useState<any[]>([]);
  const [selectedTab, setSelectedTab] = React.useState<string>("settings");

  const companyVariables = useAgentVariables();

  const [whatsapps, setWhatsapps] = useState<any[]>([]);
  const [loadingWhatsapps, setLoadingWhatsapps] = useState<boolean>(false);
  
  const [playing, tooglePlay] = useCallAgent({ own: !!data?.data?.own });

  const actionOptions = [
    {
      title: str("AGENTS.ACTIONS_OPTIONS.END_CALL_TITLE"),
      subtitle: str("AGENTS.ACTIONS_OPTIONS.END_CALL_SUBTITLE"),
      type: "end_call",
      description: str("AGENTS.ACTIONS_OPTIONS.END_CALL_DESCRIPTION"),
    },
    {
      title: str("AGENTS.ACTIONS_OPTIONS.TRANSFER_A_CALL_TITLE"),
      subtitle: str("AGENTS.ACTIONS_OPTIONS.TRANSFER_A_CALL_SUBTITLE"),
      type: "transfer_call",
      description: str("AGENTS.ACTIONS_OPTIONS.TRANSFER_A_CALL_DESCRIPTION"),
    },
    {
      title: str("AGENTS.ACTIONS_OPTIONS.SEND_SMS_TITLE"),
      subtitle: str("AGENTS.ACTIONS_OPTIONS.SEND_SMS_SUBTITLE"),
      type: "send_sms",
      description: str("AGENTS.ACTIONS_OPTIONS.SEND_SMS_DESCRIPTION"),
    },
    {
      title: str("AGENTS.ACTIONS_OPTIONS.INTEGRATION_TITLE"),
      subtitle: str("AGENTS.ACTIONS_OPTIONS.INTEGRATION_SUBTITLE"),
      type: "integration",
      description: "",
    },
    {
      title: str("AGENTS.ACTIONS_OPTIONS.BOOK_APPOINTMENT_TITLE"),
      subtitle: str("AGENTS.ACTIONS_OPTIONS.BOOK_APPOINTMENT_SUBTITLE"),
      type: "book_appointment",
      description: str("AGENTS.ACTIONS_OPTIONS.BOOK_APPOINTMENT_DESCRIPTION"),
    },
    {
      title: str("AGENTS.ACTIONS_OPTIONS.CALL_WEBHOOK_TITLE"),
      subtitle: str("AGENTS.ACTIONS_OPTIONS.CALL_WEBHOOK_SUBTITLE"),
      type: "call_webhook",
      description: str("AGENTS.ACTIONS_OPTIONS.CALL_WEBHOOK_DESCRIPTION"),
    },
    {
      title: str("AGENTS.ACTIONS_OPTIONS.UNSUBSCRIBE_TITLE"),
      subtitle: str("AGENTS.ACTIONS_OPTIONS.UNSUBSCRIBE_SUBTITLE"),
      type: "unsubscribe",
      description: str("AGENTS.ACTIONS_OPTIONS.UNSUBSCRIBE_DESCRIPTION"),
    },
    {
      title: str("AGENTS.ACTIONS_OPTIONS.SCHEDULE_A_CALL_TITLE"),
      subtitle: str("AGENTS.ACTIONS_OPTIONS.SCHEDULE_A_CALL_SUBTITLE"),
      type: "schedule_a_call",
      description: str("AGENTS.ACTIONS_OPTIONS.SCHEDULE_A_CALL_DESCRIPTION"),
    },
    {
      title: str("AGENTS.ACTIONS_OPTIONS.PRESS_DIGIT_TITLE"),
      subtitle: str("AGENTS.ACTIONS_OPTIONS.PRESS_DIGIT_SUBTITLE"),
      type: "press_digit",
      description: str("AGENTS.ACTIONS_OPTIONS.PRESS_DIGIT_DESCRIPTION"),
    },
  ].filter(nn => nn);

  const loadPhones = () => {
    GET_ALL_PHONES()
    .then((res) => {
      setPhones(res)
    })
    .catch(err => {
        notifications.show({title: "Ops.", message: err.message, color: 'red'})
    })
  }

  const loadCalendars = () => {
    GET_ALL_CALENDARS()
    .then(cs => {
      setCalendars(cs)
    })
    .catch(err => {
      notifications.show({message: err.message, color: "red"})
    })
  }

  const loadData = (setLoading = true) => {
    if (agentId) {
      setLoadingData(setLoading);
      GET_AGENT_DETAILS(agentId)
      .then((dt) => {
        setLoadingData(false);
        setData(dt);
        setTimeout(() => updateRef.current = true, 1000);
      })
      .catch((err) => {
        setLoadingData(false);
        notifications.show({ title: "Ops", message: err.message, color: "red",});
      });
    }
  };

  const loadTags = () => {
    GET_ALL_TAGS()
    .then(({tags = [], categories = []}) => {
      setTags(tags.map(t => ({...t, category: categories.find(c => c._id === t.category)})));
    })
    .catch(err => {
      notifications.show({ message: err.message, color: "red" })
    })
  }
  
  const loadKnowledges = () => {
    GET_ALL_KNOWLEDGES()
    .then(res => {
      setKnowledges(res);
    })
    .catch(err => {
        notifications.show({ message: err.message, color: "red" })
    })
  }

  const loadWhatsapps = () => {
    setLoadingWhatsapps(true);
    GET_ALL_WHATSAPPS()
    .then((res) => {
      setLoadingWhatsapps(false);
      setWhatsapps(res.filter(wpp => wpp?.configured))
    })
    .catch(err => {
        setLoadingWhatsapps(false);
        notifications.show({title: "Ops.", message: err.message, color: 'red'})
    })
  }
  
  const loadVoices = () => {
    GET_ALL_VOICES()
    .then(res => {
      setVoices(res ?? []);
    })
    .catch(err => {
        notifications.show({ message: err.message, color: "red" })
    })
  }
  
  const handleCall = () => {
    setLoadingCall(true)
    TRIGGER_CALL({...newCall, agent: agentId})
    .then((dt) => {
      setLoadingCall(false)
      setNewCall(null);
    })
    .catch((err) => {
      setLoadingCall(false)
      notifications.show({ title: "Ops", message: err.message, color: "red" });
    });
  };

  const previewVoice = (url) => {
    let audio = new Audio(url);
    audio.play();
    setPlayingTest(true);
    audio.addEventListener("ended", function(){
      audio.currentTime = 0;
      setPlayingTest(false);
    });
  }
  
  const playTestVoice = () => {
    let audio = new Audio(`${process.env.REACT_APP_SERVER_URL}/auth/text/to/speech?text=${voice.testing}&voice_id=${voice.voice_id}`);
    audio.play();
    setPlayingTest(true);
    audio.addEventListener("ended", function(){
      audio.currentTime = 0;
      setPlayingTest(false);
    });
  }

  const handleSaveAgent = () => {
    if (agentId) {
      setLoadingSave(true);
      const params = {
        _id: agentId,
        ...data,
      };

      SAVE_AGENT(params)
      .then((dt) => {
        setLoadingSave(false);
        setSomeUpdate(false);
        updateRef.current = false;
        loadData();
        // notifications.show({ message: "Agent updated", color: "green" });
      })
      .catch((err) => {
        setLoadingSave(false);
        notifications.show({ title: "Ops", message: err.message, color: "red" });
      });
    }
  };

  useEffect(() => {
    setSomeUpdate(false);
    updateRef.current = false;
    loadData();
  }, [agentId]);

  useEffect(() => {
    loadPhones();
    loadCalendars();
    loadTags();
    // loadKnowledges();
    loadWhatsapps();
    // loadVoices();
  }, []);

  useEffect(() => {
    if(updateRef.current) setSomeUpdate(true);
  }, [data]);

  return (
    <div>
      <LoadingOverlay visible={loadingData} />

      <Grid>
        <Grid.Col span={{base: 12, md: 8}}>
          <Paper>
            <Group p="lg">
              <Box style={{flex: 1}}>
                <Title order={3}>{data?.title}</Title>
                <Badge>{data?._id}</Badge>
              </Box>
              {userData?.user?.isAdmin && <Tooltip label="Template">
                <ActionIcon
                  variant={data?.template ? "filled" : "outline"}
                  color={data?.template ? "green" : "gray"}
                  onClick={() => setData(d => ({...d, template: !d?.template}))}
                ><FaCheck /></ActionIcon>
              </Tooltip>}
              <ActionIcon
                variant="outline"
                color="gray"
                size="md"
                onClick={() => {
                  app.confirmDialog(
                    {
                      text: "Are you sure about deleting this agent?",
                    },
                    ({ confirmed }) => {
                      if (confirmed){
                        DELETE_AGENT(data?._id);
                        navigate("/agents");
                      }
                    }
                  );
                }}
              ><FaTrash/></ActionIcon>
            </Group>
            <Tabs value={selectedTab ?? "general"} onChange={setSelectedTab}>
              <Tabs.List>
                  <Tabs.Tab value="settings">{str('SETTINGS')}</Tabs.Tab>
                  <Tabs.Tab value="prompt">{str('PROMPT')}</Tabs.Tab>
                  <Tabs.Tab value="actions">{str('ACTIONS')}</Tabs.Tab>
                  <Tabs.Tab value="variables">{str('VARIABLES')}</Tabs.Tab>
              </Tabs.List>
            </Tabs>
            {selectedTab === "settings" && <Settings data={data} setData={setData} setVoice={setVoice} />}

            {selectedTab === "prompt" && <Prompt data={data} setData={setData} companyVariables={companyVariables} />}

            {selectedTab === "variables" && <Variables data={data} setData={setData} companyVariables={companyVariables} />}

            {selectedTab === "actions" && <AddActions onSelect={setAction} whatsapps={whatsapps} calendars={calendars} actionOptions={actionOptions} />}
          </Paper>
        </Grid.Col>
        <Grid.Col span={{base: 12, md: 4}}>
          
          {selectedTab === "settings" && <Paper p="md" mb="md">
            <OthersSettings data={data} setData={setData} onUpdateAction={setAction} />
          </Paper>}
          
          {selectedTab === "variables" && <Paper p="md" mb="md">
            <OthersVariables data={data} setData={setData} />
          </Paper>}
          
          {selectedTab === "prompt" && <Paper p="md" mb="md">
            <OthersPrompt data={data} setData={setData} tags={tags} loadTags={loadTags} />
          </Paper>}
          
          {selectedTab === "actions" && <Paper p="md" mb="md">
            <Actions data={data} setData={setData} onUpdateAction={setAction} actionOptions={actionOptions} />
          </Paper>}

          <Paper mb="md">
            <Group p="md">
              <FaPhone />
              <Text size="lg" fw="bold" style={{flex: 1}}>{str("AGENTS.TEST_YOUR_AGENT")}</Text>
            </Group>
            <Divider />
            {
              someUpdate
              ? <Button
                size="xl"
                fullWidth
                onClick={handleSaveAgent}
                loading={loadingSave}
              >{str("SAVE_UPDATES")}</Button>
              : <Group>
                <Button
                  disabled={!data?.props?.retell_agent_id}
                  // leftSection={playing ? <FaStop /> : <FaPlay />}
                  onClick={() => setNewCall({ })}
                  variant="transparent"
                  color="white"
                  size="md"
                  ml="sm"
                  mt="xs"
                  mb="xs"
                  style={{flex: 1}}
                >{str("AGENTS.PHONE_CALL").toUpperCase()}</Button>
                <Divider orientation="vertical" />
                <Button
                  disabled={!data?.props?.retell_agent_id}
                  // leftSection={playing ? <FaStop /> : <FaPlay />}
                  onClick={() => tooglePlay({ agentId: data._id })}
                  variant="transparent"
                  color="white"
                  size="md"
                  mr="sm"
                  mt="xs"
                  mb="xs"
                  style={{flex: 1}}
                >{playing ? str('STOP_CALL').toUpperCase() : str('WEB_CALL').toUpperCase()}</Button>
              </Group>
            }
          </Paper>
        </Grid.Col>
      </Grid>
      
      <Modal opened={!!action} onClose={() => setAction(null)} size="lg">
        <ActionForm
          data={action}
          setData={setAction}
          calendars={calendars}
          loadCalendars={loadCalendars}
          loadWhatsapps={loadWhatsapps}
          whatsapps={whatsapps}
        />
        <Group justify="flex-end" mt="lg">
          <Button onClick={() => {
            setData(d => ({
              ...d, data: {
                ...d.data,
                actions: [undefined, null].includes(action.index)
                ? [
                  ...(d?.data?.actions ?? []),
                  {
                    ...action,
                    index: (d?.data?.actions ?? []).length,
                  }
                ]
                : (d?.data?.actions ?? []).map((act, i) => (
                  action.index === i
                  ? { ...act, ...action }
                  : act
                ))
              }
            }))
            setAction(null);
          }}>{str("SAVE")}</Button> 
        </Group>
      </Modal>

      <Modal opened={!!newCall} onClose={() => setNewCall(null)} size="sm">
        <InputField
          name="from"
          title={str("AGENTS.PHONE_NUMBER_FROM")}
          mb="md"
          value={newCall?.from}
          fieldType={InputFieldTypes.SELECT}
          options={phones
            .filter(p => (
              p.type === "sip" ||
              ["in-use", "success"].includes(p?.data?.twilio?.status)
            ))
            .map(p => ({ label: p.number, value: p._id }))}
          onChange={({from}) => setNewCall(na => ({ ...na, from }))}
        />

        {/* <InputField
          value={newCall?.contact}
          name="contact"
          mb="md"
          title="Contact"
          fieldType={InputFieldTypes.SELECT}
          onChange={({contact}) => setNewCall(nc => ({...nc, contact}))}
          options={contacts.map(c => ({value: c._id, label: c.name}))}
        /> */}
        <InputField
          name="contact"
          title={str("CONTACTS.TITLE")}
          mt="md"
          value={newCall?.contact}
          onChange={({contact}) => {
            setNewCall(nc => ({...nc, contact }));
          }}
          fieldType={InputFieldTypes.SEARCH}
          searchType="contact"
        />
        <Button mt="md" loading={loadingCall} onClick={handleCall}>{str("CALL_NOW")}</Button>
      </Modal>

      <Modal
        opened={!!voice}
        onClose={() => setVoice(null)}
        size="lg"
      >
        {retellVoices.map(rv => <>
          <Paper p="xs">
            <Group>
              <AudioPlayer url={rv.preview_audio_url} />
              <Box style={{flex: 1}}>
                <Text>{rv.voice_name}</Text>
                <Badge size="xs" variant="outline" color={rv.gender === "female" ? "pink" : "blue"}>{rv.gender}</Badge>
              </Box>
              <UnstyledButton
                onClick={() => {
                  setVoice(null);
                  setData(na => ({...na, data: { ...na.data, retell: {
                    ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, voice_id: rv.voice_id } } } }));
                }}
              >
                <Text size="xs">{str("AGENTS.APPLY_THIS_VOICE")}</Text>
              </UnstyledButton>
            </Group>
          </Paper>
          <Divider mt="xs" mb="xs" />
        </>)}
      </Modal>
    </div>
  );
}
