import { api } from "./api";

export const GET_CONTACTS = async () => {
  const { data } = await api.get("/contacts/all");
  return data.contacts || [];
};

export const GET_CONTACT = async (id) => {
  const { data } = await api.get(`/contacts/${id}/details`);
  return data;
};

export const GET_CONTACT_LIST = async (id) => {
  const { data } = await api.get(`/contacts/list/${id}/details`);
  return data;
};

export const SEARCH_CONTACT = async (keyword) => {
  const { data } = await api.get(`/contacts/search/by/keyword?keyword=${keyword}`);
  return data;
};

export const GET_CONTACT_LIST_CONTACTS = async (id) => {
  const { data } = await api.get(`/contacts/list/${id}/contacts`);
  return data;
};

export const GET_ALL_CONTACTS_LIST = async () => {
  const { data } = await api.get(`/contacts/list/all`);
  return data.lists || [];
};

export const SAVE_CONTACT = async (params) => {
  const { data } = await api.post("/contacts/save", params);
  return data;
};

export const SAVE_CONTACT_LIST = async (params) => {
  const { data } = await api.post("/contacts/list/save", params);
  return data;
};

export const UPDATE_CONTACT = async (params, id) => {
  const { data } = await api.put(`/contacts/${id}/update`, params);
  return data;
};

export const DELETE_CONTACT = async (id) => {
  const { data } = await api.delete(`/contacts/${id}`);
  return data;
};

export const UPDATE_CONTACT_LIST = async (params, id) => {
  const { data } = await api.put(`/contacts/list/${id}/update`, params);
  return data;
};

export const DELETE_CONTACT_LIST = async (id) => {
  const { data } = await api.delete(`/contacts/list/${id}`);
  return data;
};

export const DELETE_CONTACT_FROM_LIST = async (contactId, listId) => {
  const { data } = await api.delete(`/contacts/${contactId}/list/${listId}`);
  return data;
};

