import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid, Group, Image, Paper,
  Popover,
  Progress,
  Switch,
  Text,
  Title, Tooltip, UnstyledButton
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications, showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { FaCheck, FaCheckCircle, FaChevronLeft, FaInfoCircle, FaTimes, FaTimesCircle } from "react-icons/fa";
import { Link, Location, Navigate, useLocation, useNavigate } from "react-router-dom";
import InputField, { InputFieldTypes } from "../components/input_field";
import { useAuth } from "../contexts/auth.context";
import { api } from "../services/api";
import { extenseRecurrenceType, phoneCountryCodes, useQuery } from "../utility/util";
import { GET_ALL_PLANS } from "../services/plans";
import { PaymentForm } from "../components/payment_form";
import { useApp } from "../contexts/app.context";
import { SUBSCRIBE } from "../services/auth";
import RenderImage from "../components/render_image";
import { GET_STRIPE_CONFIGS, SAVE_PAYMENT_METHOD } from "../services/payments";
import { useLanguage } from "../contexts/language.context";
import { SEARCH_COUPON } from "../services/companies";

export default function Signup({ order = null, service = null, customer = false, shareLinkId = null, partner = false }){
  const { user, signIn, companyHost, ip } = useAuth();
  const { str } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 900px)');
  const [selectedRecurrencyType, setSelectedRecurrencyType] = useState<string>("year");
  const [agree, setAgree] = useState<boolean>(false);

  if(companyHost){
    if(companyHost?.fastGeneration) customer = false;
    else customer = true;
  }

  const [formData, setFormData] = useState<any>({
    company: {
      type: "1",
      employees: "1"
    }
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [steps, setSteps] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);
  const [coupons, setCoupons] = useState<any>({});

  const selectedPlan = plans.find(p => p._id === formData?.plan);

  const state = location.state as { from: Location };
  const from = state ? `${state.from.pathname}?signed=true` : "/?signed=true";

  const query = useQuery();
  const queryMail = query.get("email");
  const referral = query.get("referral") ?? "";

  const validTab = customer
  ? [
    service ? () => true : null,
    () => formData?.general?.name &&
      formData?.general?.email &&
      // formData?.general?.register_number &&
      formData?.general?.phone_code &&
      formData?.general?.phone &&
      formData?.general?.password
  ].filter(nn => nn)
  : [
    () => formData?.general?.name &&
      formData?.general?.email &&
      // formData?.general?.register_number &&
      formData?.general?.phone &&
      formData?.general?.phone_code &&
      formData?.general?.password,
    () => formData?.company?.name && formData?.company?.type && formData?.company?.employees,
    partner ? () => formData?.plan : () => true,
    partner ? () => formData?.coupon?.length > 3 : () => {
      return formData?.payment?.card &&
        formData?.payment?.card?.number &&
        formData?.payment?.card?.exp_month &&
        formData?.payment?.card?.exp_year &&
        formData?.payment?.card?.cvc
    },
  ].filter(nn => nn);

  const signup = async () => {
    if(customer){
      finishSignup();
    }else{
      if(agree){
        setLoading(true) 
        if(formData?.payment?.method === "new-card"){
          try{
            const { awaz } = await GET_STRIPE_CONFIGS("awaz")
            const stripe = require('stripe')(awaz);
            const pm = await stripe.paymentMethods.create({
                type: 'card',
                card: formData?.payment?.card,
            })
            const method = await SAVE_PAYMENT_METHOD("awaz", pm, { email: formData?.general?.email, name: formData?.general?.name })
            finishSignup(method._id, { push: true });
          }catch(err){
            setLoading(false)    
            notifications.show({ message: err?.message ?? "Stripe not configured" });
          }
        }else{
          finishSignup(formData?.payment?.method);
        }
      }else{
        notifications.show({ message: "To continue you need to agree with Terms of Service and Refund Policy", color: "yellow" })
      }
    }
  }

  const finishSignup = (method = null, others = {}) => {
    setLoading(true)    
    
    api.post(
      customer ? `/auth/signup` : `/auth/signup/company`,
      customer
      ? { general: formData?.general, custom: formData?.custom, job: order?._id, service: service?._id }
      : {
        ...formData, 
        payment: { method, ...others },
        referral, 
        share_link: shareLinkId, 
        existentCompany: companyHost?.fastGeneration ? companyHost.id : null
      }
    )
    .then(({data : {user}}) => {
      if(selectedPlan){
        try{
          (window as any).fbq('track', 'Purchase', {
            ip,
            name: formData?.general?.name,
            email: formData?.general?.email,
            phone: `+${formData?.general?.phone_code}${formData?.general?.phone.replace(/\(|\)|\-|\.| /g, "")}`,
            plan: selectedPlan?.title,
            currency: "USD",
            value: selectedPlan?.price
          });
        }catch(ignored){}
        try{
          (window as any).ttq.track('Purchase', {
            content_type: "subscription",
            name: formData?.general?.name,
            email: formData?.general?.email,
            phone: `+${formData?.general?.phone_code}${formData?.general?.phone.replace(/\(|\)|\-|\.| /g, "")}`,
            plan: selectedPlan?.title,
            currency: "USD",
            value: selectedPlan?.price
          });
        }catch(ignored){}
        try{
          (window as any).gtag('event', 'purchase', {
            event_name: "purchase",
            name: formData?.general?.name,
            email: formData?.general?.email,
            phone: `+${formData?.general?.phone_code}${formData?.general?.phone.replace(/\(|\)|\-|\.| /g, "")}`,
            plan: selectedPlan?.title,
            currency: "USD",
            value: selectedPlan?.price
          })
        }catch(ignored){}
      }
      try{
        let wd : any = window;
        let rewardful = wd.rewardful;
        rewardful('convert', { email: formData?.general?.email });
      }catch(ignored){ console.log({ ignored }) }

      setLoading(false);
      if(customer){
        signIn(user, () => navigate(
          order ? `/orders/${order._id}` : service ? `/?service=${service._id}` : "/",
          { replace: true }
        ))
      }else{
        signIn(user, () => navigate("/thank-you", { replace: true }));
      }
    })
    .catch(err => {
      setLoading(false);
      notifications.show({title: "Ops", message: err.message, color: 'red'})
    })
  }

  const navigateToTab = index => {
    if(steps[index-1]?.onNext) steps[index-1]?.onNext();

    if(index === steps.length) signup();
    else setSelectedTab(index);
  }

  const handleChangeGeneral = dt => setFormData(fd => ({...fd, general: {...fd?.general, ...dt}}))
  const handleChangeCompany = dt => setFormData(fd => ({...fd, company: {...fd?.company, ...dt}}))
  const handleChangeCustom = dt => setFormData(fd => ({ ...fd, custom: {...fd?.custom, ...dt} }))
  // const handleChangeOthers = dt => setFormData(fd => ({...fd, others: {...fd?.others, ...dt}}))

  useEffect(() => {
    if(companyHost?.fastGeneration) setFormData(fd => ({
      ...fd,
      general: {
        ...fd?.general,
        name: companyHost.name,
      },
      company: {
        ...fd?.company,
        name: companyHost.name,
      },
    }))
  }, [companyHost]);
  
  useEffect(() => {
    handleChangeGeneral({email: queryMail})
  }, [queryMail]);

  useEffect(() => {
    GET_ALL_PLANS()
    .then(res => {
      setPlans(res);
    })
    .catch((error) => {
      notifications.show({message: error.message, color: 'red'})
    })
  }, [])
  
  useEffect(() => {
    let pls = plans.sort((a,b) => a.recurrence_type === "year" && b.recurrence_type !== "year" ? -1 : 1)
    setFormData(fd => ({ ...fd, plan: pls[0]?._id }))
  }, [plans])
  
  const ServiceStep = service && <Box>
    <RenderImage src={service?.cover_image} width="100%" />

    {
      order
      ? <Text mt="xs" size="md" fw="bold" c="violet">{order.currency} {parseFloat(order.total_price).toFixed(2)}</Text>
      : (service.pricing_structure === "custom" || service.pricing_table)
      ? <Text mt="xs" size="md" fw="bold" c="violet">On Demand ({service.currency})</Text>
      : <Text mt="xs" size="md">From {service.currency} {parseFloat(service.unity_price ?? 0).toFixed(2)}</Text>
    }
    <Text c="gray" size="xs">{service.plan_type === "subscription" ? extenseRecurrenceType(service.recurrence_type) : "One-time"}</Text>
    <Text mt="xs" size="sm">{(service.description ?? "").split("\n").map((d) => <div style={{minHeight: 14}}>{d}</div>)}</Text>
  </Box>

  const FirstStep = <Paper p="md">
    <InputField
      title={str("SIGNUP.BRAND_NAME")}
      mb="lg"
      name="name"
      value={formData?.company?.name}
      onChange={handleChangeCompany}
    />
    <InputField
      mb="lg"
      name="type"
      value={formData?.company?.type}
      fieldType={InputFieldTypes.BOX}
      onChange={handleChangeCompany}
      options={[
        {label: str("SIGNUP.PERSONAL_ACCOUNT"), value: "1"},
        {label: str("SIGNUP.BUSINESS_ACCOUNT"), value: "2"},
      ]}
    />
    <InputField
      title={str("SIGNUP.NUMBER_OF_COLLABORATORS")}
      mb="lg"
      name="employees"
      value={formData?.company?.employees}
      fieldType={InputFieldTypes.BOX}
      onChange={handleChangeCompany}
      options={[
        {label: "1", value: "1"},
        {label: "2-10", value: "2-10"},
        {label: "11-50", value: "11-50"},
        {label: "50+", value: "50+"},
      ]}
    />
  </Paper>;
  const SeccondStep = <Paper p="md">
    <InputField
      title={str("YOUR_NAME")}
      mb="lg"
      autoComplete="off"
      name="name"
      value={formData?.general?.name}
      onChange={handleChangeGeneral}
    />
    <InputField
      title={str("EMAIL")}
      mb="lg"
      name="email"
      autoCapitalize="off"
      value={formData?.general?.email}
      onChange={handleChangeGeneral}
    />
    <Group style={isMobile ? {display: 'block'} : {}}>
      <InputField
        style={isMobile ? {} : {flex: 0.3}}
        title={str("COUNTRY")}
        mb="lg"
        name="phone_code"
        autoComplete="tel-country-code"
        fieldType={InputFieldTypes.SELECT}
        value={formData?.general?.phone_code}
        onChange={handleChangeGeneral}
        nothingFound="No country code found"
        options={phoneCountryCodes}
      />
      <InputField
        style={isMobile ? {} : {flex: 0.7}}
        title={str("PHONE")}
        mb="lg"
        name="phone"
        value={formData?.general?.phone}
        onChange={handleChangeGeneral}
      />
    </Group>
    {customer && (companyHost?.customFields || [])
      .filter(field => field.title)
      .map(field => (
          <InputField mb="md" name={field._id} value={{...formData?.custom}[field._id]} onChange={handleChangeCustom} title={field.title} />
    ))}
    <InputField
      title={str("PASSWORD")}
      mb="lg"
      name="password"
      fieldType={InputFieldTypes.PASSWORD}
      value={formData?.general?.password}
      onChange={handleChangeGeneral}
    />
  </Paper>;
  const ThirdStep = partner
  ? <Box>
    <Title order={3} mt="md">Insert your Appsumo Code to start</Title>
    
    <InputField
      title="Appsumo Code"
      mt="lg"
      mb="lg"
      name="coupon"
      value={formData?.coupon}
      onChange={({coupon}) => setFormData(fd => ({...fd, coupon}))}
      rightSection={formData?.coupon?.length > 3 ? coupons[formData?.coupon] ? <FaCheck /> : <FaTimesCircle /> : null}
    />
  </Box>
  : <Box>
    <Box>
      {/* <UnstyledButton mb="md" style={{width: '100%'}} onClick={() => {
          setFormData(fd => ({...fd, plan: null}))
      }}>
          <Paper shadow='xs' p="md" style={{border: !formData?.plan ? `3px solid #FF4D17` : '1px solid black'}}>
              <Title order={3} mt="sm">Free Plan (Limited Resources)</Title>
          </Paper>
      </UnstyledButton> */}

      <Group pb="md" mb="md" style={{justifyContent: 'center'}} align='center'>
        <Group gap={5} style={{borderRadius: 40, padding: '10px', border: '1px solid #DFDFDF'}}>
          <Button
            style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "month" ? '1px solid #DFDFDF' : 0 }}
            color="violet"
            onClick={() => setSelectedRecurrencyType("month")}
            variant={selectedRecurrencyType === "month" ? "filled" : "light"} size="xs">{str("RECURRENCES.MONTH")}</Button>
          <Button
            style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "year" ? '1px solid #DFDFDF' : 0 }}
            color="violet"
            onClick={() => setSelectedRecurrencyType("year")}
            variant={selectedRecurrencyType === "year" ? "filled" : "light"} size="xs">{str("RECURRENCES.YEAR")}</Button>
        </Group>
      </Group>

      <Grid>
          {plans
          .filter(p => (p.recurrence_type ?? "month") === selectedRecurrencyType)
          .map(plan => (
            <Grid.Col span={{base: 12, md: 12}}>
              <UnstyledButton mb="md" style={{width: '100%'}} onClick={() => {
                  setFormData(fd => ({...fd, plan: plan._id}))
              }}>
                <Paper shadow='xs' p="md" style={{border: selectedPlan?._id === plan._id ? `3px solid #FF4D17` : '1px solid black'}}>
                  <Title order={4}>{plan.title}</Title>
                  <Text c="gray" size="xl">{"USD"} {parseFloat(plan.price).toFixed(2)}</Text>
                  <Text c="gray" size="xs">{str(`RECURRENCES.${plan.recurrence_type}`.toUpperCase())}</Text>
                  <Text size="sm" mt="md">{plan.description}</Text>
                  {/* <Group align='center' justify='flex-end' mt="xs">
                    {(plan.service.badges ?? []).map(s => ( <Badge variant='filled' c={s.textColor} color={s.color}>{s.text}</Badge> ))}
                  </Group> */}
                </Paper>
              </UnstyledButton>
            </Grid.Col>
          ))}
      </Grid>
    </Box>
  </Box>;
  const FourthStep = <Box>
    <Grid>
      <Grid.Col span={{ base: 12, md: 12 }}>
          <Paper style={{borderColor: "#DFDFDF"}} p="xl">
              <Group align="flex-start">
                  <Box style={{flex: 1}}>
                      <Title order={3}>{selectedPlan?.title}</Title>
                      <Text size="sm">{selectedPlan?.description}</Text>
                  </Box>
                  <Box>
                      <Group align='flex-end' gap="1px">
                          <Title size="22px">{"USD"} {parseFloat(selectedPlan?.price).toFixed(2)}</Title>
                          <Text size="sm">/ {(selectedPlan?.recurrence_type ?? "month")}</Text>
                      </Group>
                      <InputField
                        title={str("COUPON_CODE")}
                        mt="lg"
                        mb="lg"
                        name="coupon"
                        value={formData?.coupon}
                        onChange={({coupon}) => setFormData(fd => ({...fd, coupon}))}
                        rightSection={formData?.coupon?.length > 3 ? coupons[formData?.coupon] ? <FaCheck /> : <FaTimesCircle /> : null}
                      />
                      {/* <Text mb="md" c="gray" size="sm">Per user</Text>
                      <InputField
                          title="Add Users"
                          name="seats"
                          fieldType={InputFieldTypes.QUANTITY}
                          value={formData.seats}
                          onChange={({seats}) => {
                              let minimun = (planData?.seats || []).length;
                              minimun = (minimun > 1 ? minimun : 1);
                              if(parseInt(seats) >= minimun){
                                  setFormData(fd => ({...fd, seats}));
                              }
                          }}
                          size="xs"
                      /> */}
                  </Box>
              </Group>
          </Paper>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }}>
          <Paper style={{borderColor: "#DFDFDF"}} p="xl">
              {
                  (selectedPlan?.price ?? 0) > 0 && <>
                    <Group>
                      <Title style={{flex: 1}} order={4}>{str("SIGNUP.PAYMENT_METHOD")}</Title>
                      <Popover>
                        <Popover.Target>
                          <Tooltip label="Why do you need to put card information?">
                            <ActionIcon variant="light" color="yellow"><FaInfoCircle /></ActionIcon>
                          </Tooltip>
                        </Popover.Target>
                        <Popover.Dropdown style={{width: 300}}>
                          <Text ta="center" fw="bold" size="sm" mb="xs">{str("SIGNUP.CHARGE_DESCRIPTION_TITLE")}</Text>
                          <Text ta="center" size="xs">{str("SIGNUP.CHARGE_DESCRIPTION_SUBTITLE")}</Text>
                        </Popover.Dropdown>
                      </Popover>
                    </Group>
                    <Box mt="sm">
                      <PaymentForm
                        showMethods={false}
                        data={formData?.payment}
                        onChange={dt => {
                          setFormData(fd => ({...fd, payment: {...fd.payment, ...dt }}))
                        }}
                      />
                    </Box>
                  </>
              }
          </Paper>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 5 }}>
        <Paper style={{borderColor: "#DFDFDF"}} p="xl">
            <Title order={4}>{str("TOTAL")}</Title>

            <Paper mt="md" p="md" style={{borderColor: "#DFDFDF"}}>
                {/* <Text size="md">Due Today</Text> */}
                <Divider c="#DFDFDF" mt="md" mb="md" />
                <Text size="xs">{str("SIGNUP.WEB_PLATFORM")}</Text>
                <Text size="xs" mt="xs" c="gray">{"USD"} {(selectedPlan?.price ?? 0).toFixed(2)}{` ${str("SIGNUP.AFTER_15_DAYS_TRIAL").toLowerCase()}`}</Text>
                {<>
                  <Divider c="#DFDFDF" mt="md" mb="md" />
                  <Text size="xs">{str("SIGNUP.15_DAYS_DESCRIPTION")} </Text>
                  <Text size="xs" mt="xs" fw="bold">{"USD"} {
                    "0,00"
                  }</Text>
                </>}
            </Paper>
        </Paper>
    </Grid.Col>
    </Grid>
    <Checkbox
      checked={agree}
      mt="md"
      onChange={() => setAgree(a => !a)}
      label={<Text size="xs" c="gray" mb="md">{str("I_AGREE_TO_THE")} <Link to="https://www.awaz.ai/terms-conditions" target="_blank">{str("TERMS_AND_REFUND")}</Link></Text>}
    />
  </Box>;

  const activeForm : any = (
    customer ?
    [
      service ? { principal: ServiceStep } : null,
      { principal: SeccondStep },
    ].filter(nn => nn)
    : [
      { principal: SeccondStep },
      { principal: FirstStep },
      { principal: ThirdStep },
      { principal: FourthStep },
    ].filter(nn => nn)
  )[selectedTab];

  useEffect(() => {
    if(formData?.coupon?.length > 3) SEARCH_COUPON(formData?.coupon)
    .then(res => {
        setCoupons(c => ({...c, [formData?.coupon]: res }))
    })
    .catch(err => {
    })
}, [formData?.coupon]);

  useEffect(() => {
    // if(customer){
      // setSteps([
      //   service ? {title: service.plan_name, subtitle: `Get access to ${companyHost?.name ?? "Awaz"}`} : null,
      //   {title: "Sign Up", subtitle: `Get access to ${companyHost?.name ?? "Awaz"}`},
      // ].filter(nn => nn))
    // }else{
      setSteps([
        {title: str("SIGNUP.WELCOME_TITLE"), subtitle: str("SIGNUP.WELCOME_SUBTITLE"), onNext: () => {
          setFormData(fd => {
            SUBSCRIBE({ referral, name: fd?.general?.name, email: fd?.general?.email, phone: fd?.general?.phone, phone_code: fd?.general?.phone_code })
            .then(() => {})
            .catch(() => {})
            return fd;
          })
        }},
        {title: str("SIGNUP.WELCOME_TITLE"), subtitle: str("SIGNUP.WELCOME_SUBTITLE")},
        {title: str("SIGNUP.PRICE_TITLE"), subtitle: str("SIGNUP.PRICE_SUBTITLE")},
        {title: selectedPlan?.title ?? "Get Awaz", subtitle: str("SIGNUP.PRICE_TITLE")},
      ])
    // }
  }, [customer, service, selectedPlan])

  return !!user
  ? <Navigate to="/" state={{ from }} />
  : (
    <div style={{
        backgroundImage: `url(/soundwave.png)`,
        backgroundColor: '#12141d',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        position: 'relative',
        minHeight: '100vh',
        zIndex: 0,
        ...(
            isMobile
            ? {
                padding: 20,
            }
            : {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: activeForm?.secondary ? '10vh 10vw' : '10vh 20vw',
            }
        )
    }}>
        <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: '#00000055', zIndex: -1}}></div>
        <Paper p="md" style={{backgroundColor: '#242442'}}>
                {(isMobile && activeForm?.secondary)
                ? activeForm?.secondary
                : <Grid>
                    <Grid.Col span={{ base: 12, md: activeForm?.secondary ? 7 : 12 }}>
                        <div style={{display: 'flex', margin: '40px 0 10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <img alt="awaz" src={companyHost?.image ? companyHost?.image : `/logo-white.png`} style={{maxHeight: 80, maxWidth: '180px'}}/>
                        </div>
                        
                        <Title ta="center" mt="xl" order={2}>{steps[selectedTab]?.title}</Title>
                        <Text ta="center" size="sm">{steps[selectedTab]?.subtitle}</Text>

                        <Box style={{padding: isMobile ? '20px 10px 20px 10px' : '40px 60px 40px 60px'}}>
                          <Paper p="md" mb="md">
                            <Group style={{padding: isMobile ? '0 4px' : '0 10px'}}>
                                <ActionIcon onClick={() => { if(selectedTab === 0){ navigate("/login") }else{ setSelectedTab(i => i-1) } }}><FaChevronLeft /></ActionIcon>
                                <Box style={{flex: 1}}>
                                    <Progress
                                        value={(((selectedTab??0)+1)/(steps.length))*100}
                                        color="violet"
                                    />
                                </Box>
                                <ActionIcon style={{visibility: 'hidden'}}><FaChevronLeft /></ActionIcon>
                            </Group>
                          </Paper>

                            {/* <Box style={{padding: '0 0 40px 0'}}>
                            </Box> */}
                            <form onSubmit={e => {e.preventDefault(); navigateToTab(selectedTab+1) }} style={{overflowY: 'auto'}}>
                                {activeForm?.principal}
                                <Box mt="xl" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Button
                                        type="submit"
                                        loading={loading}
                                        disabled={validTab[selectedTab] && !validTab[selectedTab]()}
                                        color="violet"
                                        variant="filled"
                                        size="md">{selectedTab+1 === steps.length ? customer ? str("SIGNUP.TITLE") : str("CONTINUE") : str("CONTINUE")}</Button>
                                </Box>
                                <Group mt="xl" mb="xl">
                                  <Divider style={{flex: 1, margin: '0 10px', borderColor: '#6a6a6a'}} />
                                  <Text c="#6a6a6a">{str("OR")}</Text>
                                  <Divider style={{flex: 1, margin: '0 10px', borderColor: '#6a6a6a'}} />
                                </Group>
                                <Box mt="xl" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <Button
                                        loading={loading}
                                        color="violet"
                                        variant="outline"
                                        onClick={() => navigate("/login")}
                                        size="md">{str("LOGIN.LOGIN")}</Button>
                                </Box>
                            </form>
                    </Box>
                </Grid.Col>
                {activeForm?.secondary && !isMobile && <Grid.Col span={{ base: 12, md: 5 }}>
                    {activeForm.secondary}
                </Grid.Col>}
            </Grid>}
        </Paper>
    </div>
  );
};
