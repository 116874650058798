import React, { useEffect, useState } from 'react'
import InputField, { InputFieldMasks, InputFieldTypes } from './input_field'
import { Group, Paper } from '@mantine/core'
import { countriesList } from '../utility/util';
import { useLanguage } from '../contexts/language.context';

export const PaymentForm = ({ data, showMethods = true, onChange, methods = [], activeMethod = null, onChangeMethod = null }) => {
    const [newCard, setNewCard] = useState(false);

    const { str } = useLanguage();

    const handleChangeCard = (dt) => {
        onChange(({
            ...data,
            card: { ...data?.card, ...dt }
        }))
    }

    useEffect(() => {
        if(methods.length === 0) onChange({ method: "new-card" })
    }, [methods.length])

    const CardForm = <>
        <InputField
            size="md"
            mb="md"
            title={str("PAYMENT.CARD_NUMBER")}
            name="number"
            value={data?.card?.number}
            onChange={handleChangeCard}
        />
        {/* <InputField
            size="md"
            mb="md"
            title="Name"
            name="name"
            value={data?.card?.name}
            onChange={handleChangeCard}
        /> */}
        <InputField
            size="md"
            title={str("PAYMENT.EXPIRATION")}
            name="vl"
            mb="md"
            mask={InputFieldMasks.CUSTOM}
            customMask={"99/99"}
            placeholder='MM/YY'
            value={[
                data?.card?.exp_month,
                data?.card?.exp_year,
            ].filter(nn => nn).join("/")}
            onChange={({vl}) => {
                let splited = vl.split("/");
                handleChangeCard({exp_month: splited[0] ?? "", exp_year: splited[1] ?? ""})
            }}
        />
        <InputField
            size="md"
            mb="md"
            title={str("PAYMENT.SECURITY")}
            name="cvc"
            value={data?.card?.cvc}
            onChange={handleChangeCard}
        />
        {/* <InputField
            size="md"
            mb="md"
            title="Country"
            name="country"
            value={data?.card?.country}
            options={countriesList}
            fieldType={InputFieldTypes.SELECT}
            onChange={handleChangeCard}
        /> */}
    </>

    return <>
        {showMethods && <InputField
            size="md"
            name="method"
            mb="md"
            value={data?.method}
            searchable={false}
            clearable={false}
            fieldType={InputFieldTypes.SELECT}
            onChange={({method}) => {
                onChange({ method })
            }}
            options={[
                ...methods.map(m => ({label: `${m?.data?.payment_method?.card?.brand?.toUpperCase()} **** ${m?.data?.payment_method?.card?.last4}` || "Card", value: m._id})),
                { label: str("PAYMENT.ADD_NEW_CARD"), value: 'new-card' }
            ]}
        />}
        {data?.method === "new-card" && CardForm}
    </>
}